import empty from '../components/empty'

import appDashboard from '../modules/app/dashboard/index'

import appBooking from '../modules/app/booking/index'

import appContainers from '../modules/app/containers/index'
import appContainersView from '../modules/app/containers/view'

import appInventories from '../modules/app/inventories/index'
import appInventoriesModified from '../modules/app/inventories/modified'
import appInventoriesView from '../modules/app/inventories/view'

import partnerInventories from '../modules/app/partner/inventories'
import partnerInventoriesModified from '../modules/app/partner/inventories/modified'

import appBranches from '../modules/app/settings/dictionaries/branches'
import appBranchesEmployees from '../modules/app/branches/employees'

import appBrands from '../modules/app/settings/dictionaries/brands'
import appBrandsModels from '../modules/app/brands/models'

import appCarriers from '../modules/app/carriers/index'
import appCarriersDrivers from '../modules/app/carriers/drivers'

import appCompanies from '../modules/app/companies/index'
import appCustomers from '../modules/app/companies/customers'

import appCods from '../modules/app/settings/dictionaries/cods'
import appLiens from '../modules/app/settings/dictionaries/liens'
import appColors from '../modules/app/settings/dictionaries/colors'
import appTitles from '../modules/app/settings/dictionaries/titles'
import appChassis from '../modules/app/settings/dictionaries/chassis'
import appOptions from '../modules/app/settings/dictionaries/options'
import appEmployees from '../modules/app/employees/index'
import appTerminals from '../modules/app/settings/dictionaries/terminals'
import appInventoryTypes from '../modules/app/settings/dictionaries/inventory_types'
import appConditions from '../modules/app/condition/index'
import appShippingLines from '../modules/app/settings/dictionaries/shipping_lines'
import appDestinations from '../modules/app/settings/dictionaries/destinations'
import appLoadPlans from '../modules/app/load_plan/index'
import appNotifications from '../modules/app/notifications/index'

import appConditionReportWizard from '../modules/app/condition_report/wizard'

import appDictionaries from '../modules/app/settings/dictionaries/index'

export default [
    {
        path: '',
        redirect: {
            name: 'appDashboard'
        },
    },
    {
        path: 'condition-report',
        component: empty,
        meta: {
            breadcrumb: 'Condition Report',
        },
        children: [
            {
                path: '',
                name: 'appConditionReport',
                redirect: {
                    name: 'appConditionReportWizard',
                    params: {
                        step: '1',
                    },
                },
            },
            {
                path: 'step-:step',
                name: 'appConditionReportWizard',
                component: appConditionReportWizard,
                meta: {
                    menu: 'appInventories',
                },
            },
        ],
    },
    {
        path: 'dashboard',
        name: 'appDashboard',
        component: appDashboard,
        meta: {
            breadcrumb: 'Dashboard',
            menu: 'appDashboard'
        },
    },
    {
        path: 'employees',
        name: 'appEmployees',
        component: appEmployees,
        meta: {
            breadcrumb: 'Employees',
            menu: 'appEmployees',
        },
    },
    {
        path: 'booking',
        name: 'appBooking',
        component: appBooking,
        meta: {
            breadcrumb: 'Booking',
            menu: 'appBooking',
        },
    },
    {
        path: 'companies',
        component: empty,
        meta: {
            breadcrumb: 'Customers',
        },
        children: [
            {
                path: '',
                name: 'appCompanies',
                component: appCompanies,
                meta: {
                    breadcrumb: 'List',
                    menu: 'appCompanies',
                },
            },
            {
                path: ':id',
                name: 'appCustomers',
                component: appCustomers,
                meta: {
                    breadcrumb: 'View',
                    menu: 'appCompanies',
                },
            },
        ],
    },
    {
        path: 'inventories',
        component: empty,
        meta: {
            breadcrumb: 'Inventories',
        },
        children: [
            {
                path: 'on_the_way',
                name: 'appInventoriesOnTheWay',
                props: {
                    type: 'on_the_way'
                },
                component: appInventories,
                meta: {
                    breadcrumb: 'On the way',
                    menu: 'appInventories',
                },
            },
            {
                path: 'received',
                name: 'appInventoriesReceived',
                props: {
                    type: 'received'
                },
                component: appInventories,
                meta: {
                    breadcrumb: 'Received',
                    menu: 'appInventories',
                },
            },
            {
                path: 'in_yard',
                name: 'appInventoriesInYard',
                props: {
                    type: 'in_yard'
                },
                component: appInventories,
                meta: {
                    breadcrumb: 'In Yard',
                    menu: 'appInventories',
                },
            },
            // {
            //   path: 'immersed',
            //   name: 'appInventoriesImmersed',
            //   props: {
            //     type: 'immersed'
            //   },
            //   component: appInventories,
            //   meta: {
            //     breadcrumb: 'Immersed',
            //     menu: 'appInventories',
            //   },
            // },
            {
                path: 'shipped,immersed',
                name: 'appInventoriesShipped',
                props: {
                    type: 'shipped,immersed'
                },
                component: appInventories,
                meta: {
                    breadcrumb: 'Shipped',
                    menu: 'appInventories',
                },
            },
            {
                path: 'released',
                name: 'appInventoriesReleased',
                props: {
                    type: 'released'
                },
                component: appInventories,
                meta: {
                    breadcrumb: 'Released',
                    menu: 'appInventories',
                },
            },
            {
                path: 'create',
                name: 'appInventoriesCreate',
                component: appInventoriesModified,
                meta: {
                    breadcrumb: 'Create',
                    menu: 'appInventories'
                },
            },
            {
                path: ':id',
                name: 'appInventoriesView',
                component: appInventoriesView,
                meta: {
                    breadcrumb: 'View',
                    menu: 'appInventories'
                },
            },
            {
                path: ':id/edit',
                name: 'appInventoriesEdit',
                component: appInventoriesModified,
                meta: {
                    breadcrumb: 'Edit',
                    menu: 'appInventories'
                },
            },
            {
                path: '',
                name: 'appInventories',
                redirect: {
                    name: 'appInventoriesReceived',
                },
            },
        ],
    },
    {
        path: 'partner/inventories',
        component: empty,
        meta: {
            breadcrumb: 'Inventories',
        },
        children: [
            {
                path: 'received',
                name: 'partnerInventoriesReceived',
                props: {
                    type: 'received'
                },
                component: partnerInventories,
                meta: {
                    breadcrumb: 'Received',
                    menu: 'partnerInventories',
                },
            },
            {
                path: ':id/edit',
                name: 'partnerInventoriesEdit',
                component: partnerInventoriesModified,
                meta: {
                    breadcrumb: 'Edit',
                    menu: 'partnerInventories'
                },
            },
            {
                path: '',
                name: 'partnerInventories',
                redirect: {
                    name: 'partnerInventoriesReceived',
                },
            },
        ],
    },
    {
        path: 'containers',
        component: empty,
        meta: {
            breadcrumb: 'Containers',
        },
        children: [
            {
                path: 'manually',
                name: 'appContainersManually',
                props: {type: 'manually'},
                component: appContainers,
                meta: {
                    breadcrumb: 'Manually',
                    menu: 'appContainers',
                },
            },
            {
                path: 'forming',
                name: 'appContainersForming',
                props: {type: 'forming'},
                component: appContainers,
                meta: {
                    breadcrumb: 'Forming',
                    menu: 'appContainers',
                },
            },
            {
                path: 'pulled',
                name: 'appContainersPulled',
                props: {type: 'pulled'},
                component: appContainers,
                meta: {
                    breadcrumb: 'Pulled',
                    menu: 'appContainers',
                },
            },
            {
                path: 'in_gated',
                name: 'appContainersInGated',
                props: {type: 'in_gated'},
                component: appContainers,
                meta: {
                    breadcrumb: 'In Gated',
                    menu: 'appContainers',
                },
            },
            {
                path: '',
                name: 'appContainers',
                redirect: {
                    name: 'appContainersForming',
                },
            },
            {
                path: ':id',
                name: 'appContainersInventories',
                component: appContainersView,
                meta: {
                    breadcrumb: 'View',
                    menu: 'appContainers',
                },
            }
        ],
    },
    {
        path: 'load_plans',
        name: 'appLoadPlans',
        component: appLoadPlans,
        meta: {
            breadcrumb: 'Load Plans',
            menu: 'appLoadPlans',
        },
    },
    {
        path: 'notifications',
        name: 'appNotifications',
        component: appNotifications,
        meta: {
            breadcrumb: 'Notifications',
            menu: 'appNotifications',
        },
    },

    {
        path: 'carriers',
        component: empty,
        meta: {
            breadcrumb: 'Carriers',
            menu: 'appCarriers',
        },
        children: [
            {
                path: '',
                name: 'appCarriers',
                component: appCarriers,
                meta: {
                    breadcrumb: 'List',
                    menu: 'appCarriers',
                },
            },
            {
                path: ':id',
                name: 'appCarriersDrivers',
                component: appCarriersDrivers,
                meta: {
                    breadcrumb: 'View',
                    menu: 'appCarriers',
                },
            },
        ]
    },

    {
        path: 'dictionaries',
        component: appDictionaries,
        meta: {
            breadcrumb: 'Directories',
            menu: 'appDirectories',
        },
        children: [
            {
                path: 'branches',
                name: 'appBranches',
                component: appBranches,
                meta: {
                    breadcrumb: 'Branches',
                    menu: 'appBranches',
                },
            },
            {
                path: 'branches/:id',
                name: 'appBranchesEmployees',
                component: appBranchesEmployees,
                meta: {
                    breadcrumb: 'View',
                    menu: 'appBranches',
                },
            },
            {
                path: 'brands',
                name: 'appBrands',
                component: appBrands,
                meta: {
                    breadcrumb: 'Brands',
                    menu: 'appBrands',
                },
            },
            {
                path: 'brands/:id',
                name: 'appBrandsModels',
                component: appBrandsModels,
                meta: {
                    breadcrumb: 'View',
                    menu: 'appBrands',
                },
            },
            {
                path: 'colors',
                name: 'appColors',
                component: appColors,
                meta: {
                    breadcrumb: 'Colors',
                    menu: 'appColors',
                },
            },
            {
                path: 'options',
                name: 'appOptions',
                component: appOptions,
                meta: {
                    breadcrumb: 'Options',
                    menu: 'appOptions',
                },
            },
            {
                path: 'conditions',
                name: 'appConditions',
                component: appConditions,
                meta: {
                    breadcrumb: 'Conditions',
                    menu: 'appConditions',
                },
            },
            {
                path: 'terminals',
                name: 'appTerminals',
                component: appTerminals,
                meta: {
                    breadcrumb: 'Terminals',
                    menu: 'appTerminals',
                },
            },
            {
                path: 'liens',
                name: 'appLiens',
                component: appLiens,
                meta: {
                    breadcrumb: 'Liens',
                    menu: 'appLiens',
                },
            },
            {
                path: 'cods',
                name: 'appCods',
                component: appCods,
                meta: {
                    breadcrumb: 'CODs',
                    menu: 'appCods',
                },
            },
            {
                path: 'titles',
                name: 'appTitles',
                component: appTitles,
                meta: {
                    breadcrumb: 'Titles',
                    menu: 'appTitles',
                },
            },
            {
                path: 'inventory-types',
                name: 'appInventoryTypes',
                component: appInventoryTypes,
                meta: {
                    breadcrumb: 'Inventory types',
                    menu: 'appInventoryTypes',
                },
            },
            {
                path: 'shipping_lines',
                name: 'appShippingLines',
                component: appShippingLines,
                meta: {
                    breadcrumb: 'Shipping Lines',
                    menu: 'appShippingLines',
                },
            },
            {
                path: 'destinations',
                name: 'appDestinations',
                component: appDestinations,
                meta: {
                    breadcrumb: 'Destinations',
                    menu: 'appDestinations',
                },
            },

            {
                path: 'chassis',
                name: 'appChassis',
                component: appChassis,
                meta: {
                    breadcrumb: 'Chassis',
                    menu: 'appChassis',
                },
            },

        ]
    },
]
