<template>
    <div>
        <Row :gutter="8">
            <Col :sm="8">
                <FormItem prop="v_type"
                          label="Type of vehicle">
                    <Select v-model="form.v_type"
                            size="large">
                        <Option v-for="item in $store.getters.vehicleTypesList"
                                :value="item.id"
                                :key="item.id">
                            <template>{{item.name}}</template>
                        </Option>
                    </Select>
                </FormItem>
            </Col>
            <Col span="8">
                <FormItem prop="branch"
                          label="Branch">
                    <Select :value="form.branch ? form.branch.id : null"
                            filterable
                            size="large"
                            @on-change="onChangeBranch">
                        <Option v-for="item in listBranches"
                                :value="item.id"
                                :key="item.id">
                            <template>{{item.name}}</template>
                        </Option>
                    </Select>
                </FormItem>
            </Col>
        </Row>

        <Row :gutter="8">
            <Col :sm="8">
                <FormItem prop="year"
                          label="Year">
                    <DatePicker
                            :value="form.year && form.year.isValid() ? form.year.toDate() : null"
                            type="year"
                            placeholder="Select year"
                            size="large"
                            format="yyyy"
                            @on-change="onChangeYear"/>
                </FormItem>
            </Col>

            <Col :sm="8">
                <FormItem prop="make"
                          label="Make">
                    <Input size="large"
                           v-model="form.make"
                           type="text"
                           placeholder="Make"/>
                </FormItem>
            </Col>

            <Col :sm="8">
                <FormItem prop="model"
                          label="Model">
                    <Input size="large"
                           v-model="form.model"
                           type="text"
                           placeholder="Model"/>
                </FormItem>
            </Col>
        </Row>

        <Row :gutter="8">
            <Col :sm="8">
                <FormItem prop="color"
                          label="Color">
                    <Select :value="(form.color || {}).id"
                            filterable
                            size="large"
                            @on-change="onChangeColor">
                        <Option v-for="item in listColors"
                                :value="item.id"
                                :key="item.id">
                            <template>{{item.name}}</template>
                        </Option>
                    </Select>
                </FormItem>
            </Col>

            <Col :sm="8">
                <FormItem prop="vin"
                          label="VIN">
                    <Input size="large"
                           v-model="form.vin"
                           type="text"
                           placeholder="VIN code"/>
                </FormItem>
            </Col>

            <Col :sm="8">
                <FormItem prop="hat_number"
                          label="HAT #">
                    <Input size="large"
                           v-model="form.hat_number"
                           type="text"
                           placeholder="HAT #"/>
                </FormItem>
            </Col>
        </Row>

        <Row :gutter="8">
            <Col :sm="8">
                <FormItem prop="lot_number"
                          label="Lot #">
                    <Input size="large"
                           v-model="form.lot_number"
                           type="text"
                           placeholder="Lot #"/>
                </FormItem>
            </Col>

            <Col :sm="8">
                <FormItem prop="dimension"
                          label="Dimension">
                    <Input size="large"
                           v-model="form.dimension"
                           type="text"
                           placeholder="Dimension"/>
                </FormItem>
            </Col>

            <Col :sm="8">
                <FormItem prop="items"
                          label="Items in vehicle">
                    <Input size="large"
                           v-model="form.items"
                           type="text"
                           placeholder="Item in vehicle"/>
                </FormItem>
            </Col>
        </Row>

        <Row :gutter="8">
            <Col :md="4"
                 :sm="8">
                <FormItem prop="title_present"
                          label="Title provided">
                    <RadioGroup v-model="form.title_present"
                                size="large">
                        <Radio label="true">Yes</Radio>
                        <Radio label="false">No</Radio>
                    </RadioGroup>
                </FormItem>
            </Col>

            <Col :md="4"
                 :sm="8">
                <FormItem prop="damaged"
                          label="Damaged">
                    <RadioGroup v-model="form.damaged"
                                size="large">
                        <Radio label="true">Yes</Radio>
                        <Radio label="false">No</Radio>
                    </RadioGroup>
                </FormItem>
            </Col>

            <Col :md="4"
                 :sm="8">
                <FormItem prop="operable"
                          label="Operable">
                    <RadioGroup v-model="form.operable"
                                size="large">
                        <Radio label="true">Yes</Radio>
                        <Radio label="false">No</Radio>
                    </RadioGroup>
                </FormItem>
            </Col>

            <Col :md="7"
                 :sm="12">
                <FormItem prop="towed"
                          label="Towed">
                    <RadioGroup v-model="form.towed"
                                size="large">
                        <Radio label="true">Yes</Radio>
                        <Radio label="false">Customer dropped off</Radio>
                    </RadioGroup>
                </FormItem>
            </Col>
        </Row>
    </div>
</template>

<script>
  import moment from 'moment'
  import {Branch} from '../../../models/Branch'
  import {Color} from '../../../models/Color'

  export default {
    props: {
      form: {
        type: Object
      }
    },

    data() {
      return {
        listBranches: [],
        listColors: [],
      }
    },

    mounted() {
      this.reload()
    },

    methods: {
      async reload() {
        let response = await new Color().all();
        this.listColors = (response.data || []).filter(item => !item.is_deleted);
        response = await new Branch().all();
        this.listBranches = (response.data || []).filter(item => !item.is_deleted);
      },

      onChangeBranch(id) {
        let filtered = this.listColors.filter(item => item.id === id);
        this.form.color = filtered.length ? filtered[0] : null
      },

      onChangeColor(id) {
        let filtered = this.listColors.filter(item => item.id === id);
        this.form.color = filtered.length ? filtered[0] : null
      },

      onChangeYear(value) {
        this.form.year = moment(value, 'YYYY')
      },
    }
  }
</script>