import {Base} from './Base'
import {Branch} from './Branch'
import {Employee} from './Employee'

export class Inventory extends Base {
    resourceName() {
        return 'inventories'
    }

    relations() {
        return {
            owner: (...arg) => new Employee(...arg),
            branch: (...arg) => new Branch(...arg),
        }
    }


    async checked() {
        let parent = Object.getPrototypeOf(Object.getPrototypeOf(this)).resourceName();
        return await this.request({
            url: `${this.baseUrl()}/${parent}/${this.id}/check`,
            method: 'POST',
        })
    }

    async canceled() {
        let parent = Object.getPrototypeOf(Object.getPrototypeOf(this)).resourceName();
        return await this.request({
            url: `${this.baseUrl()}/${parent}/${this.id}/cancel`,
            method: 'POST',
        })
    }

    async restore() {
        let parent = Object.getPrototypeOf(Object.getPrototypeOf(this)).resourceName();
        return await this.request({
            url: `${this.baseUrl()}/${parent}/${this.id}/restore`,
            method: 'GET',
        })
    }

    async shipped() {
        let parent = Object.getPrototypeOf(Object.getPrototypeOf(this)).resourceName();
        return await this.request({
            url: `${this.baseUrl()}/${parent}/${this.id}/shipped`,
            method: 'GET',
        })
    }

    async received() {
        let parent = Object.getPrototypeOf(Object.getPrototypeOf(this)).resourceName();
        return await this.request({
            url: `${this.baseUrl()}/${parent}/${this.id}/receive`,
            method: 'POST',
        })
    }

    async checkVin() {
        const result = await this.request({
            url: `${this.baseUrl()}/${this.resourceName()}/vin/${this.vin}`
        });
        return !(result.data || {vin: false}).vin
    }
}
