import {Base} from './Base'

export class Title extends Base {
  resourceName() {
    return 'titles'
  }

  fields() {
    return [
      'name',
      'is_deleted',
    ]
  }
}