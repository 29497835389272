import {Base} from './Base'
import {Employee} from "./Employee"

export class Branch extends Base {

  resourceName() {
    return 'branches'
  }

  fields() {
    return [
      'name',
      'address1',
      'address2',
      'city',
      'state',
      'phone',
      'zip',
    ]
  }

  relations () {
    return {
      employees: (...arg) => new Employee(...arg),
    }
  }

  fullAddress(object = null) {
    if (!object) {
      object = this
    }
    return [
      object.address1,
      object.address2,
      object.city,
      object.state,
      object.zip
    ].filter((item) => item).join(', ')
  }

}