<template>
    <Card class="info-card-component">
        <p slot="title">{{title}}</p>
        <table >
            <tr v-for="(item, index) in data" :key="index">
                <td>{{item.title}}</td>
                <td>{{item.value}}</td>
            </tr>
        </table>
        <Spin fix v-if="loading">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
    </Card>

</template>

<script>
    export default {
        name: 'CardInfo',
        props:{
            title:{
                type: String,
            },
            data: {
                type: Array,
            },
            loading: {
                type: Boolean,
                default: false
            }
        },

    }
</script>
<style lang="scss">
    .info-card-component {
        table {
            border-collapse: collapse;
            width: 100%;
        }
        table tr:nth-child(even) {
            background: #f3f3f3;
        }
        table tr:nth-child(odd) {
            background: #FFF
        }

        table tr th:last-child,
        table tr td:last-child {
            border-bottom-style: none;
            border-top-style: none;
            border-right-style: none;
        }

        table tr td:first-child,
        table tr th:first-child {
            border-left-style: none;
            border-top-style: none;
            border-bottom-style: none;
        }

        table th, table td {
            padding-left: 5px;
            text-align: left;
        }
    }
</style>