<template>
    <Modal v-model="visible"
           ref="modal"
           :loading="true"
           title="Release Inventory"
           @on-ok="onOk"
           @on-cancel="onCancel">
        <Form ref="form"
              :model="form"
              :rules="form.rules"
              label-position="right"
              :label-width="120">

            <FormItem prop="release_date"
                      label="Release Date">
                <DatePicker type="date"
                            placeholder="Select date"
                            size="large"
                            format="MM/dd/yyyy"
                            :value="form.release_date && form.release_date.isValid && form.release_date.isValid() ? form.release_date.toDate() : null"
                            @on-change="onChangeReleaseDate"/>
            </FormItem>

            <FormItem prop="recipient"
                      label="Recipient">
                <Input size="large"
                       v-model="form.recipient"
                       type="text"
                       placeholder="Recipient name"/>
            </FormItem>
        </Form>

    </Modal>
</template>

<script>
    import moment from 'moment'
    import {Release} from '../../../../models/Release'
    export default {
        props: {
            value: Boolean,
        },

        data() {
            return {
                visible: false,
                form: {},

            }
        },

        methods: {
            open(object = null) {
                this.form = new Release();
                if (object) {
                    if (object.inventory) {
                        this.form.inventory = object.inventory;
                    }
                }
                this.visible = true;
            },

            onChangeReleaseDate(value) {
                this.form.release_date = moment(value, 'MM/DD/YYYY')
            },


            onOk() {
                this.form
                    .save()
                    .then(resp => {
                        this.visible = false;
                        this.$emit('on-ok')
                    })
                    .catch(err => {
                        this.$refs['form'].validate();
                        this.$refs['modal'].buttonLoading = false
                        throw err;
                    })
            },

            onCancel() {
                this.$emit('on-cancel')
            },
        }
    }
</script>