<template>
    <Timeline>

        <TimelineItem v-for="(item, index) in data" v-bind:key="index">
            <p class="author">{{ item.author }} {{ item.event}}d on {{ item.created_at }} </p>
            <p class="content" style="white-space: pre"> {{ item.changeset }}</p>
        </TimelineItem>
        <Spin fix v-if="loading">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>Loading..</div>
        </Spin>
    </Timeline>
</template>
<style scoped>
    .author{
        font-size: 14px;
        font-weight: bold;
    }
    .content{
        padding-left: 5px;
    }
</style>

<script>
    import moment from 'moment'
    import {Version} from '../models/Version'

    export default {
        props: {
            form: {
                type: Object,
            },
            resource: {
                type: String,
                required: true,
            }
        },

        data() {
            return {
                loading: false,
                data: []
            }

        },
        mounted() {
            this.loading = true;
            new Version().get_from_resource(this.resource, this.form.id)
                .then(resp => {
                    this.data = resp.data || [];
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false
                });
        },

        methods: {}
    }
</script>