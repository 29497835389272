<template>
    <Tabs :value="type"
          @on-click="onTabClick">
        <TabPane v-for="state in stateList"
                 :label="state.label"
                 :name="state.name"
                 :key="state.name">
            <div class="filter-fields">
                <Select :value="$route.query.branch || 'all'"
                        size="small"
                        style="width: 150px;"
                        @on-change="onChangeBranch">
                    <Option v-for="item in branches()"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <Select :value="$route.query.company || undefined "
                        placeholder="Customer"
                        size="small"
                        filterable
                        clearable
                        style="width: 150px;"
                        @on-change="onChangeCustomer">
                    <Option v-for="item in listCompanies"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <template v-if="$store.getters.hasAccess('container', 'export') && type === 'in_gated'">
                    <DatePicker :value="[$route.query.date_from || undefined, $route.query.date_till || undefined]"
                                format="MM/dd/yyyy"
                                type="daterange"
                                size="small"
                                placeholder="Select date"
                                style="width: 200px; font-size: 12px;"
                                @on-change="onChangeRange"/>
                    <div style="float: right">
                        <Downloads name="Export containers"
                                   size="small"
                                   :url="`exports/containers?filter[date_of_receiving]=${range_to_string()}`"
                                   :disabled="[$route.query.date_from || undefined, $route.query.date_till || undefined].filter(item => !!item).length === 0"/>

                        <Downloads name="Export loads"
                                   size="small"
                                   :url="`exports/loads?filter[date_of_receiving]=${range_to_string()}`"
                                   :disabled="[$route.query.date_from || undefined, $route.query.date_till || undefined].filter(item => !!item).length === 0"/>
                    </div>

                </template>

            </div>
            <containers :ref="state.name"
                        @on-action="onAction"
                        :type="type"
                        :range="[$route.query.date_from || undefined, $route.query.date_till || undefined]"
                        :page="parseInt($route.query.page) || undefined"
                        :sort="{key: $route.query.key || undefined, order: $route.query.order || undefined}"
                        :branch="$route.query.branch || undefined"
                        :company="$route.query.company || undefined"
                        :search="$route.query.search || undefined"/>
        </TabPane>

        <template slot="extra">
            <Button type="primary"
                    icon="md-add"
                    v-if="$store.getters.hasAccess('container', 'create')"
                    @click="create">
                <template>New container</template>
            </Button>

            <Input :value="$route.query.search || undefined"
                   @on-change="onSearchChange"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   search
            />

        </template>

        <Modified ref="modal"
                  @on-ok="onOk"/>
    </Tabs>
</template>

<script>
    import Vue from 'vue'
    import Modified from './../modals/containers/modified'
    import Downloads from '../../../components/Downloads'
    import containers from './_list'
    import {Company} from '../../../models/Company'

    export default {
        props: {
            type: {
                type: String,
                default: 'forming',
            }
        },

        components: {
            Modified,
            containers,
            Downloads,
        },

        data() {
            return {
                listCompanies: [],
                stateList: [
                    {label: 'Forming', name: 'forming'},
                    {label: 'Pulled', name: 'pulled'},
                    {label: 'In Gated', name: 'in_gated'},
                    {label: 'Manually', name: 'manually'},
                ],
            }
        },

        mounted() {
            this.reload()
        },

        watch: {
            '$route': 'reload',
        },

        methods: {
            branches() {
                const items = [{id: 'all', name: 'All Branches'}];
                if (this.$store.getters.branches()) {
                    return [...items, ...this.$store.getters.branches().data]
                }
                return items
            },

            onAction(name, action, object) {
                if (action === 'edit') {
                    this.$refs.modal.open(object)
                }
            },

            reload() {
                new Company().all().then(resp => this.listCompanies = resp.data || []).catch(console.log);
                this.$nextTick(() => {
                    this.$refs[this.type][0].reload()
                });
            },

            onOk() {
                this.reload()
            },

            create() {
                this.$refs.modal.open()
            },

            onTabClick(name) {
                this.$router.push({path: name, query: this.$route.query})
            },

            onChangeRange(range) {
                const [date_from, date_till] = range;
                this.changeUrl({date_from: date_from || undefined, date_till: date_till || undefined});
            },
            onChangeCustomer(item) {
                this.changeUrl({company: item || undefined})
            },
            onChangeBranch(item) {
                this.changeUrl({branch: (item === 'all' ? undefined : item)})
            },
            range_to_string() {
                return Vue.options.filters.to_string([this.$route.query.date_from || undefined, this.$route.query.date_till || undefined])
            },

            onSearchChange(event) {
                this.changeUrl({search: event.currentTarget.value || undefined, page: undefined})
            },

            changeUrl(object) {
                this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
            },
        }
    }
</script>
