import moment from 'moment'
import {Base} from './Base'

export class Version extends Base {
    resourceName() {
        return 'versions'
    }

    fields() {
        return [
            'created_at',
            'event',
            'changeset',
            'author'
        ]
    }

    dates() {
        return {
            created_at: 'YYYY-MM-DD',
        }
    }

    async get_from_resource(resource, id) {
        const requestConfig = { method: 'GET', url: `${this.baseUrl()}/${resource}/${id}/versions` };
        this.queryBuilder.reset();
        let response = await this.request(requestConfig);

        return this.respond(response.data);
    }
}