<template>
    <div>
        <Table :columns="columns"
               :data="data"
               :loading="loading"
               :row-class-name="rowClassName"
               @on-sort-change="changeSort"
               v-column-resizable="storage_name"
               border/>

        <Page :total="pagination.total_objects"
              :current="page"
              :page-size="pagination.per_page"
              @on-change="changePage"
              @on-page-size-change="changePageSize"
              show-total
              n-show-sizer/>
    </div>
</template>

<script>
  import moment from 'moment'
  import {Vehicle} from '../../../../models/Vehicle'

  export default {
    props: {
      search: {
        type: String,
      },
      range: {
        type: Array,
      },
      page: {
        type: Number,
        default: 1,
      },
      branch: {
        type: String,
      },
      sort: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        loading: true,
        columns: [
          {
            title: 'Date',
            minWidth: 60,
            width: 80,
            ellipsis: true,
            key: 'date_of_receiving',
            sortable: 'custom',
            render: (h, params) => {
              return h('span', params.row.date_of_receiving && params.row.date_of_receiving.isValid() ? params.row.date_of_receiving.format('MM/DD/YYYY') : null)
            },
          },
          {
            title: 'Branch',
            minWidth: 50,
            width: 120,
            ellipsis: true,
            key: 'branch_name',
          },
          {
            title: 'Year',
            minWidth: 45,
            width: 45,
            ellipsis: true,
            key: 'year',
            render: (h, params) => {
              return h('span', params.row.year && params.row.year.isValid() ? params.row.year.format('YYYY') : null)
            },
          },
          {
            title: 'Make',
            minWidth: 50,
            width: 120,
            ellipsis: true,
            key: 'make',
          },
          {
            title: 'Model',
            minWidth: 55,
            width: 120,
            ellipsis: true,
            key: 'model',
          },
          {
            title: 'VIN',
            minWidth: 60,
            width: 230,
            ellipsis: true,
            key: 'vin',
            sortable: 'custom',
            render: (h, params) => {
              let elements = [];
              elements.push(
                h('Badge', {
                  props: {
                    'dot': '',
                  },
                  class: {
                    'badge-green': params.row.is_images,
                  }
                })
              );
              elements.push(
                h('span', {
                    style: {
                      'padding-left': '10px',
                    },
                  },
                  params.row.vin
                ),
              );
              return h('span', elements)
            },
          },
          {
            title: 'Color',
            minWidth: 50,
            width: 80,
            ellipsis: true,
            key: 'color',
            render: (h, params) => {
              return h('div', params.row && params.row.color ? params.row.color.name : '')
            },
          },
          {
            title: 'Actions',
            width: 200,
            key: 'actions',
            // fixed: 'right',
            render: (h, params) => h('div', {style: {textAlign: 'right'},}, [...this.columnActions(h, params)],),
          }
        ],
        pagination: {
          total_objects: 0,
          per_page: 30,
        },
        data: [],
        lastObject: null,
      }
    },

    methods: {
      columnActions(h, params) {
        let actions = [];

        if (this.$store.getters.hasAccess('inventory', 'update')
          || this.$store.getters.hasAccess('inventory', 'read')
          || this.$store.getters.isPartner()) {
          actions.push(h('Button', {
            props: {type: 'success', size: 'small'},
            style: {marginRight: '5px'},
            on: {click: () => this.edit(params.index)}
          }, 'View'))
        }

        return actions
      },

      async checked(index) {
        await this.data[index].checked();
        this.$emit('on-action', this.type(), 'checked', this.data[index].clone())
      },

      async cancel(index) {
        await this.data[index].cancel();
        this.$emit('on-action', this.type(), 'cancel', this.data[index].clone())
      },

      rowClassName(row) {
        if (!row.title_date.isValid()) {
          return 'inventory-no-title'
        }
        if (row.lien && row.lien.name === 'LIEN') {
          return 'inventory-lien'
        }
        if (row.state === 'received') {
          return 'inventory-state-received'
        }
        return ''
      },

      type() {
        return this.$route.path.split('/').pop()
      },

      storage_name() {
        return 'inventories_' + this.type()
      },

      edit(index) {
        this.$emit('on-action', this.type(), 'edit', this.data[index].clone());
        this.$router.push({name: 'partnerInventoriesEdit', params: {id: this.data[index].id}})
      },

      reload() {
        this.loading = true;
        this.lastObject && this.lastObject.cancel('ignore');
        let vehicle = this.lastObject = new Vehicle();
        if (this.range && this.range.filter(item => !!item).length) {
          vehicle = vehicle.where('date_of_receiving', this.range.map(item => moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD')).join(','))
        }
        if (this.search) {
          vehicle = vehicle.where('search', encodeURIComponent(this.search))
        }
        if (this.sort && this.sort.key && this.sort.order) {
          vehicle = vehicle[this.sort.order === 'asc' ? 'orderBy' : 'orderByDesc'](this.sort.key)
        }
        if (this.branch) {
          vehicle = vehicle.where('branch', this.branch)
        }
        vehicle
          .paginate(this.pagination.per_page, this.page)
          .then(resp => {
            if (parseInt(resp.meta.total_objects) > 0 && parseInt(resp.meta.total_pages) < this.page) {
              this.changePage()
            }
            this.data = resp.data || [];
            this.pagination.total_objects = resp.meta.total_objects;
            this.loading = false
          })
          .catch(err => {
            if (!err.toString().startsWith('Cancel')) {
              console.warn(err);
              this.loading = false
            }
          });
      },

      changePage(page = undefined) {
        this.$router.push({name: this.$route.name, query: {...this.$route.query, page}});
      },

      changePageSize(per_page) {
        this.pagination.per_page = per_page;
        this.reload()
      },

      changeSort({column, key, order}) {
        const sort = {};
        if (order !== 'normal') {
          sort.key = column.model || key;
          sort.order = order
        }
        this.changeUrl(sort);
      },

      changeUrl(object) {
        this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
      },
    },
  }
</script>