import {Base} from './Base'
import {Vehicle} from "./Vehicle";

export class Color extends Base {

  resourceName() {
    return 'colors'
  }

  fields() {
    return [
      'name',
      'hex_color',
      'is_deleted',
    ]
  }

  relations() {
    return {
      vehicles: (...arg) => new Vehicle(...arg),
    }
  }

}