<template>
    <Layout class="h-layout-login">
        <Content style="display: flex">
            <div style="margin: auto">
                <Form ref="formLogin"
                      :model="formLogin"
                      :rules="ruleLogin">
                    <FormItem prop="email">
                        <Input size="large"
                               v-model="formLogin.login"
                               :autofocus="true"
                               type="text"
                               placeholder="Login"
                               prefix="ios-person-outline"/>
                    </FormItem>

                    <FormItem prop="password">
                        <Input size="large"
                               v-model="formLogin.password"
                               type="password"
                               password
                               placeholder="Password"
                               prefix="ios-lock-outline"/>
                    </FormItem>

                    <FormItem>
                        <Button type="primary"
                                @click.native.prevent="handleSubmit('formLogin')"
                                long
                                size="large"
                                html-type="submit">
                            <template>Sign in</template>
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </Content>
    </Layout>
</template>

<script>
  import {AUTH_REQUEST} from '../../store/actions/auth'

  export default {
    data() {
      return {
        formLogin: {
          login: '',
          password: '',
        },
        ruleLogin: {
          login: [
            {required: true, message: 'Please fill in the user name', trigger: 'blur'},
          ],
          password: [
            {required: true, message: 'Please fill in the password.', trigger: 'blur'},
          ]
        }
      }
    },

    methods: {
      handleSubmit(name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.$store.dispatch(AUTH_REQUEST, {
              login: this.formLogin.login,
              password: this.formLogin.password
            }).then(() => {
              // this.$Notice.success({
              //   title: 'Authentication', desc: 'Success'
              // });
              this.$router.push({name: 'appDashboard'});
            }).catch((error) => {
              console.log(error)
              this.$Notice.error({
                title: 'Authentication',
                desc: error.response && error.response.data ? error.response.data.message : error
              });
            })
          }
        })
      }
    }
  }
</script>
