<template>
    <Tabs value="all">
        <TabPane label="All"
                 name="all">
            <Table :columns="columns"
                   :data="data"
                   :loading="loading"/>

            <Page :total="pagination.total_objects"
                  :current="pagination.page"
                  :page-size="pagination.per_page"
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                  show-total
                  n-show-sizer/>
        </TabPane>

        <template slot="extra">
            <template>&nbsp;</template>

            <i-button type="primary"
                      icon="md-add"
                      @click="create"
                      v-if="$store.getters.hasAccess('dictionary', 'create')">
                <template>New carrier</template>
            </i-button>
            <Input :value="$route.query.search || undefined"
                   @on-change="onSearchChange"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   search
            />
        </template>

        <Modal v-model="modal"
               :title="formTitle"
               @on-ok="onOk"
               @on-cancel="onCancel"
               :loading="true"
               ref="modal">
            <Form ref="form"
                  :model="form"
                  :rules="form.rules"
                  label-position="right"
                  :label-width="100">
                <FormItem prop="name"
                          label="Name">
                    <Input size="large"
                           v-model="form.name"
                           :autofocus="true"
                           type="text"
                           placeholder="Name"/>
                </FormItem>

                <FormItem prop="address1"
                          label="Address 1">
                    <Input size="large"
                           v-model="form.address1"
                           type="text"
                           placeholder="Address 1"/>
                </FormItem>

                <FormItem prop="address2"
                          label="Address 2">
                    <Input size="large"
                           v-model="form.address2"
                           type="text"
                           placeholder="Address 2"/>
                </FormItem>

                <FormItem prop="city"
                          label="City">
                    <Input size="large"
                           v-model="form.city"
                           type="text"
                           placeholder="City"/>
                </FormItem>

                <FormItem prop="region"
                          label="Region">
                    <Input size="large"
                           v-model="form.region"
                           type="text"
                           placeholder="Region"/>
                </FormItem>

                <FormItem prop="country"
                          label="Country">
                    <Select v-model="form.country"
                            filterable
                            size="large">
                        <Option v-for="item in $store.getters.countryList"
                                :value="item"
                                :key="item">
                            <template>{{item}}</template>
                        </Option>
                    </Select>
                </FormItem>

                <FormItem prop="postal_code"
                          label="Postal Code">
                    <Input size="large"
                           v-model="form.postal_code"
                           type="text"
                           placeholder="Postal Code"/>
                </FormItem>

                <FormItem prop="phone"
                          label="Phone">
                    <Input size="large"
                           v-model="form.phone"
                           type="text"
                           placeholder="Phone"/>
                </FormItem>

                <FormItem prop="fax"
                          label="Fax">
                    <Input size="large"
                           v-model="form.fax"
                           type="text"
                           placeholder="Fax"/>
                </FormItem>

                <FormItem prop="email"
                          label="Email">
                    <Input size="large"
                           v-model="form.email"
                           type="text"
                           placeholder="Email"/>
                </FormItem>
            </Form>
        </Modal>

        <changeCarrierModal ref="changeCarrier"
                            @on-ok="onCarrierReplaceOk"/>
    </Tabs>
</template>

<script>
    import {Carrier} from '../../../models/Carrier'
    import changeCarrierModal from "./changeCarrierModal";

    export default {
        components: {
            changeCarrierModal
        },
        data() {
            return {
                modal: false,
                changeModal: false,
                form: {},
                loading: false,
                search: '',
                columns: [
                    {
                        title: '#',
                        key: 'id',
                        width: 50

                    },
                    {
                        title: 'Name',
                        key: 'name',
                        render: (h, params) => h('span', {style: {textDecoration: params.row.is_deleted ? 'line-through' : 'none' }}, params.row.name),
                    },
                    {
                        title: 'Address',
                        key: 'address',
                        render: (h, params) => {
                            return h('div', [
                                params.row.address1,
                                params.row.address2,
                                params.row.city,
                                params.row.region,
                                params.row.country,
                                params.row.postal_code
                            ].filter((item) => item).join(', '));
                        }
                    },

                    {title: 'Phone', key: 'phone'},
                    {title: 'Fax', key: 'fax'},
                    {title: 'Email', key: 'email'},
                    {
                        title: 'Actions',
                        width: 190,
                        key: 'actions',
                        render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
                    }
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 50,
                    page: 1
                },
                data: []
            }
        },

        computed: {
            formTitle() {
                return this.form.id ? 'Edit Carrier' : 'Create Carrier'
            }
        },

        created() {
            this.reload()
        },

        methods: {
            columnActions(h, params) {
                let actions = [];
                if (this.show) {
                    actions.push(h('Button', {
                            props: {type: 'default', size: 'small'},
                            style: {marginRight: '5px'},
                            on: {
                                click: () => this.show(params.index)
                            }
                        }, 'View'),
                    )
                }
                if (this.$store.getters.hasAccess('dictionary', 'update') && !params.row.is_deleted) {
                    actions.push(h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => this.edit(params.index)
                        }
                    }, 'Edit'))
                }
                if (this.$store.getters.hasAccess('dictionary', 'delete') && !params.row.is_deleted) {
                    actions.push(h('Poptip', {
                        props: {
                            confirm: true,
                            title: 'Do you want to change current carrier to a different one before deleting?',
                            placement: 'left',
                            'ok-text': "Yes",
                            'cancel-text': "No",
                            transfer: true,
                        },
                        on: {
                            'on-ok': ()=> this.replaceCarrier(params.index),
                            'on-cancel': ()=> this.remove(params.index)
                        }
                    }, [h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                    }, 'Delete')]));
                }
                return actions
            },

            onOk() {
                this.form
                    .save()
                    .then(resp => {
                        this.modal = false;
                        this.reload()
                    })
                    .catch(err => {
                        this.$refs['form'].validate();
                        this.$refs['modal'].buttonLoading = false
                    })
            },

            onCancel() {
                console.log('cancel', arguments)
            },

            onCarrierReplaceOk(){
                this.reload()
            },

            create() {
                this.form = new Carrier();
                this.modal = true;
            },

            show(index) {
                this.$router.push({name: 'appCarriersDrivers', params: {id: this.data[index].id}})
            },

            edit(index) {
                this.form = this.data[index].clone();
                this.modal = true;
            },

            remove(index) {
                this.data[index]
                    .delete()
                    .then(resp => {
                        this.reload()
                    })
                    .catch(err => {
                        this.reload()
                    })
            },

            replaceCarrier(index){
                this.$refs.changeCarrier.open(this.data[index].clone())
            },

            async reload() {
                this.loading = true;
                this.lastObject && this.lastObject.cancel('ignore');

                let carriers = this.lastObject = new Carrier();
                if(this.search){ carriers = carriers.where('search', encodeURIComponent(this.search)) }

                carriers.paginate(this.pagination.per_page, this.pagination.page)
                    .then(resp => {
                        this.data = resp.data || [];
                        this.pagination.total_objects = resp.meta.total_objects;
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false
                    });
            },

            changePage(page) {
                this.pagination.page = page;
                this.reload()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                this.reload()
            },

            onSearchChange(event) {
                this.search = event.currentTarget.value || undefined;
                this.reload();
                //this.changeUrl({search: this.search, page: undefined})
            },

            changeUrl(object) {
                this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
            },
        }
    }
</script>
