<template>
    <Avatar :style="styles" v-if="initials.length > 0">{{initials}}</Avatar>
    <Avatar :style="styles" icon="ios-person" v-else/>
</template>

<script>

    import randomcolor from 'randomcolor'

    export default {
        props: {
            firstName: String,
            lastName: String,
        },

        data() {
            return {}
        },

        computed: {
            initials() {
                let initCharts = '';
                if (this.firstName && this.firstName !== 'undefined' && this.firstName.length > 0) {
                    initCharts += this.firstName.charAt(0)
                }
                if (this.lastName && this.lastName !== 'undefined' && this.lastName.length > 0) {
                    initCharts += this.lastName.charAt(0)
                }
                return initCharts;
            },

            styles() {
                var color = randomcolor({ hue: 'blue', luminosity: 'bright', alpha: 1});
                return {
                    'background-color': color
                }
            }
        },

    }
</script>

<style scoped>

</style>
