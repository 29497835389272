import {Base} from './Base'
import {Container} from "./Container"

export class Requirement extends Base {

  resourceName() {
    return 'requirements'
  }

  fields() {
    return [
      'seal',
      'loaders',
      'direction',
      'chassis_id',
      'driver_name',
      'container_number',
    ]
  }

  dates() {
    return {
      requirement_date: 'YYYY-MM-DD',
    }
  }

  relations() {
    return {
      container: (...arg) => new Container(...arg),
    }
  }

}