<template>
    <Row :gutter="16">
        <Col :span="24"
             style="height: 400px; border: 2px solid lightgrey;">
            <canvas class="canvas"></canvas>
        </Col>

        <Col :span="24">&nbsp;</Col>

        <Col :span="24">
            <Button @click="clear">Clear signature</Button>
        </Col>
    </Row>
</template>

<script>
  import moment from 'moment'
  import SignaturePad from 'signature_pad'

  export default {
    props: {
      form: {
        type: Object,
      },
      value: {
        type: File,
      },
    },

    data() {
      return {
        hidden: null,
        element: null,
        canvas: null,
        headers: {
          authorization: localStorage.getItem('user-token'),
        },
        imageUrl: '',
        visible: false,
      }
    },

    mounted() {
      this.element = document.querySelector("canvas");
      this.canvas = new SignaturePad(this.element, {
        onEnd: () => {
          this.canvas_to_file(this.canvas.toDataURL())
        }
      });

      this._resizeObject = document.createElement('object');
      this._resizeObject.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;');
      this._resizeObject.setAttribute('aria-hidden', 'true');
      this._resizeObject.setAttribute('tabindex', '-1');
      this._resizeObject.onload = this.addResizeHandler;
      this._resizeObject.type = 'text/html';
      this._resizeObject.data = 'about:blank';
      this.element.offsetParent.appendChild(this._resizeObject);
    },

    destroyed() {
      this.removeResizeHandler();
    },

    methods: {
      clear() {
        this.canvas.clear();
        this.$emit('input', null)
      },

      addResizeHandler() {
        this._resizeObject.contentDocument.defaultView.addEventListener('resize', this.window_resize);
        this.window_resize()
      },

      removeResizeHandler() {
        if (this._resizeObject && this._resizeObject.onload) {
          if (this._resizeObject.contentDocument) {
            this._resizeObject.contentDocument.defaultView.removeEventListener('resize', this.window_resize)
          }
          delete this._resizeObject.onload
        }
      },

      window_resize() {
        this.element.width = this.element.offsetParent.getBoundingClientRect().width - 16;
        this.element.height = this.element.offsetParent.getBoundingClientRect().height;
      },

      canvas_to_file(b64Image) {
        let parts = b64Image.split(',');
        let img = atob(parts[1]);
        let content_type = parts[0].replace(/^.*:(.*);.*$/, '$1');
        let file_extension = content_type.replace(/^.*\/(.*)$/, '$1');

        let img_buffer = [];
        let i = 0;
        while (i < img.length) {
          img_buffer.push(img.charCodeAt(i++));
        }
        const signature = new File([new Uint8Array(img_buffer)], `signature.${moment().format('YYYYMMDDHHmmss')}.${file_extension}`, {type: content_type});
        this.$emit('input', signature);
      },
    },
  }
</script>