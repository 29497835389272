<template>
    <Content>
        <Card :bordered="false"
              dis-hover
              title="Inventory Details">
            <template slot="extra">
                <Downloads name="Download CR"
                           size="default"
                           :url="`condition_reports/${form.id}`"/>
                <Downloads name="Download Images"
                           size="default"
                           :url="`inventories/${form.id}/zip`"
                           v-if="tab === 'images'"/>

            </template>
            <Row :gutter="16">
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card">
                        <p slot="title">
                            Vehicle Images
                        </p>
                        <Carousel :starting-image="2" :images="images" />

                    </Card>
                </Col>
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card">
                        <p slot="title">
                            Vehicle Info
                        </p>
                        <table>
                            <tr>
                                <td>Date of Received</td>
                                <td>{{form.date_of_receiving | to_humanize_date}}</td>
                            </tr>
                            <tr>
                                <td>VIN</td>
                                <td>{{form.vin}}</td>
                            </tr>
                            <tr>
                                <td>Year, Make and Model</td>
                                <td>{{form.year | to_humanize_year}} {{form.make}} {{form.model}}</td>
                            </tr>
                            <tr>
                                <td>Color</td>
                                <td>{{(form.color || {}).name}}</td>
                            </tr>
                            <tr>
                                <td>Value</td>
                                <td>{{form.cost_humanize}}</td>
                            </tr>
                            <tr>
                                <td>Keys</td>
                                <td>{{form.key_humanize }}</td>
                            </tr>
                            <tr>
                                <td>Title</td>
                                <td>{{form.title && form.title.name}}</td>
                            </tr>
                        </table>
                    </Card>
                </Col  :xl>
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card">
                        <p slot="title">
                            Other Info
                        </p>

                        <table>
                            <tr>
                                <td>Lot #</td>
                                <td>{{form.lot_number}}</td>
                            </tr>
                            <tr>
                                <td>Damaged</td>
                                <td>{{form.damaged | to_humanize_boolean}}</td>
                            </tr>
                            <tr>
                                <td>Towed</td>
                                <td>{{form.towed | to_humanize_boolean}}</td>
                            </tr>
                            <tr>
                                <td>Operable</td>
                                <td>{{form.operable | to_humanize_boolean}}</td>
                            </tr>
                        </table>
                    </Card>
                </Col>
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card">
                        <p slot="title">
                            Vehicle Condition Report
                        </p>

                        <table>

                            <tr v-for="option in listOption" :key="option.id">
                                <td>{{option.name}}</td>
                                <td>{{optionState(option.id) | to_humanize_boolean}}</td>
                            </tr>
                        </table>
                    </Card>
                </Col>
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card"
                          v-if="form.state === 'shipped'">
                        <p slot="title">
                            Booking Info
                        </p>

                        <table>
                            <tr>
                                <td>Booking</td>
                                <td>{{form.booking_number}}</td>
                            </tr>
                            <tr>
                                <td>Container</td>
                                <td>
                                    {{form.container_number}}
                                </td>
                            </tr>
                            <tr>
                                <td>Shipping Line</td>
                                <td>
                                    {{form.shipping_line}}
                                </td>
                            </tr>
                            <tr>
                                <td>Vessel name</td>
                                <td>
                                    {{form.vessel_name}}
                                </td>
                            </tr>

                            <tr>
                                <td>Departure date</td>
                                <td>
                                    {{ form.departure_date && form.departure_date.isValid() ?
                                    form.departure_date.format('MM/DD/YYYY') : null }}
                                </td>
                            </tr>

                            <tr>
                                <td>Arrival date</td>
                                <td>
                                    {{ form.departure_date && form.arrival_date.isValid() ?
                                    form.arrival_date.format('MM/DD/YYYY') : null }}
                                </td>
                            </tr>

                            <tr>
                                <td>Status</td>
                                <td>
                                    {{ form.state }}
                                </td>
                            </tr>
                        </table>
                    </Card>
                </Col>
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card" title="Attachments">
                        <Attachments :attachment-list="((form.attachments|| {}).data || [])"/>
                    </Card>
                </Col>
            </Row>
            <Spin fix v-if="loading">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>Loading</div>
            </Spin>
        </Card>
    </Content>
</template>

<script>
    import {Option} from '../../../models/Option'
    import {Vehicle} from '../../../models/Vehicle'
    import Downloads from '../../../components/Downloads'
    import Carousel from '../../../components/Carousel'
    import Attachments from "../../../components/AttachmentsCustomer";

    export default {
        components: {
            Downloads,
            Carousel,
            Attachments
        },

        data() {
            return {
                tab: 'images',
                form: {},
                listOption: [],
                imageUrl: '',
                visible: false,
                images: [],
                loading: true,
            }
        },

        mounted() {
            this.reload()
        },

        methods: {
            handleView(url) {
                this.imageUrl = url;
                this.visible = true
            },

            optionState(id) {
                return (this.form.vehicle_options || {data: []}).data.filter(item => item.option.id === id).length > 0
            },

            async reload() {
                try {
                    this.loading = true;
                    this.listOption = (await new Option().all()).data || {};
                    if (this.$route && this.$route.params && this.$route.params.id) {
                        const response = await new Vehicle()
                            .with([
                                'color',
                                'title',
                                'attachments',
                                'vehicle_options.option',
                            ])
                            .find(this.$route.params.id)
                        this.form = response || {};
                        await this.form.pictures().then(resp => this.images = resp || []).catch(console.log);
                        this.images = this.images.concat(this.form.images);
                        this.loading = false;
                    }
                } catch {
                    this.loading = false;
                }
            }
        },

        watch: {
            '$route': 'reload',
        },
    }
</script>
