<template>
    <FormItem prop="carrier" ref="carrier">
        <template slot="label">
            <span>Carrier</span>
            <small v-if="value && value._uuid && $store.getters.hasAccess('dictionary', 'create')"
                   style="color:red">
                <template>[ will be created ]</template>
            </small>
        </template>

        <Select :value="value ? value.id : null"
                filterable
                size="large"
                remote
                :loading="loading"
                @on-change="onChange"
                @on-query-change="onQueryChange">
            <Option v-for="item in options"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id">
                <template>{{item.name}}</template>
            </Option>
        </Select>
    </FormItem>
</template>

<script>
  import uuid from '../utils/uuid'
  import {Carrier} from '../models/Carrier'

  export default {
    props: {
      value: {
        type: Object,
      }
    },

    data() {
      return {
        loading: false,
        list: [],
        options: [],
      }
    },

    methods: {
      async reload() {
        let response = await new Carrier().all();

        this.options = this.list = response.data || [];
        if (this.value && this.findById(this.value.id).length === 0) {
          this.options = [this.value, ...this.options]
        }
      },

      findById(id) {
        return this.list.filter(item => item.id === id)
      },

      onChange(id) {
        if (this.value && id !== this.value.id && !this.value._uuid) {
          let filtered = this.findById(id);
          this.$emit('input', filtered.length ? filtered[0] : null)
        }
      },

      onQueryChange(value) {
        let uniq = uuid();
        this.loading = true;

        if (value) {
          const carrier = {id: uniq, name: value, _uuid: uniq};

          this.options = this.list.filter(item => (item.name || '').toLowerCase().indexOf(value.toLowerCase()) > -1);

          const filtered = this.list.filter(item => (item.name || '').toLowerCase() === value.toLowerCase());

          if (filtered.length === 0) {
            this.options = [carrier, ...this.options];
            this.$emit('input', carrier)
          } else {
            this.$emit('input', filtered[0])
          }
        } else {
          this.options = this.list;
        }
        this.loading = false;
        this.$refs.carrier.validate()
      },
    },

    mounted() {
      this.reload()
    },
  }
</script>
