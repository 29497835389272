<template>
    <Modal v-model="visible"
           title="Change permissions"
           ref="permission"
           @on-ok="onOk"
           :loading="true">
        <Form ref="form"
              :model="form"
              label-position="left"
              :label-width="400">
            <FormItem prop="partner"
                      label="Partner mode"
                      style="margin-bottom: 8px;">
                <i-switch :value="form.partner"
                          @on-change="onChangePartner($event)"
                          size="large">
                    <span slot="open">YES</span>

                    <span slot="close">NO</span>
                </i-switch>
            </FormItem>
            <template v-if="!form.partner">
                <FormItem prop="admin"
                          label="Administrator mode"
                          style="margin-bottom: 8px;">
                    <i-switch :value="form.admin"
                              @on-change="onChangeAdmin($event)"
                              size="large">
                        <span slot="open">YES</span>

                        <span slot="close">NO</span>
                    </i-switch>
                </FormItem>
                <template v-if="!form.admin">
                    <hr>
                    <FormItem v-for="item in (form.accesses || {}).data"
                              :prop="item.id"
                              :label="item.name"
                              :key="item.id"
                              style="margin-bottom: 8px;">
                        <i-switch :value="item.grant"
                                  @on-change="onChangePermission(item, $event)"
                                  size="large">
                            <span slot="open">YES</span>

                            <span slot="close">NO</span>
                        </i-switch>
                    </FormItem>
                </template>
            </template>
        </Form>
    </Modal>
</template>

<script>
  export default {
    data() {
      return {
        form: {},
        visible: false,
      }
    },

    methods: {
      async open(object) {
        this.form = object;
        this.visible = true
      },

      onOk() {
        this.visible = false
      },

      async onChangePermission(item, value) {
        await this.form.changePermission(item, value);
        item.grant = value;
      },

      async onChangeAdmin(value) {
        this.form.admin = value;
        await this.form.save()
          .then(() => this.form.admin = value)
          .catch(() => this.form.admin = !value)
      },

      async onChangePartner(value) {
        const prevAdmin = this.form.admin;
        const prevPartner = this.form.partner;

        this.form.admin = value ? false : prevAdmin;
        this.form.partner = value;

        await this.form.save()
          .then(() => {
            this.form.admin = value ? false : prevAdmin;
            this.form.partner = value;
          })
          .catch(() => {
            this.form.admin = prevAdmin;
            this.form.partner = prevPartner;
          })
      },
    }
  }
</script>