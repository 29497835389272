import {Base} from './Base'
import {VehicleMake} from "./VehicleMake"

export class VehicleModel extends Base {

  resourceName() {
    return 'vehicle_models'
  }

  fields() {
    return [
      'name',
    ]
  }

  relations() {
    return {
      vehicle_make: (...arg) => new VehicleMake(...arg),
    }
  }

}