<template>
    <Tabs value="all">
        <TabPane label="All"
                 name="all">
            <Table :columns="columns"
                   :data="data"
                   :loading="loading"/>

            <Page :total="pagination.total_objects"
                  :current="pagination.page"
                  :page-size="pagination.per_page"
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                  show-total
                  show-elevator
                  show-sizer/>
        </TabPane>

        <template slot="extra">
            <template>&nbsp;</template>

            <Button type="primary"
                    icon="md-add"
                    @click="create"
                    v-if="$store.getters.hasAccess('company', 'create')">
                <template>New customer</template>
            </Button>

            <Input :value="search"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   @on-change="onChangeSearch"/>
        </template>

        <Modal v-model="modal"
               :title="formTitle"
               @on-ok="onOk"
               @on-cancel="onCancel"
               :loading="true"
               ref="modal">
            <Form ref="form"
                  :model="form"
                  :rules="form.rules"
                  label-position="right"
                  :label-width="100">
                <FormItem prop="name"
                          label="Name">
                    <Input size="large"
                           v-model="form.name"
                           :autofocus="true"
                           type="text"
                           placeholder="Name"/>
                </FormItem>

                <FormItem prop="address1"
                          label="Address 1">
                    <Input size="large"
                           v-model="form.address1"
                           type="text"
                           placeholder="Address 1"/>
                </FormItem>

                <FormItem prop="address2"
                          label="Address 2">
                    <Input size="large"
                           v-model="form.address2"
                           type="text"
                           placeholder="Address 2"/>
                </FormItem>

                <FormItem prop="city"
                          label="City">
                    <Input size="large"
                           v-model="form.city"
                           type="text"
                           placeholder="City"/>
                </FormItem>

                <FormItem prop="region"
                          label="Region">
                    <Input size="large"
                           v-model="form.region"
                           type="text"
                           placeholder="Region"/>
                </FormItem>

                <FormItem prop="country"
                          label="Country">
                    <Select v-model="form.country"
                            filterable
                            size="large">
                        <Option v-for="item in $store.getters.countryList"
                                :value="item"
                                :key="item">
                            <template>{{item}}</template>
                        </Option>
                    </Select>
                </FormItem>

                <FormItem prop="postal_code"
                          label="Postal Code">
                    <Input size="large"
                           v-model="form.postal_code"
                           type="text"
                           placeholder="Postal Code"/>
                </FormItem>

                <FormItem prop="phone"
                          label="Phone">
                    <Input size="large"
                           v-model="form.phone"
                           type="text"
                           placeholder="Phone"/>
                </FormItem>

                <FormItem prop="fax"
                          label="Fax">
                    <Input size="large"
                           v-model="form.fax"
                           type="text"
                           placeholder="Fax"/>
                </FormItem>

                <FormItem prop="website"
                          label="Website">
                    <Input size="large"
                           v-model="form.website"
                           type="text"
                           placeholder="Website"/>
                </FormItem>

                <FormItem prop="email"
                          label="Email">
                    <Input size="large"
                           v-model="form.email"
                           type="text"
                           placeholder="Email"/>
                </FormItem>
            </Form>
        </Modal>
    </Tabs>
</template>

<script>
  import {Company} from '../../../models/Company'

  export default {
    data() {
      return {
        modal: false,
        form: {},
        search: '',
        lastSearch: '',
        loading: false,
        columns: [
          {
            title: 'Name',
            key: 'name',
            render: (h, params) => h('span', {style: {textDecoration: params.row.is_deleted && 'line-through'}}, params.row.name),
          },
          {
            title: 'Address',
            key: 'address',
            render: (h, params) => {
              return h('div', [
                params.row.address1,
                params.row.address2,
                params.row.city,
                params.row.region,
                params.row.country,
                params.row.postal_code
              ].filter((item) => item).join(', '));
            }
          },

          {title: 'Phone', key: 'phone'},
          {title: 'Fax', key: 'fax'},
          {title: 'website', key: 'website'},
          {title: 'Email', key: 'email'},
          {
            title: 'Actions',
            width: 190,
            key: 'actions',
            render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
          }
        ],
        pagination: {
          total_objects: 0,
          per_page: 20,
          page: 1
        },
        data: [],
        lastObject: null,
      }
    },

    computed: {
      formTitle() {
        return this.form.id ? 'Edit Customer' : 'Create Customer'
      }
    },

    created() {
      this.reload();
    },

    methods: {
      columnActions(h, params) {
        let actions = [];
        if (this.show) {
          actions.push(h('Button', {
              props: {type: 'default', size: 'small'},
              style: {marginRight: '5px'},
              on: {
                click: () => this.show(params.index)
              }
            }, 'View'),
          )
        }
        if (this.$store.getters.hasAccess('company', 'update') && !params.row.is_deleted) {
          actions.push(h('Button', {
            props: {type: 'primary', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => this.edit(params.index)
            }
          }, 'Edit'))
        }
        if (this.$store.getters.hasAccess('company', 'delete') && !params.row.is_deleted) {
          actions.push(h('Poptip', {
            props: {
              confirm: true,
              title: 'Are you sure you want to delete this item?',
              placement: 'left',
              transfer: true,
            },
            on: {
              'on-ok': () => this.remove(params.index)
            }
          }, [h('Button', {
            props: {
              type: 'error',
              size: 'small'
            },
            style: {
              marginRight: '5px'
            },
          }, 'Delete')]));
        }
        return actions
      },

      onOk() {
        console.log('ok', arguments);
        this.form
          .save()
          .then(resp => {
            this.modal = false;
            this.reload()
          })
          .catch(err => {
            this.$refs['form'].validate();
            this.$refs['modal'].buttonLoading = false
          })
      },

      onCancel() {
        console.log('cancel', arguments)
      },

      create() {
        this.form = new Company();
        this.modal = true;
      },

      show(index) {
        this.$router.push({name: 'appCustomers', params: {id: this.data[index].id}})
      },

      edit(index) {
        this.form = this.data[index].clone();
        this.modal = true;
      },

      remove(index) {
        this.data[index]
          .delete()
          .then(resp => {
            this.reload()
          })
          .catch(err => {
            this.reload()
          })
      },

      reload() {
        this.loading = true;
        this.lastObject && this.lastObject.cancel('ignore');
        let company = this.lastObject = new Company();
        if (this.search) {
          company = company.where('search', encodeURIComponent(this.search))
        }
        if (this.search !== this.lastSearch) {
          this.lastSearch = this.search;
          this.pagination.page = 1
        }
        company.paginate(this.pagination.per_page, this.pagination.page)
          .then(resp => {
            this.data = resp.data || [];
            this.pagination.total_objects = resp.meta.total_objects;
            this.loading = false
          })
          .catch(err => {
            if (!err.toString().startsWith('Cancel')) {
              console.warn(err);
              this.loading = false
            }
          });
      },

      onChangeSearch(event) {
        this.search = event.target.value;
        this.reload()
      },

      changePage(page) {
        this.pagination.page = page;
        this.reload()
      },

      changePageSize(per_page) {
        this.pagination.per_page = per_page;
        this.reload()
      },
    }
  }
</script>
