import {Base} from './Base'
import {Access} from './Access'
import {Branch} from './Branch'

export class Employee extends Base {
  resourceName() {
    return 'employees'
  }

  fields() {
    return [
      'admin',
      'allow_login',
      'email',
      'partner',
      'first_name',
      'last_name',
      'password',
      'username',
      'branch_name',
    ]
  }

  relations() {
    return {
      branch: (...arg) => new Branch(...arg),
      branches: (...arg) => new Branch(...arg),
      accesses: (...arg) => new Access(...arg),
    }
  }

  computed() {
    return {
      fullName(employee) {
        return [employee.first_name, employee.last_name].filter((item) => item).join(' ')
      }
    }
  }

  async changePermission(access, value) {
    let clone = access.clone();
    clone.grant = value;

    await this.request({
      url: `${this.baseUrl()}/${this.resourceName()}/${this.id}/accesses/${clone.id}`,
      data: this.serialize(clone.data()),
      method: 'PUT',
    })
  }
}