<template>
    <div>
        <Tabs value="all" v-if="this.data.length > 0">
            <TabPane label="All"
                     name="all">
                <Table :columns="columns"
                       :data="data"
                       :loading="loading"/>

                <Page :total="pagination.total_objects"
                      :current="pagination.page"
                      :page-size="pagination.per_page"
                      @on-change="changePage"
                      @on-page-size-change="changePageSize"
                      show-total
                      n-show-sizer/>
            </TabPane>
            <template slot="extra">
                <Button type="primary"
                        icon="md-add"
                        @click="create">
                    <template>New notification</template>
                </Button>
            </template>
        </Tabs>
        <div v-else class="center-image">

            <img src='/assets/images/callcenter.svg' height="295" width="220">
            <h2 class="text">You haven't created any notifications yet</h2>
            <div class="center-button">
                <Button type="primary"
                        size="large"
                        icon="md-add"
                        @click="create">
                    <template>New notification</template>
                </Button>
            </div>

        </div>

        <Modal v-model="modalNotification"
               :title="formTitle"
               @on-ok="onOk"
               :loading="true"
               ref="modalBranch">
            <Form ref="formBranch"
                  :model="form"
                  :rules="form.rules"
                  label-position="right"
                  :label-width="100">
                <FormItem prop="message"
                          label="Message">
                    <Input size="large"
                           v-model="form.message"
                           type="textarea"
                           :autosize="{minRows: 2,maxRows: 5}"
                           :autofocus="true"
                           placeholder="Please, enter your message"/>
                </FormItem>
                <FormItem label="Date range">
                    <Row>
                        <Col span="11">
                            <FormItem prop="date">
                                <DatePicker type="date"
                                            placeholder="Date from"
                                            format="MM/dd/yyyy"
                                            v-model="form.due_from"/>
                            </FormItem>
                        </Col>
                        <Col span="2" style="text-align: center">-</Col>
                        <Col span="11">
                            <FormItem prop="date">
                                <DatePicker type="date"
                                            placeholder="Date to"
                                            format="MM/dd/yyyy"
                                            v-model="form.due_to"/>
                            </FormItem>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>

<script>
    import {Notification} from "../../../models/Notification";

    export default {
        data() {
            return {
                loading: false,
                modalNotification: false,
                form: {},
                columns: [
                    {
                        title: 'Date range',
                        key: 'date_range',
                        width: 200,
                        render: (h, params) => {
                            let elements = [];
                            if (params.row.due_from && params.row.due_from.isValid()) {
                                console.log(params.row.due_from.date);
                                elements.push(
                                    h('span', params.row.due_from.format('MM/DD/YYYY')),
                                );
                                elements.push(
                                    h('span', ' - '),
                                );
                            }

                            elements.push(
                                h('span', params.row.due_to && params.row.due_to.isValid() ? params.row.due_to.format('MM/DD/YYYY') : null),
                            );
                            return h('div', elements);
                        },
                    },
                    {title: 'Message', key: 'message'},
                    {
                        title: 'Actions',
                        width: 190,
                        key: 'actions',
                        render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
                    }
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 50,
                    page: 1
                },
                data: []
            }
        },

        created() {
            this.reload();
        },

        computed: {
            formTitle() {
                return this.form.id ? 'Edit Notification' : 'Create Notification'
            }
        },

        methods: {

            reload() {
                this.loading = true;
                new Notification().paginate(this.pagination.per_page, this.pagination.page)
                    .then(resp => {
                        this.data = resp.data || [];
                        this.pagination.total_objects = resp.meta.total_objects;
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false
                    });
            },

            onOk() {
                console.log('ok', arguments);
                this.form
                    .save()
                    .then(resp => {
                        this.modalNotification = false;
                        this.reload()
                    })
                    .catch(err => {
                        this.$refs['form'].validate();
                        this.$refs['modalNotification'].buttonLoading = false
                    })
            },

            create() {
                this.form = new Notification();
                this.modalNotification = true;
            },

            edit(index) {
                this.form = this.data[index].clone();
                this.modalNotification = true;
            },

            remove(index) {
                this.data[index]
                    .delete()
                    .then(resp => {
                        this.reload()
                    })
                    .catch(err => {
                        this.reload()
                    })
            },

            changePage(page) {
                this.pagination.page = page;
                //this.reload()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                //this.reload()
            },

            columnActions(h, params) {
                let actions = [];
                if (this.$store.getters.hasAccess('dictionary', 'update')) {
                    actions.push(h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => this.edit(params.index)
                        }
                    }, 'Edit'))
                }
                if (this.$store.getters.hasAccess('dictionary', 'delete')) {
                    actions.push(h('Poptip', {
                        props: {
                            confirm: true,
                            title: 'Are you sure you want to delete this item?',
                            placement: 'left',
                            transfer: true,
                        },
                        on: {
                            'on-ok': () => this.remove(params.index)
                        }
                    }, [h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                    }, 'Delete')]));
                }
                return actions
            },
        }
    }
</script>

<style scoped>
    .center-image {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;  /* Centering y-axis */
        align-items :center; /* Centering x-axis */
    }

    .text{
        padding-left: 30px;
        padding-top: 20px;
    }

    .center-button{
        margin-top: 20px;
        margin-left: 45px;
    }

</style>
