<template>
    <FormItem prop="title"
              label="Title">
        <Select :value="value ? value.id : null"
                size="large"
                @on-change="onChange">
            <Option v-for="item in list"
                    v-if="!item.is_deleted || (item.is_deleted && value && value.id === item.id)"
                    :disabled="item.is_deleted"
                    :value="item.id"
                    :key="item.id">
                <template>{{item.name}}</template>
            </Option>
        </Select>
    </FormItem>
</template>

<script>
    import {Title} from '../models/Title'

    export default {
        props: {
            value: {
                type: Object,
            }
        },

        data() {
            return {
                list: []
            }
        },

        methods: {
            async reload() {
                let response = await new Title().all();
                this.list = response.data || [];
            },

            onChange(id) {
                let filtered = this.list.filter(item => item.id === id);
                this.$emit('input', filtered.length ? filtered[0] : null)
            },
        },

        mounted() {
            this.reload()
        },
    }
</script>
