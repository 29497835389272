<template>
   <Tabs value="all">
      <TabPane label="All"
               name="all">
         <Table :columns="columns"
                :data="data"
                :loading="loading"/>

         <Page :total="pagination.total_objects"
               :current="pagination.page"
               :page-size="pagination.per_page"
               @on-change="changePage"
               @on-page-size-change="changePageSize"
               show-total
               n-show-sizer/>
      </TabPane>

      <template slot="extra">
         <template>&nbsp;</template>

         <Button type="primary"
                 icon="md-add"
                 @click="create"
                 v-if="$store.getters.hasAccess('company', 'create')">
            <template>New Load Plan</template>
         </Button>

         <Input :value="search"
                type="text"
                placeholder="Search ..."
                style="width: 200px;"
                @on-change="onChangeSearch"/>
      </template>
      <Modified ref="modal"></Modified>
   </Tabs>
</template>

<script>
   import {Company} from '../../../models/Company'
   import Modified from './modals/modifier'

   export default {
      components: {
         Modified,
      },
      data() {
         return {
            form: {},
            search: '',
            lastSearch: '',
            loading: false,
            columns: [
            ],
            pagination: {
               total_objects: 0,
               per_page: 20,
               page: 1
            },
            data: [],
            lastObject: null,
         }
      },

      computed: {
         formTitle() {
            return this.form.id ? 'Edit Customer' : 'Create Customer'
         }
      },

      created() {
         this.reload();
      },

      methods: {
         columnActions(h, params) {
            let actions = [];
            if (this.show) {
               actions.push(h('Button', {
                          props: {type: 'default', size: 'small'},
                          style: {marginRight: '5px'},
                          on: {
                             click: () => this.show(params.index)
                          }
                       }, 'View'),
               )
            }
            if (this.$store.getters.hasAccess('company', 'update') && !params.row.is_deleted) {
               actions.push(h('Button', {
                  props: {type: 'primary', size: 'small'},
                  style: {marginRight: '5px'},
                  on: {
                     click: () => this.edit(params.index)
                  }
               }, 'Edit'))
            }
            if (this.$store.getters.hasAccess('company', 'delete') && !params.row.is_deleted) {
               actions.push(h('Poptip', {
                  props: {
                     confirm: true,
                     title: 'Are you sure you want to delete this item?',
                     placement: 'left',
                     transfer: true,
                  },
                  on: {
                     'on-ok': () => this.remove(params.index)
                  }
               }, [h('Button', {
                  props: {
                     type: 'error',
                     size: 'small'
                  },
                  style: {
                     marginRight: '5px'
                  },
               }, 'Delete')]));
            }
            return actions
         },

         onOk() {
            console.log('ok', arguments);
            this.form
                    .save()
                    .then(resp => {
                       this.modal = false;
                       this.reload()
                    })
                    .catch(err => {
                       this.$refs['form'].validate();
                       this.$refs['modal'].buttonLoading = false
                    })
         },

         onCancel() {
            console.log('cancel', arguments)
         },

         create() {
            this.$refs.modal.open()
         },

         show(index) {
            this.$router.push({name: 'appCustomers', params: {id: this.data[index].id}})
         },

         edit(index) {
            //this.form = this.data[index].clone();
         },

         remove(index) {
            this.data[index]
                    .delete()
                    .then(resp => {
                       this.reload()
                    })
                    .catch(err => {
                       this.reload()
                    })
         },

         reload() {
            this.loading = true;
            this.lastObject && this.lastObject.cancel('ignore');
            let company = this.lastObject = new Company();
            if (this.search) {
               company = company.where('search', encodeURIComponent(this.search))
            }
            if (this.search !== this.lastSearch) {
               this.lastSearch = this.search;
               this.pagination.page = 1
            }
            company.paginate(this.pagination.per_page, this.pagination.page)
                    .then(resp => {
                       this.data = resp.data || [];
                       this.pagination.total_objects = resp.meta.total_objects;
                       this.loading = false
                    })
                    .catch(err => {
                       if (!err.toString().startsWith('Cancel')) {
                          console.warn(err);
                          this.loading = false
                       }
                    });
         },

         onChangeSearch(event) {
            this.search = event.target.value;
            this.reload()
         },

         changePage(page) {
            this.pagination.page = page;
            this.reload()
         },

         changePageSize(per_page) {
            this.pagination.per_page = per_page;
            this.reload()
         },
      }
   }
</script>
