import {Base} from './Base'
import {Terminal} from './Terminal'
import {Container} from './Container'
import {Company} from './Company'
import {ShippingLine} from './ShippingLine'
import {Branch} from "./Branch";
import {Destination} from "./Destination";

export class Booking extends Base {

    resourceName() {
        return 'bookings'
    }

    fields() {
        return [
            'number',
            'slots',
            'early_gate_confirmation',
            'booking_tracking_url',
            'container_states',
            'departure_date',
            'arrival_date',
            'vessel_name',
            'voyage',
            'is_system'
        ];
    }

    dates() {
        return {
            early_gate: 'YYYY-MM-DD',
            cut_off_day: 'YYYY-MM-DD',
            arrival_date: 'YYYY-MM-DD',
            departure_date: 'YYYY-MM-DD'
        }
    }

    relations() {
        return {
            company: (...arg) => new Company(...arg),
            in_terminal: (...arg) => new Terminal(...arg),
            out_terminal: (...arg) => new Terminal(...arg),
            containers: (...arg) => new Container(...arg),
            shipping_line: (...arg) => new ShippingLine(...arg),
            branch: (...arg) => new Branch(...arg),
            destination: (...arg) => new Destination(...arg),
        }
    }

}