import {Base} from './Base'

export class Access extends Base {
  resourceName() {
    return 'accesses'
  }

  fields() {
    return [
      'name',
      'grant',
      'object',
      'action',
      'description',
    ]
  }
}