<template>
    <Modal
            :title="title"
            ref="changeCarrier"
            v-model="visible"
            @on-ok="onOk">
        <Select v-focus
                v-model="value"
                ref="select"
                filterable
                remote
                prefix="ios-speedometer"
                :clearable="true"
                placeholder="Please, select replaceable carrier"
                :remote-method="handleSearch"
                :loading="loading">
            <Option v-for="item in carrierList" :value="item.id" :key="item.id">#{{item.id}} - {{item.name}}</Option>
        </Select>

    </Modal>
</template>

<script>
    import {Carrier} from "../../../models/Carrier";

    export default {

        data(){
            return{
                visible: false,
                removableCarrier: {},
                value: 0,
                carrierList: [],
                loading: false
            }
        },

        methods: {
            open(object){
                this.value = 0;
                this. removableCarrier = object;
                this.visible = true;
                this.$refs.select.$refs.search.focus()
            },

            handleSearch (value) {
                if(value !== null ) {
                    this.loading = true;
                    let carriers = new Carrier();
                    carriers = carriers.where('search_with_except', [encodeURIComponent(value), this.removableCarrier.id])
                    carriers.all()
                        .then(resp => {
                            this.carrierList = resp.data || [];
                            this.loading = false
                        })
                        .catch(err => {
                            console.log(err);
                            this.loading = false
                        });
                }
            },
            async onOk() {
                if(this.removableCarrier.id > 0 && this.value > 0) {
                    await this.removableCarrier.replace(this.value);
                    this.$emit('on-ok')
                }

            },

        },

        computed: {
            title(){
                return 'Replace carrier ' + this.removableCarrier.name + ' to:';
            }
        }
    }
</script>

<style scoped>

</style>
