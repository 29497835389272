<template>
    <div>
        <Card :bordered="false"
              :dis-hover="true">
            <p slot="title">{{item.name}}</p>
            <p><strong>Address:</strong> {{item.fullAddress ? item.fullAddress() : ''}}</p>
            <p><strong>Phone:</strong> {{item.phone}}</p>
            <p><strong>Fax:</strong> {{item.fax}}</p>
            <p><strong>Email:</strong> {{item.email}}</p>
        </Card>

        <Tabs value="drivers">
            <TabPane label="Drivers"
                     name="drivers">
                <Table :columns="columns"
                       :data="data"
                       :loading="loading"/>
            </TabPane>

            <template slot="extra">
                <template>&nbsp;</template>

                <Button type="primary"
                        @click="create"
                        v-if="$store.getters.hasAccess('dictionary', 'create')">
                    <template>New</template>
                </Button>
            </template>

            <Modal v-model="modal"
                   :title="formTitle"
                   @on-ok="onOk"
                   @on-cancel="onCancel"
                   :loading="true"
                   ref="modal">
                <Form ref="form"
                      :model="form"
                      :rules="form.rules"
                      label-position="right"
                      :label-width="100">
                    <FormItem prop="first_name"
                              label="First Name">
                        <Input size="large"
                               v-model="form.first_name"
                               :autofocus="true"
                               type="text"
                               placeholder="First Name"/>
                    </FormItem>

                    <FormItem prop="last_name"
                              label="Last Name">
                        <Input size="large"
                               v-model="form.last_name"
                               type="text"
                               placeholder="Last Name"/>
                    </FormItem>
                </Form>
            </Modal>
        </Tabs>
    </div>
</template>

<script>
  import {Driver} from '../../../models/Driver'
  import {Carrier} from '../../../models/Carrier'

  export default {
    data() {
      return {
        modal: false,
        form: {},
        loading: false,
        columns: [
            {
                title: ' ',
                key: 'avatar',
                width: 50,
                render: (h, params) => {
                    return h('UserAvatar', {props:
                            {'firstName': params.row.first_name,
                                'lastName': params.row.last_name
                            }
                    });
                }
            },
          {
            title: 'Name',
            key: 'full_name',
            render: (h, params) => h('span', {style: {textDecoration: params.row.is_deleted && 'line-through'}}, params.row.fullName),
          },
          {
            title: 'Actions',
            width: 140,
            key: 'actions',
            render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
          }
        ],
        pagination: {
          total_objects: 0,
          per_page: 50,
          page: 1
        },
        data: [],
        item: {}
      }
    },

    computed: {
      formTitle() {
        return this.form.id ? 'Edit Driver' : 'Create Driver'
      }
    },

    created() {
      this.reload()
    },

    methods: {
      columnActions(h, params) {
        let actions = [];
        if (this.show) {
          actions.push(h('Button', {
              props: {type: 'default', size: 'small'},
              style: {marginRight: '5px'},
              on: {
                click: () => this.show(params.index)
              }
            }, 'View'),
          )
        }
        if (this.$store.getters.hasAccess('dictionary', 'update') && !params.row.is_deleted) {
          actions.push(h('Button', {
            props: {type: 'primary', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => this.edit(params.index)
            }
          }, 'Edit'))
        }
        if (this.$store.getters.hasAccess('dictionary', 'delete') && !params.row.is_deleted) {
          actions.push(h('Poptip', {
            props: {
              confirm: true,
              title: 'Are you sure you want to delete this item?',
              placement: 'left',
              transfer: true,
            },
            on: {
              'on-ok': () => this.remove(params.index)
            }
          }, [h('Button', {
            props: {
              type: 'error',
              size: 'small'
            },
            style: {
              marginRight: '5px'
            },
          }, 'Delete')]));
        }
        return actions
      },

      onOk() {
        this.form
          .save()
          .then(resp => {
            this.modal = false;
            this.reload()
          })
          .catch(err => {
            this.$refs['form'].validate();
            this.$refs['modal'].buttonLoading = false
          })
      },

      onCancel() {
        console.log('cancel', arguments)
      },

      create() {
        this.form = new Driver();
        this.form.carrier = this.item;
        this.modal = true;
      },

      edit(index) {
        this.form = this.data[index].clone();
        this.modal = true;
      },

      remove(index) {
        this.data[index]
          .delete()
          .then(resp => {
            this.reload()
          })
          .catch(err => {
            this.reload()
          })
      },

      reload() {
        this.loading = true;
        new Carrier().with(['drivers.carrier']).find(this.$route.params.id)
          .then(resp => {
            this.item = resp;
            this.data = resp.drivers.data || [];
            this.loading = false
          })
          .catch(err => {
            console.log(err);
            this.loading = false
          });
      },

      changePage(page) {
        this.pagination.page = page;
        this.reload()
      },

      changePageSize(per_page) {
        this.pagination.per_page = per_page;
        this.reload()
      },
    }
  }
</script>
