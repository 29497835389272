import {AUTH_401, AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS} from '../actions/auth'
import {USER_REQUEST} from '../actions/user'
import axios from 'axios'

const state = {token: localStorage.getItem('user-token') || '', status: '', hasLoadedOnce: false};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
};

const actions = {
  [AUTH_REQUEST]: async ({commit, dispatch}, user) => {
    commit(AUTH_REQUEST);
    try {
      const resp = await axios({url: '/api/employees/sign_in', data: user, method: 'POST'});
      const token = resp.headers.authorization;
      console.log(resp.headers);
      localStorage.setItem('user-token', token);
      localStorage.setItem('isPartner', String(resp.data.partner) || "false");
      commit(AUTH_SUCCESS, token);
      dispatch(USER_REQUEST)
    } catch (err) {
      commit(AUTH_ERROR, err);
      localStorage.removeItem('user-token');
      throw err
    }
  },

  [AUTH_LOGOUT]: async ({commit, dispatch}) => {
    await axios({url: '/api/employees/sign_out', method: 'DELETE'}).catch(console.warn);
    commit(AUTH_LOGOUT);
    localStorage.removeItem('user-token')
  },

  [AUTH_401]: async ({commit, dispatch}) => {
    commit(AUTH_401);
    localStorage.removeItem('user-token')
  }
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },

  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success';
    state.token = token;
    state.hasLoadedOnce = true
  },

  [AUTH_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true
  },

  [AUTH_LOGOUT]: (state) => {
    state.token = ''
  },

  [AUTH_401]: (state) => {
    state.token = '';
    state.status = ''
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
