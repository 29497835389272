<template>
    <div>
        <Card :bordered="false"
              :dis-hover="true">
            <p slot="title">{{item.name}}</p>
        </Card>

        <Tabs value="models">
            <TabPane label="Models"
                     name="models">
                <Table :columns="columns"
                       :data="data"
                       :loading="loading"/>

                <Page :total="pagination.total_objects"
                      :current="pagination.page"
                      :page-size="pagination.per_page"
                      @on-change="changePage"
                      @on-page-size-change="changePageSize"
                      show-total
                      n-show-sizer/>
            </TabPane>

            <template slot="extra">
                <template>&nbsp;</template>

                <Button type="primary"
                        @click="create"
                        v-if="$store.getters.hasAccess('dictionary', 'create')">
                    <template>New</template>
                </Button>
            </template>

            <Modal v-model="modal"
                   :title="formTitle"
                   @on-ok="onOk"
                   @on-cancel="onCancel"
                   :loading="true"
                   ref="modal">
                <Form ref="form"
                      :model="form"
                      :rules="form.rules"
                      label-position="right"
                      :label-width="100">
                    <FormItem prop="name"
                              label="Name">
                        <Input size="large"
                               v-model="form.name"
                               :autofocus="true"
                               type="text"
                               placeholder="Name"/>
                    </FormItem>
                </Form>
            </Modal>
        </Tabs>
    </div>
</template>

<script>
  import {VehicleModel} from '../../../models/VehicleModel'
  import {VehicleMake} from '../../../models/VehicleMake'

  export default {
    data() {
      return {
        branch: {},
        modal: false,
        form: {},
        loading: false,
        columns: [
          {title: 'Name', key: 'name'},
          {
            title: 'Actions',
            width: 140,
            key: 'actions',
            render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
          }
        ],
        pagination: {
          total_objects: 0,
          per_page: 50,
          page: 1
        },
        data: [],
        item: {},
      }
    },

    computed: {
      formTitle() {
        return this.form.id ? 'Edit Model' : 'Create Model'
      }
    },

    created() {
      this.reload()
    },

    methods: {
      columnActions(h, params) {
        let actions = [];
        if (this.show) {
          actions.push(h('Button', {
              props: {type: 'default', size: 'small'},
              style: {marginRight: '5px'},
              on: {
                click: () => this.show(params.index)
              }
            }, 'View'),
          )
        }
        if (this.$store.getters.hasAccess('dictionary', 'update')) {
          actions.push(h('Button', {
            props: {type: 'primary', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => this.edit(params.index)
            }
          }, 'Edit'))
        }
        if (this.$store.getters.hasAccess('dictionary', 'delete')) {
          actions.push(h('Poptip', {
            props: {
              confirm: true,
              title: 'Are you sure you want to delete this item?',
              placement: 'left',
              transfer: true,
            },
            on: {
              'on-ok': () => this.remove(params.index)
            }
          }, [h('Button', {
            props: {
              type: 'error',
              size: 'small'
            },
            style: {
              marginRight: '5px'
            },
          }, 'Delete')]));
        }
        return actions
      },

      onOk() {
        this.form
          .save()
          .then(resp => {
            this.modal = false;
            this.reload()
          })
          .catch(err => {
            console.error(err);
            this.$refs['form'].validate();
            this.$refs['modal'].buttonLoading = false
          })
      },

      onCancel() {
        console.log('cancel', arguments)
      },

      create() {
        this.form = new VehicleModel();
        this.form.vehicle_make = this.item;
        this.modal = true
      },

      edit(index) {
        this.form = this.data[index].clone();
        this.modal = true
      },

      remove(index) {
        this.data[index]
          .delete()
          .then(resp => {
            this.reload()
          })
          .catch(err => {
            this.reload()
          })
      },

      reload() {
        this.loading = true;
        new VehicleMake().with(['vehicle_models.vehicle_make']).find(this.$route.params.id)
          .then(resp => {
            this.item = resp;
            this.data = resp.vehicle_models.data || [];
            this.loading = false
          })
          .catch(err => {
            console.log(err);
            this.loading = false
          });
      },

      changePage(page) {
        this.pagination.page = page;
        this.reload()
      },

      changePageSize(per_page) {
        this.pagination.per_page = per_page;
        this.reload()
      },
    }
  }
</script>