<template>
    <div class="alert-container">
        <Alert type="error" show-icon v-for="(item, index) in data" v-bind:key="index">
            <Icon type="ios-bulb-outline" slot="icon"></Icon>
            <span class="message">{{item.message}}</span>
        </Alert>
    </div>
</template>

<script>
    import {Notification} from "../models/Notification";

    export default {
        name: "NotificationCustomer",

        data() {
            return {
                isNotifications: true,
                data: []
            }

        },

        created() {
            new Notification()
                .filter('current')
                .all()
                .then(resp => {
                    this.data = resp.data || [];
                    this.isNotifications = true
                })
                .catch(err => {
                    console.log(err);
                });
        },
    }
</script>

<style scoped>
  .alert-container{
      margin-bottom: 10px;
  }

  .message{
      font-weight: 800;
  }

</style>
