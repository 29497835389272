<template>
    <Card class="wiget">
        <Row>
            <Col>
                <h3 class="count">{{count}}</h3>
                <p class="subText">{{name}}</p>
            </Col>
        </Row>
    </Card>
</template>

<script>

    export default {
        name: 'InforCard',
        props: {
            count: {
                type: Number,
                default: 0
            },

            icon: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            }
        }
    }
</script>


<style lang="scss">
    .wiget {
        margin: 5px;
        .count{
            text-align: center;
        }
    }

    .subText {
        color: #9ea7b4;
        text-align: center;
    }

    .spin-icon-load{
        animation: ani-spin 1s linear infinite;
    }
    @keyframes ani-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }

</style>
