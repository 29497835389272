import {Base} from './Base'
import {Booking} from "./Booking"

export class Terminal extends Base {

  resourceName() {
    return 'terminals'
  }

  fields() {
    return [
      'name',
      'booking_track_url',
      'container_track_url',
      'is_deleted',
    ]
  }

  relations() {
    return {
      in_bookings: (...arg) => new Booking(...arg),
      out_bookings: (...arg) => new Booking(...arg),
    }
  }

}