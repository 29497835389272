import {
  CUSTOMERS_AUTH_REQUEST,
  CUSTOMERS_AUTH_ERROR,
  CUSTOMERS_AUTH_SUCCESS,
  CUSTOMERS_AUTH_LOGOUT,
  CUSTOMERS_AUTH_401,
} from '../actions/customers'
import axios from 'axios'
import {USER_REQUEST} from "../actions/user";

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false
};

const getters = {
  // isAuthenticated: state => !!state.token,
  // authStatus: state => state.status,
};

const actions = {
  [CUSTOMERS_AUTH_REQUEST]: ({commit, dispatch}, user) => {
    return new Promise((resolve, reject) => {
      commit(CUSTOMERS_AUTH_REQUEST);
      axios({url: '/api/customers/sign_in', data: user, method: 'POST'})
        .then(resp => {
          localStorage.setItem('user-token', resp.headers.authorization);
          commit(CUSTOMERS_AUTH_SUCCESS, resp);
          dispatch(USER_REQUEST);
          resolve(resp)
        })
        .catch(err => {
          commit(CUSTOMERS_AUTH_ERROR, err);
          localStorage.removeItem('user-token');
          reject(err)
        })
    })
  },

  [CUSTOMERS_AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      axios({url: '/api/customers/sign_out', method: 'DELETE'})
        .then(resp => {
          commit(CUSTOMERS_AUTH_LOGOUT);
          localStorage.removeItem('user-token');
          resolve()
        })
        .catch(err => {
          commit(CUSTOMERS_AUTH_LOGOUT);
          localStorage.removeItem('user-token');
          reject(err)
        })
    })
  },

  [CUSTOMERS_AUTH_401]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      commit(CUSTOMERS_AUTH_401);
      localStorage.removeItem('user-token');
      resolve()
    })
  }
};

const mutations = {
  [CUSTOMERS_AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },

  [CUSTOMERS_AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.token = resp.headers.authorization;
    state.hasLoadedOnce = true
  },

  [CUSTOMERS_AUTH_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true
  },

  [CUSTOMERS_AUTH_LOGOUT]: (state) => {
    state.token = ''
  },

  [CUSTOMERS_AUTH_401]: (state) => {
    state.token = '';
    state.status = ''
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}