<template>
    <Card :bordered="false"
          :dis-hover="true"
          :title=" (form.id ? 'Edit' : 'Create') + ' Inventory ' + additionalInformation">
        <template slot="extra"
                  style="top: 7px">
            <Button @click="onCancel"
                    size="large">
                <template>Back</template>
            </Button>
        </template>

        <Form ref="form"
              :model="form"
              :rules="form.rules"
              label-position="top"
              :label-width="0">
            <Row :gutter="16">
                <Col :sm="24">
                    <Tabs value="inventory">
                        <TabPane label="Inventory" name="inventory">
                            <Row :gutter="16">
                                <Col :md="16" :sm="24">
                                    <Row :gutter="8">
                                        <Col span="8">
                                            <FormItem prop="date_of_receiving"
                                                      label="Receiving date">
                                                <DatePicker
                                                        :value="form.date_of_receiving && form.date_of_receiving.isValid() ? form.date_of_receiving.toDate() : null"
                                                        type="date"
                                                        placeholder="Select date"
                                                        size="large"
                                                        format="MM/dd/yyyy"
                                                        readonly="readonly"
                                                        disabled="disabled"/>
                                            </FormItem>
                                        </Col>
                                        <Col span="8">
                                            <FormItem prop="inventory_type"
                                                      label="Type of inventory">
                                                <Select :value="form.inventory_type ? form.inventory_type.id : null"
                                                        size="large"
                                                        disabled="disabled">
                                                    <Option v-for="item in listInventoryTypes"
                                                            :value="item.id"
                                                            :key="item.id">
                                                        <template>{{item.name}}</template>
                                                    </Option>
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col span="8">
                                            <FormItem prop="branch"
                                                      label="Branch">
                                                <Select :value="form.branch ? form.branch.id : null"
                                                        filterable
                                                        size="large"
                                                        disabled="disabled">
                                                    <Option v-for="item in listBranches"
                                                            v-if="!item.is_deleted || (item.is_deleted && form.branch && form.branch.id === item.id)"
                                                            :disabled="item.is_deleted"
                                                            :value="item.id"
                                                            :key="item.id">
                                                        <template>{{item.name}}</template>
                                                    </Option>
                                                </Select>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row :gutter="8">
                                        <Col span="8">
                                            <FormItem prop="year"
                                                      label="Year">
                                                <DatePicker
                                                        :value="form.year && form.year.isValid() ? form.year.toDate() : null"
                                                        type="year"
                                                        placeholder="Select year"
                                                        size="large"
                                                        format="yyyy"
                                                        readonly="readonly"
                                                        disabled="disabled"/>
                                            </FormItem>
                                        </Col>

                                        <Col span="8">
                                            <FormItem prop="make"
                                                      label="Make">
                                                <Input size="large"
                                                       v-model="form.make"
                                                       type="text"
                                                       disabled="disabled"
                                                       placeholder="Make"/>
                                            </FormItem>
                                        </Col>

                                        <Col span="8">
                                            <FormItem prop="model"
                                                      label="Model">
                                                <Input size="large"
                                                       v-model="form.model"
                                                       type="text"
                                                       disabled="disabled"
                                                       placeholder="Model"/>
                                            </FormItem>
                                        </Col>

                                    </Row>
                                    <Row :gutter="8">
                                        <Col span="8">
                                            <FormItem prop="color"
                                                      label="Color">
                                                <Select :value="form.color ? form.color.id : null"
                                                        filterable
                                                        size="large"
                                                        disabled="disabled">
                                                    <Option v-for="item in listColors"
                                                            v-if="!item.is_deleted || (item.is_deleted && form.color && form.color.id === item.id)"
                                                            :disabled="item.is_deleted"
                                                            :value="item.id"
                                                            :key="item.id">
                                                        <template>{{item.name}}</template>
                                                    </Option>
                                                </Select>
                                            </FormItem>
                                        </Col>

                                        <Col span="8">
                                            <FormItem prop="vin"
                                                      label="VIN">
                                                <Input size="large"
                                                       v-model="form.vin"
                                                       type="text"
                                                       disabled="disabled"
                                                       placeholder="VIN code"/>
                                            </FormItem>
                                        </Col>

                                    </Row>

                                </Col>

                                <Col :md="8"
                                     :sm="24">
                                    <Row :gutter="16">
                                        <Col :md="{span: 24, push: 0}"
                                             :sm="{span: 16, push: 4}">
                                            <Upload ref="upload"
                                                    multiple
                                                    type="drag"
                                                    :action="'not-implemented'"
                                                    :show-upload-list="false"
                                                    :before-upload="handleBeforeUpload">
                                                <div style="padding: 20px 0">
                                                    <Icon type="ios-cloud-upload"
                                                          size="52"
                                                          style="color: #3399ff"/>
                                                    <p>Click or drag files here to upload</p>
                                                </div>
                                            </Upload>

                                            <div v-if="listOfNewFiles.length > 0">
                                                <Button @click="addFiles"
                                                        :loading="buttonLoading"
                                                        size="large">
                                                    <template>Save</template>
                                                </Button>

                                                <Progress :percent="uploadPercent"
                                                          hide-info/>
                                                {{listOfNewFiles.length}} image(s) waiting to upload. Please, click save
                                                button!
                                            </div>

                                            <br/>
                                        </Col>
                                    </Row>

                                    <Row :gutter="8">
                                        <Col :md="24">
                                            <div style="column-count: 3; column-gap: 0; line-height: 0;"
                                                 v-viewer="{title: false, rotatable: false, scalable: false}">
                                                <div class="upload-preview-list"
                                                     v-for="item in listOfNewFiles"
                                                     :key="item.uuid"
                                                     style="width: 100%; height: auto;">
                                                    <img :src="item.link" style="width: 100%; height: 100%;">

                                                    <div class="upload-preview-list-cover">
                                                        <Icon type="ios-trash-outline"
                                                              @click.native="handlePreviewRemove(item)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <br/>

                                    <Row :gutter="8">
                                        <Col :md="24">
                                            <div style="column-count: 3; column-gap: 0; line-height: 0;"
                                                 v-viewer="{title: false, rotatable: false, scalable: false}">
                                                <div class="upload-preview-list"
                                                     v-for="(item, index) in form.images"
                                                     :key="index" style="width: 100%; height: auto;">
                                                    <img :src="item.url" style="width: 100%; height: 100%;">

                                                    <div class="upload-preview-list-cover">
                                                        <Icon type="ios-trash-outline"
                                                              @click.native="handleRemove(item)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Modal title="View Image"
                                           v-model="visible">
                                        <img :src="imageUrl"
                                             v-if="visible"
                                             style="width: 100%">
                                    </Modal>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Spin size="large" fix v-if="buttonLoading"/>
        </Form>
    </Card>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import {Color} from '../../../../models/Color'
  import {Branch} from '../../../../models/Branch'
  import {Vehicle} from '../../../../models/Vehicle'
  import {InventoryType} from "../../../../models/InventoryType";
  import uuid from '../../../../utils/uuid'

  export default {
    props: {
      value: Boolean,
    },

    watch: {
      '$route': 'changeRoute',
    },

    data() {
      return {
        headers: {
          authorization: localStorage.getItem('user-token'),
        },

        form: new Vehicle(),
        additionalInformation: '',

        listInventoryTypes: [],
        listColors: [],
        listBranches: [],
        buttonLoading: false,
        imageUrl: '',
        visible: false,
        listOfNewFiles: [],
        uploadList: [],
        uploadPercent: 0,
      }
    },

    mounted() {
      this.changeRoute();
      this.uploadList = this.$refs.upload.fileList
    },

    methods: {
      branchValidate(rule, value, callback) {
        if (value && (value.id !== value._uuid)) {
          callback()
        } else {
          if (this.$store.getters.hasAccess('dictionary', 'create') && value && (value.id === value._uuid)) {
            callback()
          } else {
            callback(new Error('please, choose a branch'))
          }
        }
      },

      inventoryTypeValidate(rule, value, callback) {
        if (value && (value.id !== value._uuid)) {
          callback()
        } else {
          if (this.$store.getters.hasAccess('dictionary', 'create') && value && (value.id === value._uuid)) {
            callback()
          } else {
            callback(new Error('please, choose an inventory type'))
          }
        }
      },

      async vinValidator(rule, value, callback) {
        if (!value) {
          callback(new Error('please, enter a VIN'))
        } else {
          const result = await this.form.checkVin()
            .catch(err => callback(new Error(err)));
          if (result) {
            callback()
          } else {
            callback(new Error('VIN already exists'))
          }
        }
      },

      reloadItem() {
        this.changeRoute()
      },

      changeRoute() {
        this.additionalInformation = '';
        this.reload();

        if (this.$route.params.id) {
          this.buttonLoading = true;
          new Vehicle()
            .with([
              'color',
              'branch',
              'inventory_type',
            ])
            .find(this.$route.params.id)
            .then((resp) => {
              this.form = resp;
              this.additionalInformation = [
                '[',
                this.form.make,
                this.form.model,
                this.form.year && this.form.year.isValid() ? this.form.year.format('YYYY') : null,
                this.form.color && this.form.color.name,
                this.form.vin,
                ']',
              ].join(' ');
              this.buttonLoading = false
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          if (this.form) {
            this.form.date_of_receiving = moment();
          }
        }
      },

      reload() {
        new Color().all().then(resp => this.listColors = resp.data || []).catch(console.log);
        new Branch().all().then(resp => this.listBranches = resp.data || []).catch(console.log);
        new InventoryType().all().then(resp => this.listInventoryTypes = resp.data || []).catch(console.log);
      },

      onCancel() {
        this.$router.go(-1);
        this.$emit('on-cancel')
      },

      addFiles() {
        let data = new FormData();
        this.listOfNewFiles.forEach(item => data.append('images[]', item.file));

        axios
          .request({
            url: this.form.resourceImages(),
            data: data,
            method: 'post',
            onUploadProgress: (progressEvent) => {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          })
          .then(resp => {
            this.buttonLoading = false;
            this.listOfNewFiles.splice(0, this.listOfNewFiles.length);
            this.uploadPercent = 0;
            this.$router.replace({name: 'partnerInventoriesEdit', params: {id: this.form.id}});
            this.changeRoute();
            this.$emit('on-ok');
            this.$Notice.success({
              title: 'Saved success',
            })
          })
          .catch(error => {
            console.error(error);
            this.buttonLoading = false;
            this.$Notice.error({
              title: 'Save images failed',
              desc: `The remote server returned an error: (${error.response.status}) ${error.response.statusText}`
            })
          })

      },

      handleView(url) {
        this.imageUrl = url;
        this.visible = true
      },

      handleBeforeUpload(file) {
        this.listOfNewFiles.push({uuid: uuid(), file: file, link: URL.createObjectURL(file)});
        return false
      },

      handlePreviewRemove(item) {
        this.listOfNewFiles = this.listOfNewFiles.filter(temp => temp.uuid !== item.uuid)
      },

      handleRemove(item) {
        axios
          .request({
              url: this.form.resourceImages() + item.url.split('/').pop(),
              method: 'delete'
            }
          )
          .then(resp => {
            console.log(resp);
            this.form.images = this.form.images.filter(temp => {
              return temp.url !== item.url
            })
          })
          .catch(error => {
            console.error(error)
          })
      },
    },
  }
</script>
