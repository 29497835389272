<template>
  <Card :bordered="false"
        :dis-hover="true"
        :title=" (form.id ? 'Edit' : 'Create') + ' Inventory ' + additionalInformation">
    <template slot="extra"
              style="top: 7px">
      <Downloads name="Download Images"
                 :disabled="buttonLoading"
                 :url="`inventories/${form.id}/zip`"
                 v-if="form.id"/>
      <Downloads name="Download CR"
                 :disabled="buttonLoading"
                 :url="`condition_reports/${form.id}`"
                 v-if="form.id"/>

      <Button @click="onCancel">
        <template>Cancel</template>
      </Button>

      <Button type="primary"
              @click="onSave"
              :loading="buttonLoading">
        <template>Save</template>
      </Button>

      <Button type="primary"
              @click="onSaveAndReturn"
              :loading="buttonLoading">
        <template>Save and return</template>
      </Button>
    </template>

    <Form ref="form"
          :model="form"
          :rules="form.rules"
          label-position="top">
      <Row :gutter="16">
        <Col :sm="24">
          <Tabs value="inventory">
            <TabPane label="Inventory" name="inventory">
              <Row :gutter="16">
                <Col :md="16" :sm="24">
                  <Row :gutter="8">
                    <Col span="8">
                      <FormItem prop="storage_amount"
                                label="Storage Amount">
                        <Input size="large"
                               v-model="form.storage_amount"
                               :autofocus="true"
                               type="text"
                               prefix="logo-usd"
                               placeholder="Storage Amount"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="towed"
                                label="Towed">
                        <RadioGroup :value="form.towed === true ? 'true' : 'false'"
                                    @input="form.towed = $event"
                                    size="large">
                          <Radio label="true">Yes</Radio>
                          <Radio label="false">Customer dropped off</Radio>
                        </RadioGroup>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="towed_amount"
                                label="Towed Amount">
                        <Input size="large"
                               v-model="form.towed_amount"
                               :autofocus="true"
                               type="text"
                               prefix="logo-usd"
                               placeholder="Towed Amount"/>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row :gutter="8">
                    <Col span="3">
                      <FormItem prop="cod_confirmed"
                                label="COD Confirmed">
                        <i-switch v-model="form.cod_confirmed">
                          <Icon type="android-done"
                                slot="open"/>

                          <Icon type="android-close"
                                slot="close"/>
                        </i-switch>
                      </FormItem>
                    </Col>

                    <Col span="5">
                      <FormItem prop="cod_amount"
                                label="COD">
                        <Input size="large"
                               v-model="form.cod_amount"
                               :autofocus="true"
                               type="text"
                               prefix="logo-usd"
                               placeholder="COD"/>
                      </FormItem>
                    </Col>

                    <Col span="5">
                      <FormItem prop="cod"
                                label="COD Status">
                        <Select :value="form.cod ? form.cod.id : null"
                                size="large"
                                @on-change="onChangeCod">
                          <Option v-for="item in listCods"
                                  v-if="!item.is_deleted || (item.is_deleted && form.cod && form.cod.id === item.id)"
                                  :disabled="item.is_deleted"
                                  :value="item.id"
                                  :key="item.id">
                            <template>{{ item.name }}</template>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>

                    <Col span="5">
                      <FormItem prop="cost"
                                label="Value">
                        <Input size="large"
                               v-model="form.cost"
                               type="text"
                               prefix="logo-usd"
                               placeholder="Value"/>
                      </FormItem>
                    </Col>

                    <Col span="6">
                      <FormItem prop="check_number"
                                label="Check Number">
                        <Input size="large"
                               v-model="form.check_number"
                               type="text"
                               placeholder="Check number"/>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row :gutter="8">
                    <Col span="3">
                      <FormItem prop="title_present"
                                label="Title Present">
                        <i-switch v-model="form.title_present">
                          <Icon type="android-done"
                                slot="open"/>

                          <Icon type="android-close"
                                slot="close"/>
                        </i-switch>
                      </FormItem>
                    </Col>

                    <Col span="5">
                      <FormItem prop="title_date"
                                label="Title Received">
                        <DatePicker
                            :value="form.title_date && form.title_date.isValid() ? form.title_date.toDate() : null"
                            type="date"
                            placeholder="Select date"
                            size="large"
                            format="MM/dd/yyyy"
                            @on-change="onChangeTitleDate"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="title"
                                label="Title Status">
                        <Select :value="form.title ? form.title.id : null"
                                size="large"
                                @on-change="onChangeTitle">
                          <Option v-for="item in listTitles"
                                  v-if="!item.is_deleted || (item.is_deleted && form.title && form.title.id === item.id)"
                                  :disabled="item.is_deleted"
                                  :value="item.id"
                                  :key="item.id">
                            <template>{{ item.name }}</template>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="lien"
                                label="Lien">
                        <Select :value="form.lien ? form.lien.id : null"
                                filterable
                                size="large"
                                @on-change="onChangeLien">
                          <Option v-for="item in listLiens"
                                  v-if="!item.is_deleted || (item.is_deleted && form.lien && form.lien.id === item.id)"
                                  :disabled="item.is_deleted"
                                  :value="item.id"
                                  :key="item.id">
                            <template>{{ item.name }}</template>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row :gutter="8">
                    <Col span="24">
                      <FormItem prop="title_info"
                                label="Name on the Title">
                        <Input v-model="form.title_info"
                               type="textarea"
                               :autosize="{minRows: 3,maxRows: 10}"
                               placeholder="Enter something..."/>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row :gutter="8">
                    <Col span="8">
                      <FormItemSelectCompany v-model="form.company"
                                             ref="company"/>
                    </Col>

                    <Col span="8">
                      <FormItemSelectCarrier v-model="form.carrier"
                                             ref="carrier"/>
                    </Col>

                    <Col span="8">
                      <FormItem prop="date_of_receiving"
                                label="Receiving date">
                        <DatePicker
                            :value="form.date_of_receiving && form.date_of_receiving.isValid() ? form.date_of_receiving.toDate() : null"
                            type="date"
                            placeholder="Select date"
                            size="large"
                            format="MM/dd/yyyy"
                            @on-change="onChangeDate"/>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row :gutter="8">
                    <Col span="24">
                      <FormItem prop="note"
                                label="Notes">
                        <Input v-model="form.note"
                               type="textarea"
                               :autosize="{minRows: 5,maxRows: 10}"
                               placeholder="Enter something..."/>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>

                <Col :md="8"
                     :sm="24">
                  <Row :gutter="16">
                    <Col :md="{span: 24, push: 0}" :sm="{span: 16, push: 4}">
                      <MultiUploader :add-image-url="form.resourceImages()"
                                     :image-list="pictures"
                                     :loading="loadPictures"
                                     v-if="form.id"/>
                    </Col>
                  </Row>

                  <br/>

                  <Row :gutter="8">
                    <Col :md="24">
                      <div style="column-count: 3; column-gap: 0; line-height: 0;"
                           v-viewer="{title: false, rotatable: false, scalable: false}">
                        <div class="upload-preview-list"
                             v-for="(item, index) in form.images"
                             :key="index" style="width: 100%; height: auto;">
                          <img :src="item.thumb" style="width: 100%; height: 100%;">

                          <div class="upload-preview-list-cover">
                            <Icon type="ios-trash-outline"
                                  @click.native="handleRemove(item)"/>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </TabPane>
            <TabPane label="Condition report" name="cr">
              <Row :gutter="16">
                <Col :md="24" :sm="24">
                  <Row :gutter="8">
                    <Col span="8">
                      <FormItem prop="inventory_type"
                                label="Type of inventory">
                        <Select :value="form.inventory_type ? form.inventory_type.id : null"

                                size="large"
                                @on-change="onChangeInventoryType">
                          <Option v-for="item in listInventoryTypes"
                                  v-if="!item.is_deleted || (item.is_deleted && form.inventory_type && form.inventory_type.id === item.id)"
                                  :value="item.id"
                                  :key="item.id">
                            <template>{{ item.name }}</template>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span="8">
                      <FormItem prop="branch"
                                label="Branch">
                        <Select :value="form.branch ? form.branch.id : null"
                                filterable
                                size="large"
                                @on-change="onChangeBranches">
                          <Option v-for="item in listBranches"
                                  v-if="!item.is_deleted || (item.is_deleted && form.branch && form.branch.id === item.id)"
                                  :disabled="item.is_deleted"
                                  :value="item.id"
                                  :key="item.id">
                            <template>{{ item.name }}</template>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>

                  <Row :gutter="8">
                    <Col span="8">
                      <FormItem prop="year"
                                label="Year">
                        <DatePicker
                            :value="form.year && form.year.isValid() ? form.year.toDate() : null"
                            type="year"
                            placeholder="Select year"
                            size="large"
                            format="yyyy"
                            @on-change="onChangeYear"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="make"
                                label="Make">
                        <Input size="large"
                               v-model="form.make"
                               type="text"
                               placeholder="Make"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="model"
                                label="Model">
                        <Input size="large"
                               v-model="form.model"
                               type="text"
                               placeholder="Model"/>
                      </FormItem>
                    </Col>

                  </Row>

                  <Row :gutter="8">
                    <Col span="8">
                      <FormItem prop="color"
                                label="Color">
                        <Select :value="form.color ? form.color.id : null"
                                filterable
                                size="large"
                                @on-change="onChangeColor">
                          <Option v-for="item in listColors"
                                  v-if="!item.is_deleted || (item.is_deleted && form.color && form.color.id === item.id)"
                                  :disabled="item.is_deleted"
                                  :value="item.id"
                                  :key="item.id">
                            <template>{{ item.name }}</template>
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="vin"
                                label="VIN">
                        <Input size="large"
                               v-model="form.vin"
                               @on-blur="vincheck"
                               type="text"
                               placeholder="VIN code"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="hat_number"
                                label="HAT #">
                        <Input size="large"
                               v-model="form.hat_number"
                               type="text"
                               placeholder="HAT #"/>
                      </FormItem>
                    </Col>
                  </Row>

                  <Row :gutter="8">
                    <Col span="8">
                      <FormItem prop="lot_number"
                                label="Lot #">
                        <Input size="large"
                               v-model="form.lot_number"
                               type="text"
                               placeholder="Lot #"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="dimension"
                                label="Dimension">
                        <Input size="large"
                               v-model="form.dimension"
                               type="text"
                               placeholder="Dimension"/>
                      </FormItem>
                    </Col>

                    <Col span="8">
                      <FormItem prop="items"
                                label="Items in vehicle">
                        <Input size="large"
                               v-model="form.items"
                               type="text"
                               placeholder="Item in vehicle"/>
                      </FormItem>
                    </Col>
                  </Row>

                  <Row :gutter="8">
                    <Col span="4">
                      <FormItem prop="keys"
                                label="Key present">
                        <RadioGroup :value="form.keys ? form.keys : 'no_key' "
                                    @input="form.keys = $event"
                                    size="large"
                                    vertical>
                          <Radio label="no_key">No key</Radio>
                          <Radio label="key_only">Key only</Radio>
                          <Radio label="fob_only">Fob only</Radio>
                          <Radio label="complete_key">Complete key</Radio>
                        </RadioGroup>
                      </FormItem>
                    </Col>

                    <template v-for="item in listOptions">
                      <Col :key="item.id" span="4" v-if="!item.is_deleted || getValueById(item)">
                        <FormItem :prop="item.id"
                                  :label="item.name">
                          <RadioGroup :value="getValueById(item) === true ? 'true' : 'false'"
                                      @input="changeOption($event, item)"
                                      size="large">
                            <Radio label="true" :disabled="item.is_deleted">Yes</Radio>
                            <Radio label="false" :disabled="item.is_deleted">No</Radio>
                          </RadioGroup>
                        </FormItem>
                      </Col>
                    </template>
                  </Row>

                  <Row :gutter="8">
                    <Col span="4">
                      <FormItem prop="damaged"
                                label="Damaged">
                        <RadioGroup :value="form.damaged === true ? 'true' : 'false'"
                                    @input="form.damaged = $event"
                                    size="large">
                          <Radio label="true">Yes</Radio>
                          <Radio label="false">No</Radio>
                        </RadioGroup>
                      </FormItem>
                    </Col>

                    <Col span="4">
                      <FormItem prop="operable"
                                label="Operable">
                        <RadioGroup :value="form.operable === true ? 'true' : 'false'"
                                    @input="form.operable = $event"
                                    size="large">
                          <Radio label="true">Yes</Radio>
                          <Radio label="false">No</Radio>
                        </RadioGroup>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane label="Attachments (Internal)"
                     name="attachments_secret"
                     v-if="form.id">
              <Attachments :form="form"
                           type="secret"
                           @reload="reloadItem"/>
            </TabPane>
            <TabPane label="Attachments (External)"
                     name="attachments_open"
                     v-if="form.id">
              <Attachments :form="form"
                           type="open"
                           @reload="reloadItem"/>
            </TabPane>
            <TabPane label="Activity"
                     name="activity"
                     v-if="form.id">
              <Versions :form="form"
                        resource="inventories"/>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Spin size="large" fix v-if="buttonLoading"/>
    </Form>
  </Card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {Cod} from '../../../models/Cod'
import {Lien} from '../../../models/Lien'
import {Color} from '../../../models/Color'
import {Title} from '../../../models/Title'
import {Branch} from '../../../models/Branch'
import {Option} from '../../../models/Option'
import {Carrier} from '../../../models/Carrier'
import {Company} from '../../../models/Company'
import {Vehicle} from '../../../models/Vehicle'
import {VehicleCondition} from '../../../models/VehicleCondition'
import {InventoryType} from "../../../models/InventoryType";
import Downloads from '../../../components/Downloads'
import Attachments from '../../../components/Attachments'
import Versions from '../../../components/Versions'
import FormItemSelectCompany from '../../../components/FormItemSelectCompany'
import FormItemSelectCarrier from '../../../components/FormItemSelectCarrier'
import MultiUploader from '../../../components/ImageMultiUploader'

export default {
  props: {
    value: Boolean,
  },

  components: {
    Downloads,
    Attachments,
    FormItemSelectCarrier,
    FormItemSelectCompany,
    MultiUploader,
    Versions,
  },

  watch: {
    '$route': 'changeRoute',
  },

  data() {
    return {
      headers: {
        authorization: localStorage.getItem('user-token'),
      },
      form: new Vehicle(),
      additionalInformation: '',
      listCods: [],
      listInventoryTypes: [],
      listLiens: [],
      listColors: [],
      listTitles: [],
      listOptions: [],
      listBranches: [],
      listVehicleConditions: [],
      listCarriers: [],
      buttonLoading: false,
      loadPictures: true,
      imageUrl: '',
      visible: false,
      uploadList: [],
      pictures: []
    }
  },

  mounted() {
    this.changeRoute();
  },

  methods: {

    vincheck() {
      if (this.form.vin.length === 17 && !this.form.make && !this.form.model) {
        axios({
          url: 'https://www.hmotorsinc.com/api/v1/vin/' + this.form.vin,
          method: 'GET',
          crossDomain: true

        })
            .then(response => {
              var result = response.data.vehicles;
              if (result.length > 0) {
                this.$set(this.form, 'make', result[0].make.toUpperCase());
                this.$set(this.form, 'model', result[0].model.toUpperCase());
                this.$set(this.form, 'year', moment(result[0].year, 'YYYY'));
                this.$Message.success('Vehicle info filled automatically');
              }
            })
            .catch(error => {
              console.log(error)
            });
      }
    },

    companyValidate(rule, value, callback) {
      if (value && (value.id !== value._uuid)) {
        callback()
      } else {
        if (this.$store.getters.hasAccess('company', 'create') && value && (value.id === value._uuid)) {
          callback()
        } else {
          callback(new Error('please, choose a company'))
        }
      }
    },

    carrierValidate(rule, value, callback) {
      if (value && (value.id !== value._uuid)) {
        callback()
      } else {
        if (this.$store.getters.hasAccess('dictionary', 'create') && value && (value.id === value._uuid)) {
          callback()
        } else {
          callback(new Error('please, choose a carrier'))
        }
      }
    },

    branchValidate(rule, value, callback) {
      if (value && (value.id !== value._uuid)) {
        callback()
      } else {
        if (this.$store.getters.hasAccess('dictionary', 'create') && value && (value.id === value._uuid)) {
          callback()
        } else {
          callback(new Error('please, choose a branch'))
        }
      }
    },

    inventoryTypeValidate(rule, value, callback) {
      if (value && (value.id !== value._uuid)) {
        callback()
      } else {
        if (this.$store.getters.hasAccess('dictionary', 'create') && value && (value.id === value._uuid)) {
          callback()
        } else {
          callback(new Error('please, choose an inventory type'))
        }
      }
    },

    async vinValidator(rule, value, callback) {
      if (!value) {
        callback(new Error('please, enter a VIN'))
      } else {
        const result = await this.form.checkVin()
            .catch(err => callback(new Error(err)));
        if (result) {
          callback()
        } else {
          callback(new Error('VIN already exists'))
        }
      }
    },

    reloadItem() {
      this.changeRoute()
    },

    changeRoute() {
      this.loadPictures = true;
      this.additionalInformation = '';
      this.reload();

      if (this.$route.params.id) {
        this.buttonLoading = true;
        new Vehicle()
            .with([
              'carrier',
              'cod',
              'lien',
              'color',
              'title',
              'branch',
              'company',
              'attachments',
              'inventory_type',
              'vehicle_options.option',
              'vehicle_conditions.condition',
            ])
            .find(this.$route.params.id)
            .then((resp) => {
              this.form = resp;
              this.form.rules.company.push({
                validator: this.companyValidate,
                message: 'please, select item from list',
              });
              this.form.rules.branch.push({
                validator: this.branchValidate,
                message: 'please, select item from list',
              });
              this.form.rules.inventory_type.push({
                validator: this.inventoryTypeValidate,
                message: 'please, select item from list',
              });
              this.additionalInformation = [
                '[',
                this.form.make,
                this.form.model,
                this.form.year && this.form.year.isValid() ? this.form.year.format('YYYY') : null,
                this.form.color && this.form.color.name,
                this.form.vin,
                ']',
              ].join(' ');

              this.form.pictures().then(response => {
                this.pictures = response || [];
                this.loadPictures = false;
              }).catch(err => {
                this.loadPictures = false;
                console.error(err);
              });
              this.buttonLoading = false
              this.loadPictures = false;
            })
            .catch((error) => {
              console.error(error)
            })
      } else {
        if (this.form) {
          this.form.date_of_receiving = moment();
          this.form.rules.company.push({
            validator: this.companyValidate,
            message: 'please, select customer from list',
          });
          this.form.rules.carrier.push({
            validator: this.carrierValidate,
            message: 'please, select carrier from list',
          });
          this.form.rules.branch.push({
            validator: this.branchValidate,
            message: 'please, select branch from list',
          });
          this.form.rules.inventory_type.push({
            validator: this.inventoryTypeValidate,
            message: 'please, select inventory type from list',
          });
          this.form.rules.vin && this.form.rules.vin.push({
            validator: this.vinValidator,
            trigger: 'blur'
          });
        }
      }
    },

    reload() {
      new Cod().all().then(resp => this.listCods = resp.data || []).catch(console.log);
      new Lien().all().then(resp => this.listLiens = resp.data || []).catch(console.log);
      new Color().all().then(resp => this.listColors = resp.data || []).catch(console.log);
      new Title().all().then(resp => this.listTitles = resp.data || []).catch(console.log);
      new Branch().all().then(resp => this.listBranches = resp.data || []).catch(console.log);
      new Option().all().then(resp => this.listOptions = resp.data || []).catch(console.log);
      new InventoryType().all().then(resp => this.listInventoryTypes = resp.data || []).catch(console.log);
      new VehicleCondition().all().then(resp => this.listVehicleConditions = resp.data || []).catch(console.log);
    },

    onChangeBranches(id) {
      let filtered = this.listBranches.filter(item => item.id === id);
      this.form.branch = filtered.length ? filtered[0] : null
    },

    onChangeColor(id) {
      let filtered = this.listColors.filter(item => item.id === id);
      this.form.color = filtered.length ? filtered[0] : null
    },

    onChangeLien(id) {
      let filtered = this.listLiens.filter(item => item.id === id);
      this.form.lien = filtered.length ? filtered[0] : null
    },

    onChangeCod(id) {
      let filtered = this.listCods.filter(item => item.id === id);
      this.form.cod = filtered.length ? filtered[0] : null
    },

    onChangeInventoryType(id) {
      let filtered = this.listInventoryTypes.filter(item => item.id === id);
      this.form.inventory_type = filtered.length ? filtered[0] : null
    },

    onChangeTitle(id) {
      let filtered = this.listTitles.filter(item => item.id === id);
      this.form.title = filtered.length ? filtered[0] : null
    },

    onChangeDate(value) {
      this.form.date_of_receiving = moment(value, 'MM/DD/YYYY')
    },

    onChangeTitleDate(value) {
      this.form.title_date = moment(value, 'MM/DD/YYYY')
    },

    onChangeYear(value) {
      this.form.year = moment(value, 'YYYY')
    },

    changeOption(state, option) {
      if (!this.form.options) {
        this.$set(this.form, 'options', [])
      }
      this.form.options = this.form.options.filter(item => option.id !== item.option_id);
      if (state === 'true') {
        this.form.options.push({option_id: option.id})
      }
    },

    getValueById(option) {
      return (this.form.options || []).filter(item => option.id === item.option_id).length > 0
    },

    onSave() {
      this.onOk(false)
    },

    onSaveAndReturn() {
      this.onOk(true)
    },

    onOk(back = false) {
      console.log(this.form.errors);
      if (this.form.errors.vin) {
        this.form.errors.vin = null;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          delete this.form.vehicle_options;
          delete this.form.vehicle_conditions;
          this.saveCompany(back);
        } else {
          this.$Notice.warning({
            title: 'Error saving inventory',
            desc: 'An error occurred while saving the inventory. Check all warnings on each tab.',
            duration: 5
          })
        }
      });
    },

    saveCompany(back = false) {
      if (this.form.company && this.form.company._uuid) {
        let company = new Company();
        company.name = this.form.company.name;
        company.save().then((resp) => {
          this.$refs.company.reload()
              .then(none => {
                this.form.company = resp || {};
                this.saveCarrier(back)
              })
              .catch(none => {
                console.log(none)
              })
        })
      } else {
        this.saveCarrier(back)
      }
    },

    saveCarrier(back = false) {
      if (this.form.carrier && this.form.carrier._uuid) {
        let carrier = new Carrier();
        carrier.name = this.form.carrier.name;
        carrier.save().then((resp) => {
          this.$refs.carrier.reload()
              .then(none => {
                this.form.carrier = resp || {};
                this.saveItem(back)
              })
              .catch(none => {
                console.log(none)
              })
        })
      } else {
        this.saveItem(back)
      }
    },

    saveItem(back = false) {
      this.form
          .save()
          .then(resp => {
            this.form = resp;
            this.buttonLoading = false;
            this.$router.replace({name: 'appInventoriesEdit', params: {id: this.form.id}});
            if (back) {
              window.history.length > 2 ? this.$router.go(-2) : this.$router.push({name: 'appInventories'})
            } else {
              this.changeRoute()
            }
            this.$emit('on-ok');
            this.$Notice.success({
              title: 'Saved success',
            })
          })
          .catch(error => {
            console.error(error);
            this.$refs['form'].validate();
            this.buttonLoading = false;
            this.$Notice.error({
              title: 'Save inventory failed',
              desc: (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 && error.response.data.errors[0].detail) || `The remote server returned an error: (${error.response.status}) ${error.response.statusText}`
            })
          })
    },

    onCancel() {
      this.$router.go(-2);
      this.$emit('on-cancel')
    },

    handleRemove(item) {
      axios
          .request({
                url: this.form.resourceImages() + item.url.split('/').pop(),
                method: 'delete'
              }
          )
          .then(resp => {
            console.log(resp);
            this.form.images = this.form.images.filter(temp => {
              return temp.url !== item.url
            })
          })
          .catch(error => {
            console.error(error)
          })
    },
  },
}
</script>
