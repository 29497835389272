import {Base} from './Base'
import {Vehicle} from "./Vehicle";

export class Release extends Base {
    resourceName() {
        return 'releases'
    }

    fields() {
        return [
            'release_date',
            'recipient',
        ]
    }

    dates() {
        return {
            release_date: 'YYYY-MM-DD',
        }
    }

    relations() {
        return {
            inventory: (...arg) => new Vehicle(...arg),
        }
    }
}