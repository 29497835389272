import {Base} from './Base'
import {Booking} from "./Booking";

export class Destination extends Base {

  resourceName() {
    return 'destinations'
  }

  fields() {
    return [
      'name',
    ]
  }

  relations() {
    return {
      bookings: (...arg) => new Booking(...arg),
    }
  }

}