<template>
    <Modal v-model="visible"
           title="Add new Inventory on the way"
           ref="modal"
           :loading="true"
           width="800"
           :mask-closable="false"
           @on-ok="onOk"
           @on-cancel="onCancel">
        <Form ref="form"
              :model="form"
              :rules="form.rules"
              label-position="top"
              v-if="visible">
            <Row :gutter="24">
                <Col span="6">
                    <FormItemSelectBranch v-model="form.branch"
                                          ref="branch"/>
                </Col>

                <Col span="8">
                    <FormItemSelectCompany v-model="form.company"
                                           ref="company"/>
                </Col>
                <Col span="10">
                    <FormItemSelectCarrier v-model="form.carrier"
                                           ref="carrier"/>
                </Col>
            </Row>
            <Divider orientation="left" size="small">Inventory information</Divider>
            <Row :gutter="8">
                <Col span="6">
                    <FormItemSelectInventoryType v-model="form.inventory_type"
                                                 ref="inventory_type"/>
                </Col>
                <Col span="12">
                    <FormItem prop="vin"
                              label="VIN">
                        <Input size="large"
                               v-model="form.vin"
                               type="text"
                               placeholder="VIN code"/>
                    </FormItem>
                </Col>
                <Col span="6">
                    <FormItemSelectTitle v-model="form.title"
                                         ref="carrier"/>
                </Col>
            </Row>
            <Row :gutter="8">
                <Col span="8">
                    <FormItem prop="year"
                              label="Year">
                        <DatePicker
                                :value="form.year && form.year.isValid() ? form.year.toDate() : null"
                                type="year"
                                placeholder="Select year"
                                size="large"
                                format="yyyy"
                                @on-change="onChangeYear"/>
                    </FormItem>
                </Col>

                <Col span="8">
                    <FormItem prop="make"
                              label="Make">
                        <Input size="large"
                               v-model="form.make"
                               type="text"
                               placeholder="Make"/>
                    </FormItem>
                </Col>

                <Col span="8">
                    <FormItem prop="model"
                              label="Model">
                        <Input size="large"
                               v-model="form.model"
                               type="text"
                               placeholder="Model"/>
                    </FormItem>
                </Col>
            </Row>

            <Row :gutter="8">
                <Col span="4">
                    <FormItem prop="keys"
                              label="Keys Present">
                        <RadioGroup :value="form.keys ? 'true' : 'false'"
                                    @input="form.keys = ($event === 'true')"
                                    size="large">
                            <Radio label="true">Yes</Radio>
                            <Radio label="false">No</Radio>
                        </RadioGroup>
                    </FormItem>
                </Col>

                <Col span="4">
                    <FormItem prop="cod_amount"
                              label="COD">
                        <Input size="large"
                               v-model="form.cod_amount"
                               type="text"
                               prefix="logo-usd"
                               placeholder="COD"/>
                    </FormItem>
                </Col>
                <Col span="4">
                    <FormItemSelectCod v-model="form.cod"
                                       ref="cod"/>
                </Col>
                <Col span="4">
                    <FormItem prop="lot_number"
                              label="LOT#">
                        <Input size="large"
                               v-model="form.lot_number"
                               type="text"
                               placeholder="LOT#"/>
                    </FormItem>
                </Col>
                <Col span="4">
                    <FormItem prop="stock_number"
                              label="STOCK#">
                        <Input size="large"
                               v-model="form.stock_number"
                               type="text"
                               placeholder="STOCK#"/>
                    </FormItem>
                </Col>

            </Row>
        </Form>
    </Modal>

</template>

<script>

    import moment from 'moment'
    import {Vehicle} from '../../../../models/Vehicle'
    import FormItemSelectCompany from '../../../../components/FormItemSelectCompany'
    import FormItemSelectBranch from '../../../../components/FormItemSelectBranch'
    import FormItemSelectCarrier from '../../../../components/FormItemSelectCarrier'
    import FormItemSelectTitle from '../../../../components/FormItemSelectTitle'
    import FormItemSelectInventoryType from '../../../../components/FormItemSelectInventoryType'
    import FormItemSelectCod from '../../../../components/FormItemSelectCod'

    export default {
        props: {
            value: Boolean,
        },

        components: {
            FormItemSelectCompany,
            FormItemSelectBranch,
            FormItemSelectCarrier,
            FormItemSelectTitle,
            FormItemSelectInventoryType,
            FormItemSelectCod,
        },

        data() {
            return {
                form: {},
                visible: false,

            }
        },

        methods: {

            open() {
                this.form = new Vehicle();
                this.visible = true;
            },

            async onOk() {

                this.form
                    .save()
                    .then(resp => {
                        this.form = resp;
                        this.visible = false;
                        this.$emit('on-ok');
                        this.$Notice.success({
                            title: 'Saved success',
                        })
                    })
                    .catch(error => {
                        this.$refs['form'].validate();
                        this.$refs['modal'].buttonLoading = false;
                    })

            },

            async onCancel() {
                this.$emit('on-cancel')
            },

            onChangeYear(value) {
                this.form.year = moment(value, 'YYYY')
            },

        }


    }

</script>