<template>
    <Tabs value="all">
        <TabPane label="All"
                 name="all">
            <div class="filter-fields">
                <Select :value="branch || 'all'"
                        size="small"
                        style="width: 150px;"
                        @on-change="onChangeBranch">
                    <Option v-for="item in branches()"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <DatePicker :value="range"
                            format="MM/dd/yyyy"
                            type="daterange"
                            size="small"
                            placeholder="Select date"
                            style="width: 200px; font-size: 12px;"
                            @on-change="onChangeRange"/>
                <div style="float: right">
                    <Downloads name="Export"
                               v-if="$store.getters.hasAccess('booking', 'export')"
                               :url="`exports/bookings?filter[date_of_receiving]=${range_to_string()}`"
                               :disabled="range.filter(item => !!item).length === 0"
                               size="small"/>
                </div>

            </div>
            <Table :columns="columns"
                   :data="data"
                   :loading="loading"
                   @on-sort-change="onChangeSort"
                   @on-expand="onExpand"
                   border/>

            <Page :total="pagination.total_objects"
                  :current="pagination.page"
                  :page-size="pagination.per_page"
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                  show-total
                  n-show-sizer/>
        </TabPane>

        <template slot="extra">
            <Button type="primary"
                    icon="md-add"
                    @click="create"
                    v-if="$store.getters.hasAccess('booking', 'create')" >
                <template>New booking</template>
            </Button>
            <Input :value="search"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   @on-change="onChangeSearch"
                   search
            />
        </template>

        <Modified ref="modal"
                  @on-ok="onOk"/>
    </Tabs>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment'
    import Downloads from '../../../components/Downloads'
    import containers from './containers'
    import {Booking} from '../../../models/Booking'
    import Modified from '../modals/booking/modified'

    export default {
        components: {
            containers,
            Modified,
            Downloads,
        },

        data() {
            return {
                lastSearch: null,
                opened: [],
                loading: false,
                range: [
                    // moment().subtract(1, 'months').format('MM/DD/YYYY'),
                    // moment().format('MM/DD/YYYY')
                ],
                search: '',
                branch: undefined,
                columns: [
                    {
                        type: 'expand',
                        width: 50,
                        render: (h, params) => {
                            return h(containers, {
                                props: {
                                    data: params.row.containers.data
                                },
                                on: {
                                    'on-change': this.reload
                                },
                            })
                        }
                    },
                    {
                        title: 'Booking',
                        key: 'number',
                        width: 110,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h(params.row.booking_tracking_url ? 'a' : 'span', {
                                attrs: {
                                    target: '_blank',
                                    href: params.row.booking_tracking_url,
                                },
                            }, params.row.number)
                        },
                    },
                    {
                        title: 'Shipping Line',
                        key: 'shipping_line.name',
                        width: 130,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('div', params.row.shipping_line ? params.row.shipping_line.name : null)
                        },
                    },
                    {
                        title: 'Vessel name / Voyage',
                        key: 'vessel_name',
                        width: 200,
                        resizable: true,
                        ellipsis: true,
                        tooltip: true,
                        render: (h, params) => {
                            let elements = [params.row.vessel_name, params.row.voyage];
                            let result = elements.filter(Boolean).join(' / ');
                            return h('div', result)
                        },
                    },
                    {
                        title: 'Destination',
                        key: 'destination.name',
                        resizable: true,
                        ellipsis: true,
                        width: 130,
                        render: (h, params) => {
                            return h('div', params.row.destination ? params.row.destination.name : null)
                        }
                    },
                    {
                        title: 'Terminal Out',
                        key: 'out_terminal.name',
                        width: 130,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('div', params.row.out_terminal ? params.row.out_terminal.name : null)
                        },
                    },
                    {
                        title: 'Terminal In',
                        key: 'in_terminal.name',
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('div', params.row.in_terminal ? params.row.in_terminal.name : null)
                        },
                    },
                    {
                        title: 'Customer',
                        key: 'company.name',
                        width: 110,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('div', params.row.company ? params.row.company.name : null)
                        },
                    },
                    {
                        title: 'Early Gate',
                        key: 'early_gate',
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            let elements = [];
                            if (params.row.early_gate && params.row.early_gate.isValid()) {
                                elements.push(
                                    h('span', {
                                            style: {
                                                'padding-right': '10px',
                                            },
                                        },
                                        params.row.early_gate.format('MM/DD/YYYY')
                                    ),
                                );
                                elements.push(
                                    h('Badge', {
                                        props: {
                                            'dot': '',
                                        },
                                        class: {
                                            'badge-green': params.row.early_gate_confirmation,
                                        }
                                    })
                                )
                            }
                            return h('div', elements)
                        },
                    },
                    {
                        title: 'Cut Off Day',
                        key: 'cut_off_day',
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('div', params.row.cut_off_day && params.row.cut_off_day.isValid() ? params.row.cut_off_day.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Departure date',
                        key: 'departure_date',
                        width: 130,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('div', params.row.departure_date && params.row.departure_date.isValid() ? params.row.departure_date.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Arrival date',
                        key: 'arrival_date',
                        width: 130,
                        resizable: true,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('div', params.row.arrival_date && params.row.arrival_date.isValid() ? params.row.arrival_date.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Actions',
                        width: 140,
                        key: 'actions',
                        fixed: 'right',
                        render: (h, params) => h('div', {style: {textAlign: 'right'},}, [...this.columnActions(h, params)],),
                    }
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 30,
                    page: 1
                },
                sort: {},
                data: [],
                lastObject: null,
            }
        },

        created() {
            this.reload()
        },

        methods: {
            branches() {
                const items = [{id: 'all', name: 'All Branches'}];
                if (this.$store.getters.branches()) {
                    return [...items, ...this.$store.getters.branches().data]
                }
                return items
            },

            onChangeBranch(item) {
                this.branch = (item === 'all' ? undefined : item);
                this.reload()
            },

            columnActions(h, params) {
                let actions = [];

                if (this.show) {
                    actions.push(h('Button', {
                            props: {type: 'default', size: 'small'},
                            style: {marginRight: '5px'},
                            on: {click: () => this.show(params.index)}
                        }, 'View'),
                    )
                }

                if (this.$store.getters.hasAccess('booking', 'update')) {
                    actions.push(h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {click: () => this.edit(params.index)}
                    }, 'Edit'))
                }

                if (params.row.is_system === false && this.$store.getters.hasAccess('booking', 'delete')) {
                    actions.push(h('Poptip', {
                        props: {
                            confirm: true,
                            title: 'Are you sure you want to delete this item?',
                            placement: 'left',
                            transfer: true,
                        },
                        on: {
                            'on-ok': () => this.remove(params.index)
                        }
                    }, [h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                    }, 'Delete')]));
                }
                return actions
            },

            onExpand(row, status) {
                if (status) {
                    this.opened.push(row.id)
                } else {
                    this.opened = this.opened.filter(item => item !== row.id)
                }
            },

            onChangeSort({column, key, order}) {
                if (order === 'normal') {
                    this.sort = {}
                } else {
                    this.sort.key = key;
                    this.sort.order = order
                }
                this.reload()
            },

            onChangeRange(range) {
                this.range = range;
                this.reload()
            },

            onChangeSearch(event) {
                this.search = event.target.value;
                this.reload()
            },

            onOk() {
                this.reload()
            },

            onCancel() {
            },

            create() {
                this.$refs.modal.open()
            },

            edit(index) {
                this.$refs.modal.open(this.data[index].clone())
            },

            async remove(index) {
                await this.data[index].delete()
                    .then(() => this.reload())
                    .catch(err => {
                        this.$Notice.error({
                            title: 'Error',
                            desc: err.response.data.errors[0].title,
                            duration: 10
                        })
                    });
            },

            async reload() {
                this.loading = true;
                this.lastObject && this.lastObject.cancel('ignore');
                let booking = this.lastObject = new Booking()
                    .with(['containers.chassis',
                        'in_terminal',
                        'out_terminal',
                        'company',
                        'shipping_line',
                        'branch',
                        'destination']);
                if (this.sort && this.sort.key && this.sort.order) {
                    booking = booking[this.sort.order === 'asc' ? 'orderBy' : 'orderByDesc'](this.sort.key)
                }
                if (this.range && this.range.filter(item => !!item).length) {
                    booking = booking.where('cut_off_day', this.range.map(item => moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD')).join(','))
                }
                if (this.search) {
                    booking = booking.where('search', encodeURIComponent(this.search))
                }
                if (this.branch) {
                    booking = booking.where('branch', this.branch)
                }
                if (this.search !== this.lastSearch) {
                    this.lastSearch = this.search;
                    this.pagination.page = 1
                }
                const response = await booking
                    .paginate(this.pagination.per_page, this.pagination.page)
                    .catch(err => {
                        if (!err.toString().startsWith('Cancel')) {
                            console.warn(err);
                            this.loading = false;
                        }
                    });
                this.data = response.data || [];
                this.data.forEach((item, index) => this.data[index]._expanded = this.opened.includes(this.data[index].id));
                this.pagination.total_objects = response.meta.total_objects;
                this.loading = false
            },

            changePage(page) {
                this.pagination.page = page;
                this.reload()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                this.reload()
            },

            range_to_string() {
                return Vue.options.filters.to_string(this.range)
            },
        }
    }
</script>
