import {Base} from './Base'
import {Vehicle} from "./Vehicle"
import {Condition} from "./Condition"

export class VehicleCondition extends Base {

  resourceName() {
    return 'vehicle_conditions'
  }

  fields() {
    return [
      'position',
    ]
  }

  relations() {
    return {
      vehicle: (...arg) => new Vehicle(...arg),
      condition: (...arg) => new Condition(...arg),
    }
  }

}