<template>
    <Row>
        <Col span="4">
            <Menu :active-name="$route.meta.menu"
                  theme="light"
                  @on-select="menuSelect">
                <MenuItem name="appBranches" :append=true>
                    <span>Branches</span>
                </MenuItem>
                <MenuItem name="appChassis" :append=true>
                    <span>Chassis</span>
                </MenuItem>
                <MenuItem name="appCods" :append=true>
                    <span>CODs</span>
                </MenuItem>
                <MenuItem name="appColors" :append=true>
                    <span>Colors</span>
                </MenuItem>
                <MenuItem name="appConditions" :append="true">
                    <span>Conditions</span>
                </MenuItem>
                <MenuItem name="appDestinations" :append="true">
                    <span>Destinations</span>
                </MenuItem>
                <MenuItem name="appInventoryTypes" :append="true">
                    <span>Inventory types</span>
                </MenuItem>

                <MenuItem name="appLiens" :append="true">
                    <span>Liens</span>
                </MenuItem>
                <MenuItem name="appOptions" :append="true">
                    <span>Options</span>
                </MenuItem>
                <MenuItem name="appShippingLines" :append="true">
                    <span>Shipping Lines</span>
                </MenuItem>

                <MenuItem name="appTerminals" :append="true">
                    <span>Terminals</span>
                </MenuItem>
                <MenuItem name="appTitles" :append="true">
                    <span>Titles</span>
                </MenuItem>
            </Menu>
        </Col>
        <Col span="20">
            <router-view/>
        </Col>
    </Row>
</template>

<script>
    export default {
        data() {
            return {}
        },

        methods: {
            menuSelect(name) {
                this.$router.push({name: name})
            },
        }
    }
</script>

<style scoped>

</style>
