<template>
    <div id="app">
        <vue-confirm-dialog></vue-confirm-dialog>
        <router-view></router-view>
    </div>
</template>


<script>
    export default {}
</script>
