<template>
    <div>
        <div v-if="attachmentList.length > 0">
            <div style="display: inline-block; width: 100%;"
                 v-for="item in attachmentList"
                 :key="item.id">
                <Alert show-icon banner type="warning" >
                    <Icon type="md-copy" slot="icon"/>
                    <template>
                        {{item.file_name}}&nbsp;

                            <a :href="item.url"
                               target="_blank">
                                <template>Download</template>
                            </a>
                    </template>

                </Alert>
            </div>
        </div>
        <h1 v-else style="text-align: center; color: gray;">No attachments</h1>
    </div>
</template>

<script>

    export default {
        props: {
            form: {
                type: Object,
            },
            attachmentList: {
                type: Array,
                default() {
                    return [];
                }
            },
        },

        data() {
            return {}
        },


    }
</script>
