import _ from 'lodash'
import {Model} from 'sarala'
import axios from 'axios'
import moment from 'moment'
import {Formatter} from 'sarala-json-api-data-formatter'

class FixFormatter extends Formatter {
  serializeRelationship(data) {
    if (!data || data.type === 'empty') return null;
    return super.serializeRelationship(data)
  }

  resolveRelation(data) {
    return _.find(this.data.included, data) ? super.resolveRelation(data) : null
  }
}

export class Base extends Model {
  // configuration
  baseUrl() {
    return '/api'
  }

  resourceImages() {
    return `${this.resourceUrl()}${this.id}/images/`
  }

  resourceSignature() {
    return `${this.resourceUrl()}${this.id}/signatures/`
  }

  resourceAttachments() {
    return `${this.resourceUrl()}${this.id}/attachments/`
  }

  // override
  relations() {
    return {}
  }

  relationships() {
    let object = {};
    _.forEach(this.relations(), (model, field) => {
      object[field] = model()
    });
    return object
  }

  cancel = () => {};

  async request(config) {
    try {
      if (!config.url.startsWith("/")) {
        config.url = config.url.replace("http:", location.protocol)
      }
      config.cancelToken = new axios.CancelToken((cancel) => {
        this.cancel = cancel;
      });
      return await axios.request(config)
    } catch (err) {
      // check attribute errors
      this.errors = this.errors || {};
      // clear all errors
      Object.keys(this.errors).forEach(field => this.errors[field] = null);
      // fill errors from response
      err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.forEach(item => {
        if (item.source && item.source.pointer) {
          this.errors[item.source.pointer.split('/').pop()] = item.title
        }
      });
      throw err
    }
  }

  async attach(model) {
    let parent = Object.getPrototypeOf(Object.getPrototypeOf(model)).resourceName();

    let response = await this.request({
      url: `${this.links.self}/relationships/${parent}`,
      method: 'POST',
      data: {data: [{type: parent, id: model.id}]},
    });

    return this.respond(response.data)
  }

  async detach(model) {
    let parent = Object.getPrototypeOf(Object.getPrototypeOf(model)).resourceName();

    let response = await this.request({
      url: `${this.links.self}/relationships/${parent}`,
      method: 'DELETE',
      data: {data: [{type: parent, id: model.id}]},
    });

    return this.respond(response.data);
  }

  // -------------------------------------------------------------------------------------------------------------------

  // !!!
  fix(object) {
    object.rules = {};
    object.errors = {};

    _.forEach(object.fields(), field => {
      object.rules[field] = [{
        validator: (rule, value, callback) => {
          if (object.errors[rule.field]) {
            callback(object.errors[rule.field])
          } else {
            callback()
          }
        }
      }];
      object.errors[field] = null
    });

    _.forEach(object.relations(), (value, field) => {
      object.rules[field] = [{
        validator: (rule, value, callback) => {
          if (object.errors[rule.field]) {
            callback(object.errors[rule.field])
          } else {
            callback()
          }
        }
      }];
      object.errors[field] = null
    });

    _.forEach(object.dates(), (value, field) => {
      object.rules[field] = [{
        validator: (rule, value, callback) => {
          if (object.errors[rule.field]) {
            callback(object.errors[rule.field])
          } else {
            callback()
          }
        }
      }];
      object.errors[field] = null
    });

    return object
  }

  // -------------------------------------------------------------------------------------------------------------------

  // fix constructor
  constructor(data = null) {
    super();
    this.fix(this);

    if (data) {
      for (let property in data) {
        if (data.hasOwnProperty(property)) {
          // simple fields
          if (this.fields().includes(property) || property === 'id') {
            this[property] = data[property]
          } else

          // dates fields
          if (property in this.dates()) {
            this[property] = moment(data[property], this.dates()[property])
          } else

          // relationShips
          if (property in this.relations()) {
            this[property] = this.relations()[property](data[property].data)
          }
        }
      }

      _.forOwn(this.computed(), (computation, key) => {
        this[key] = computation(this)
      });

      this.fix(this)
    }
  }


  // requests
  async delete() {
    let response = await this.request({
      url: this.links.self,
      method: 'DELETE'
    });

    return this.respond(response.data);
  }


  // fix build model
  hydrate(data) {
    _.forOwn(this.dates(), (format, field) => {
      if (data[field] && typeof data[field] === 'string') {
        let temp = String(data[field]);
        temp = temp.replace('Z', '');
        if (temp.length === 4) {
          temp = temp + '-01-01T00:00:00.000'
        }
        if (temp === '0') {
          temp = null
        }
        data[field] = temp
      }
    });

    return this.fix(super.hydrate(data))
  }


  // fix extract data from model
  data() {
    this.relationshipNames = [];

    _.forEach(this.relationships(), (model, relationship) => {
      if (this.hasOwnProperty(relationship)) {
        this[relationship] = this[relationship] || new Empty();
        if (this[relationship] || false) {
          if (!this.relationshipNames.includes(relationship)) {
            this.relationshipNames.push(relationship)
          }
        }
      }
    });

    return super.data()
  }


  // fix helpers

  deserialize(data) {
    try {
      return new FixFormatter().deserialize(data)
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  serialize(data) {
    try {
      return new FixFormatter().serialize(data)
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  clone() {
    return this.fix(super.clone())
  }
}

class Empty extends Base {
  resourceName() {
    return 'empty'
  }
}
