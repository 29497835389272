<template>
    <div>
        <div v-if="form.attachments && form.attachments.data && form.attachments.data.length > 0">
            <div style="display: inline-block; width: 100%;"
                 v-for="item in getAttachments()"
                 :key="item.id">
                <Alert show-icon banner type="warning" closable @on-close="handleClose(item)">
                    <Icon type="md-copy" slot="icon"/>
                    <template>
                        <Icon type="ios-eye-off" v-if="item.access === 'secret'" />
                        {{item.file_name}}&nbsp;
                        <a :href="item.url"
                           target="_blank">
                            <template>Download</template>
                        </a>
                    </template>

                </Alert>
            </div>
        </div>
        <h1 v-else style="text-align: center; color: gray;">No attachments</h1>
    </div>

</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      form: {
        type: Object,
      },
    },

    data() {
      return {}
    },

    methods: {
      getAttachments() {
        return this.form.attachments.data
      },

      async handleClose(item) {
        await axios.request({url: this.form.resourceAttachments() + item.id, method: 'delete',});
        this.$emit('reload')
      },
    },
  }
</script>
