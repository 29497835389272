<template>
    <Card :bordered="false"
          dis-hover
          title="Inventory Details">
        <template slot="extra">
            <Downloads name="Download Images"
                       :url="`inventories/${form.id}/zip`"
                       v-if="images.length > 0"/>
            <Downloads name="Download CR"
                       :url="`condition_reports/${form.id}`"
                       v-if="!form.is_deleted"/>

            <Dropdown  @on-click="action" v-if="is_in_yard">
                <Button type="primary">
                    Actions
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                <DropdownMenu slot="list">
                    <DropdownItem name="shipped" v-if="is_to_shopped">To shipped</DropdownItem>
                    <DropdownItem name="received" v-if="is_receaved">To received</DropdownItem>
                    <DropdownItem name="released" v-if="is_released"> To released</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Button @click="cancel">
                <template>Cancel</template>
            </Button>
            <Button type="primary"
                    v-if="$store.getters.hasAccess('inventory', 'update') && !form.is_deleted"
                    @click="edit">
                <template>Edit</template>
            </Button>


        </template>
        <Row :gutter="8">
            <Col :xs="24" :md="24" :lg="12" :xl="12">
                <Card class="info-card">
                    <p slot="title">
                        Vehicle Images
                    </p>
                    <carousel :starting-image="2"
                               :images="images">
                    </carousel>
                </Card>
                <Card class="info-card" v-if="form.id">
                    <p slot="title">Activity</p>
                    <Versions :form="form"
                              resource="inventories"/>

                </Card>
            </Col>
            <Col :xs="24" :md="24" :lg="12" :xl="12">
                <Card class="info-card">
                    <p slot="title">
                        Vehicle Info
                    </p>
                    <table>
                        <tr>
                            <td>Date of Received</td>
                            <td>{{form.date_of_receiving | to_humanize_date}}</td>
                        </tr>
                        <tr>
                            <td>HAT#</td>
                            <td>{{form.hat_number}}</td>
                        </tr>
                        <tr>
                            <td>VIN</td>
                            <td>{{form.vin}}</td>
                        </tr>
                        <tr>
                            <td>Year, Make and Model</td>
                            <td>{{form.year | to_humanize_year}} {{form.make}} {{form.model}}</td>
                        </tr>
                        <tr>
                            <td>Color</td>
                            <td>{{(form.color || {}).name}}</td>
                        </tr>
                        <tr>
                            <td>Value</td>
                            <td>{{form.cost_humanize}}</td>
                        </tr>
                        <tr>
                            <td>Keys</td>
                            <td>{{form.key_humanize }}</td>
                        </tr>
                        <tr>
                            <td>Title</td>
                            <td>{{form.title && form.title.name}} / {{form.lien && form.lien.name}}</td>
                        </tr>
                    </table>
                </Card>
                <Card class="info-card">
                    <p slot="title">
                        Other Info
                    </p>

                    <table>
                        <tr>
                            <td>Lot#</td>
                            <td>{{form.lot_number}}</td>
                        </tr>
                        <tr>
                            <td>Stock#</td>
                            <td>{{form.stock_number}}</td>
                        </tr>
                        <tr>
                            <td>Carrier</td>
                            <td>{{form.carrier ? form.carrier.name : null}}</td>
                        </tr>
                        <tr>
                            <td>Tow amount</td>
                            <td>${{form.towed_amount}}</td>
                        </tr>
                        <tr>
                            <td>Storage amount</td>
                            <td>${{form.storage_amount}}</td>
                        </tr>
                        <tr>
                            <td>COD</td>
                            <td>{{form.cod_humanize}} {{form.cod_status_humanize}}</td>
                        </tr>
                        <tr>
                            <td>Storage days</td>
                            <td>{{form.days_of_storage}}</td>
                        </tr>
                        <tr>
                            <td>Damaged</td>
                            <td>{{form.damaged | to_humanize_boolean}}</td>
                        </tr>
                        <tr>
                            <td>Towed</td>
                            <td>{{form.towed | to_humanize_boolean}}</td>
                        </tr>
                        <tr>
                            <td>Operable</td>
                            <td>{{form.operable | to_humanize_boolean}}</td>
                        </tr>
                    </table>
                </Card>
                <Card class="info-card">
                    <p slot="title">
                        Booking and Customer Info
                    </p>

                    <table>
                        <tr>
                            <td>Customer</td>
                            <td>{{ form.company && form.company.name }}</td>
                        </tr>
                        <tr>
                            <td>Booking</td>
                            <td>{{ form.booking_number }}</td>
                        </tr>
                        <tr>
                            <td>Container</td>
                            <td><a :href="form.container_id ? '/app/containers/' + form.container_id : '#'">
                                {{ form.container_number }}
                            </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Shipping Line</td>
                            <td>
                                {{ form.shipping_line }}
                            </td>
                        </tr>
                        <tr>
                            <td>Vessel name</td>
                            <td>
                                {{ form.vessel_name }}
                            </td>
                        </tr>

                        <tr>
                            <td>Departure date</td>
                            <td>
                                {{ form.departure_date && form.departure_date.isValid() ? form.departure_date.format('MM/DD/YYYY') : null }}
                            </td>
                        </tr>

                        <tr>
                            <td>Arrival date</td>
                            <td>
                                {{ form.arrival_date && form.arrival_date.isValid() ? form.arrival_date.format('MM/DD/YYYY') : null }}
                            </td>
                        </tr>

                        <tr>
                            <td>Status</td>
                            <td>
                                {{ form.state }}
                            </td>
                        </tr>
                    </table>
                </Card>
                <Card class="info-card">
                    <p slot="title">
                        Notes
                    </p>
                    {{form.note}}
                </Card>
                <Card class="info-card">
                    <p slot="title">
                        Attachments
                    </p>
                    <Row :gutter="8">
                        <Attachments :form="form" @reload="reloadItem"/>

                    </Row>
                </Card>
                <Card class="info-card" v-if="form.release">
                    <p slot="title">
                        Release Info
                    </p>

                    <table>
                        <tr>
                            <td>Release date</td>
                            <td>{{ form.release.release_date | to_humanize_date }}</td>
                        </tr>
                        <tr>
                            <td>Recepient</td>
                            <td>{{ form.release.recipient }}</td>
                        </tr>
                    </table>
                </Card>
            </Col>
        </Row>
        <Spin fix v-if="loading">
            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
        <ModifiedRelease ref="modalRelease"
                     @on-ok="reload"/>
    </Card>
</template>

<script>
    import Attachments from '../../../components/AttachmentsShow'
    import {Option} from '../../../models/Option'
    import Downloads from '../../../components/Downloads'
    import {Vehicle} from '../../../models/Vehicle'
    import Versions from '../../../components/Versions'
    import Carousel from '../../../components/Carousel'
    import ModifiedRelease from '../modals/release/modified'


    export default {
        components: {
            Downloads,
            Attachments,
            Versions,
            Carousel,
            ModifiedRelease
        },

        data() {
            return {
                tab: 'activity',
                form: {},
                imageUrl: '',
                visible: false,
                loading: true,
                images: []
            }
        },

        mounted() {
            this.reload();
        },
        computed:{
            is_in_yard(){
                return this.form.state === 'in_yard'
            },
            is_to_shopped(){
                return this.$store.getters.hasAccess('admin', 'admin') && this.form.state === 'in_yard' && !this.form.is_deleted
            },
            is_receaved(){
                return this.$store.getters.hasAccess('inventory', 'update') && this.form.state === 'in_yard' && !this.form.is_deleted
            },
            is_released(){
                return  this.$store.getters.hasAccess('inventory', 'update') && this.form.state === 'in_yard' && !this.form.is_deleted
            },
        },

        methods: {
            handleView(url) {
                this.imageUrl = url;
                this.visible = true
            },

            reloadItem() {
                this.reload()
            },

            optionState(id) {
                return (this.form.vehicle_options || {data: []}).data.filter(item => item.option.id === id).length > 0
            },

            edit() {
                this.$router.push({name: "appInventoriesEdit", params: {id: this.form.id}})
            },

            cancel() {
                this.$router.go(-1)
            },

            async action(name) {
                console.log("1111111", name)
                if (name === 'received') {
                    await this.form.canceled();
                    this.reload();
                } else if (name === 'released') {
                    this.$refs.modalRelease.open({
                        inventory: this.form
                    })
                } else if (name === 'shipped') {
                    await this.form.shipped();
                    this.$Notice.success({
                    title: 'Notification title',
                    desc: 'Inventory changed status to shipped. '
                    })
                    this.reload();
                }
            },

            async reload() {
                try {
                    this.form = {};
                    this.loading = true;
                    if (this.$route && this.$route.params && this.$route.params.id) {
                        const response = await new Vehicle()
                            .with([
                                'color',
                                'title',
                                'lien',
                                'cod',
                                'attachments',
                                'company',
                                'vehicle_options.option',
                                'release',
                                'carrier'
                            ])
                            .find(this.$route.params.id);
                        this.form = response || {};
                        await this.form.pictures().then(resp => this.images = resp || [] ).catch(console.log);
                        this.images = this.images.concat(this.form.images);
                    }
                    this.loading = false;
                }catch {
                    this.loading = false;
                }
            }
        },

        watch: {
            '$route': 'reload',
        },
    }
</script>
