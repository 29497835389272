import {Base} from './Base'
import {VehicleModel} from "./VehicleModel"

export class VehicleMake extends Base {

  resourceName() {
    return 'vehicle_makes'
  }

  fields() {
    return [
      'name',
    ]
  }

  relations() {
    return {
      vehicle_models: (...arg) => new VehicleModel(...arg),
    }
  }

}