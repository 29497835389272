import {Base} from './Base'
import {Carrier} from "./Carrier"

export class Driver extends Base {

  resourceName() {
    return 'drivers'
  }

  fields() {
    return [
      'first_name',
      'last_name',
      'is_deleted',
    ]
  }

  relations() {
    return {
      carrier: (...arg) => new Carrier(...arg),
    }
  }

  computed() {
    return {
      fullName(driver) {
        return [driver.first_name, driver.last_name].filter((item) => item).join(' ')
      }
    }
  }
}