<template>
    <Card :bordered="false"
          :dis-hover="true"
          :title=" (form.id ? 'Edit' : 'Create') + ' Condition Report'">
        <template slot="extra"
                  style="top: 7px">
            <Button @click="onCancel"
                    size="large"
                    v-if="step === 1">
                <template>Cancel</template>
            </Button>

            <Button @click="onPrev"
                    size="large"
                    v-if="step !== 1">
                <template>Prev</template>
            </Button>

            <Button type="primary"
                    @click="onNext"
                    size="large"
                    v-if="step !== last">
                <template>Next</template>
            </Button>

            <Button type="primary"
                    @click="onSave"
                    size="large"
                    :loading="buttonLoading"
                    v-if="step === last">
                <template>Save</template>
            </Button>
        </template>

        <Form ref="form"
              @on-form-item-add="onItemAdd"
              @on-form-item-remove="onItemDel"
              :model="form"
              :rules="form.rules"
              :label-position="(step > 2 && step < last) ? 'right' : 'top'"
              :label-width="(step > 2 && step < last) ? 30 : 0">
            <step1 :form="form"
                   v-if="step === 1"/>

            <step2 :form="form"
                   v-if="step === 2"/>

            <step3 :form="form"
                   :shift="step - 3"
                   v-if="step > 2 && step < last - 1"/>

            <step4 :form="form"
                   :listOfNewFiles="listOfNewFiles"
                   v-if="step === last - 1"
                   @addFile="addFile"
                   @delFile="delFile"/>

            <step5 :form="form"
                   v-model="signature"
                   v-if="step === last"/>
        </Form>
    </Card>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import {Vehicle} from '../../../models/Vehicle'
  import step1 from './step1'
  import step2 from './step2'
  import step3 from './step3'
  import step4 from './step4'
  import step5 from './step5'
  import uuid from '../../../utils/uuid'

  export default {
    components: {
      step1,
      step2,
      step3,
      step4,
      step5,
    },

    data() {
      return {
        last: 8,
        form: {},
        signature: null,
        buttonLoading: false,
        listOfNewFiles: [],
        uploadPercent: 0,
      }
    },

    methods: {
      async onNext() {
        if (this.$refs.form.fields.length === 0 || await this.$refs.form.validate()) {
          this.$router.push({
            name: 'appConditionReportWizard',
            params: {step: this.step + 1},
          })
        } else {
          this.$Notice.warning({
            title: 'Warning',
            desc: 'Please, filled all required field',
          })
        }
      },

      onPrev() {
        this.$router.push({name: 'appConditionReportWizard', params: {step: this.step - 1}})
      },

      onSave() {
        if (this.signature) {
          this.onOk()
        } else {
          this.$Notice.warning({
            title: 'Warning',
            desc: 'Please, sign the condition report!',
          })
        }
      },

      onOk() {
        this.buttonLoading = true;
        this.saveItem()
      },

      saveItem() {
        this.form
          .save()
          .then(resp => {
            this.form = resp;
            this.addFiles()
          })
          .catch(error => {
            console.error(error);
            this.$refs['form'].validate();
            this.buttonLoading = false;
            this.$Notice.error({
              title: 'Save inventory failed',
              desc: `The remote server returned an error: (${error.response.status}) ${error.response.statusText}`,
              duration: 10,
            })
          })
      },

      addFile(file) {
        this.listOfNewFiles.push({uuid: uuid(), file: file, link: URL.createObjectURL(file)})
      },

      delFile(file) {
        this.listOfNewFiles = this.listOfNewFiles.filter(temp => temp.uuid !== file.uuid)
      },

      addFiles() {
        let data = new FormData();
        this.listOfNewFiles.forEach(item => {
          data.append('images[]', item.file);
        });

        axios
          .request({
            url: this.form.resourceImages(),
            data: data,
            method: 'post',
            onUploadProgress: (progressEvent) => {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          })
          .then(resp => {
            this.listOfNewFiles.splice(0, this.listOfNewFiles.length);
            this.uploadPercent = 0;
            this.addSignature()
          })
          .catch(error => {
            console.error(error);
            this.buttonLoading = false;
            this.$Notice.error({
              title: 'Save images failed',
              desc: (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 && error.response.data.errors[0].detail) || `The remote server returned an error: (${error.response.status}) ${error.response.statusText}`,
              duration: 10,
            })
          })
      },

      addSignature() {
        let data = new FormData();
        data.append('images', this.signature);

        axios
          .request({
            url: this.form.resourceSignature(),
            data: data,
            method: 'post',
            onUploadProgress: (progressEvent) => {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          })
          .then(resp => {
            this.buttonLoading = false;
            this.uploadPercent = 0;
            localStorage.removeItem('condition_report_wizard');
            this.reload();
            this.$emit('on-ok');
            this.$router.replace({name: 'appConditionReport'});
            this.$Notice.success({
              title: 'Saved success',
            });
          })
          .catch(error => {
            console.error(error);
            this.buttonLoading = false;
            this.$Notice.error({
              title: 'Save signature failed',
              desc: `The remote server returned an error: (${error.response.status}) ${error.response.statusText}`,
              duration: 10,
            })
          })
      },


      onCancel() {
        this.$router.push({name: 'appInventories'})
      },

      saveObject() {
        let temp = this.form.clone();
        delete temp.company;
        localStorage.setItem('condition_report_wizard', JSON.stringify(temp.data()))
      },

      onItemDel(element) {
        element.$off('on-form-change', this.saveObject)
      },

      onItemAdd(element) {
        element.$on('on-form-change', this.saveObject)
      },

      reload() {
        this.form = new Vehicle();
        this.form.date_of_receiving = moment();
        if (localStorage.getItem('condition_report_wizard')) {
          try {
            this.form = new Vehicle(JSON.parse(localStorage.getItem('condition_report_wizard')))
          } catch (e) {
            console.error(e);
            localStorage.removeItem('condition_report_wizard')
          }
        }
        const vinValidator = async (rule, value, callback) => {
          const result = await this.form.checkVin()
            .catch(err => callback(new Error(err)));
          if (result) {
            callback()
          } else {
            callback(new Error('VIN already exists'))
          }
        };
        // const companyValidate = (rule, value, callback) => {
        //   if (value && (value.id !== value._uuid)) {
        //     callback()
        //   } else {
        //     if (this.$store.getters.hasAccess('company', 'create') && value && (value.id === value._uuid)) {
        //       callback()
        //     } else {
        //       callback(new Error('please, choose a company'))
        //     }
        //   }
        // };

        this.form.rules.vin && this.form.rules.vin.push({required: true});
        this.form.rules.vin && this.form.rules.vin.push({validator: vinValidator, trigger: 'blur'});
        // this.form.rules.company && this.form.rules.company.push({validator: companyValidate});

        this.form.rules.title_present && this.form.rules.title_present.push({required: true});
        this.form.rules.damaged && this.form.rules.damaged.push({required: true});
        this.form.rules.towed && this.form.rules.towed.push({required: true});
        this.form.rules.operable && this.form.rules.operable.push({required: true});
      },
    },

    computed: {
      step() {
        return parseInt(this.$route.params.step)
      },
    },

    created() {
      this.reload();
    },
  }
</script>