<template>
    <Tabs value="all">
        <TabPane label="All"
                 name="all">
            <Table :columns="columns"
                   :data="data"
                   :loading="loading"/>

            <Page :total="pagination.total_objects"
                  :current="pagination.page"
                  :page-size="pagination.per_page"
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                  show-total
                  n-show-sizer/>
        </TabPane>

        <template slot="extra">
            <template>&nbsp;</template>

            <Button type="primary"
                    icon="md-add"
                    @click="create"
                    v-if="$store.getters.hasAccess('dictionary', 'create')">
                <template>New branch</template>
            </Button>
        </template>

        <Modal v-model="modalBranch"
               :title="formBranchTitle"
               @on-ok="onOk"
               @on-cancel="onCancel"
               :loading="true"
               ref="modalBranch">
            <Form ref="formBranch"
                  :model="formBranch"
                  :rules="formBranch.rules"
                  label-position="right"
                  :label-width="100">
                <FormItem prop="name"
                          label="Name">
                    <Input size="large"
                           v-model="formBranch.name"
                           :autofocus="true"
                           type="text"
                           placeholder="Name"/>
                </FormItem>

                <FormItem prop="address1"
                          label="Address 1">
                    <Input size="large"
                           v-model="formBranch.address1"
                           type="text"
                           placeholder="Address 1"/>
                </FormItem>

                <FormItem prop="address2"
                          label="Address 2">
                    <Input size="large"
                           v-model="formBranch.address2"
                           type="text"
                           placeholder="Address 2"/>
                </FormItem>

                <FormItem prop="city"
                          label="City">
                    <Input size="large"
                           v-model="formBranch.city"
                           type="text"
                           placeholder="City"/>
                </FormItem>

                <FormItem prop="state"
                          label="State">
                    <Select v-model="formBranch.state"
                            filterable
                            size="large">
                        <Option v-for="item in $store.getters.stateList"
                                :value="item"
                                :key="item">
                            <template>{{item}}</template>
                        </Option>
                    </Select>
                </FormItem>

                <FormItem prop="phone"
                          label="Phone">
                    <Input size="large"
                           v-model="formBranch.phone"
                           type="text"
                           placeholder="Phone"/>
                </FormItem>

                <FormItem prop="zip"
                          label="ZIP">
                    <Input size="large"
                           v-model="formBranch.zip"
                           type="text"
                           placeholder="ZIP"/>
                </FormItem>
            </Form>
        </Modal>
    </Tabs>
</template>

<script>
  import {Branch} from '../../../../models/Branch.js'

  export default {
    data() {
      return {
        modalBranch: false,
        formBranch: {},
        loading: false,
        columns: [
          {title: 'Name', key: 'name'},
          {
            title: 'Address',
            key: 'address',
            render: (h, params) => {
              return h('div', new Branch().fullAddress(params.row));
            }
          },
          {title: 'Phone', key: 'phone'},
          {
            title: 'Actions',
            width: 190,
            key: 'actions',
            render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
          }
        ],
        pagination: {
          total_objects: 0,
          per_page: 50,
          page: 1
        },
        data: []
      }
    },

    computed: {
      formBranchTitle() {
        return this.formBranch.id ? 'Edit Branch' : 'Create Branch'
      }
    },

    created() {
      this.reload()
    },

    methods: {
      columnActions(h, params) {
        let actions = [];
        if (this.show) {
          actions.push(h('Button', {
              props: {type: 'default', size: 'small'},
              style: {marginRight: '5px'},
              on: {
                click: () => this.show(params.index)
              }
            }, 'View'),
          )
        }
        if (this.$store.getters.hasAccess('dictionary', 'update')) {
          actions.push(h('Button', {
            props: {type: 'primary', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => this.edit(params.index)
            }
          }, 'Edit'))
        }
        if (this.$store.getters.hasAccess('dictionary', 'delete')) {
          actions.push(h('Poptip', {
            props: {
              confirm: true,
              title: 'Are you sure you want to delete this item?',
              placement: 'left',
              transfer: true,
            },
            on: {
              'on-ok': () => this.remove(params.index)
            }
          }, [h('Button', {
            props: {
              type: 'error',
              size: 'small'
            },
            style: {
              marginRight: '5px'
            },
          }, 'Delete')]));
        }
        return actions
      },

      onOk() {
        console.log('ok', arguments);
        this.formBranch
          .save()
          .then(resp => {
            this.modalBranch = false;
            this.reload()
          })
          .catch(err => {
            this.$refs['formBranch'].validate();
            this.$refs['modalBranch'].buttonLoading = false
          })
      },

      onCancel() {
        console.log('cancel', arguments)
      },

      show(index) {
        this.$router.push({name: 'appBranchesEmployees', params: {id: this.data[index].id}})
      },

      create() {
        this.formBranch = new Branch();
        this.modalBranch = true;
      },

      edit(index) {
        this.formBranch = this.data[index].clone();
        this.modalBranch = true;
      },

      remove(index) {
        this.data[index]
          .delete()
          .then(resp => {
            this.reload()
          })
          .catch(err => {
            this.reload()
          })
      },

      reload() {
        this.loading = true;
        new Branch().paginate(this.pagination.per_page, this.pagination.page)
          .then(resp => {
            this.data = resp.data || [];
            this.pagination.total_objects = resp.meta.total_objects;
            this.loading = false
          })
          .catch(err => {
            console.log(err);
            this.loading = false
          });
      },

      changePage(page) {
        this.pagination.page = page;
        this.reload()
      },

      changePageSize(per_page) {
        this.pagination.per_page = per_page;
        this.reload()
      },
    }
  }
</script>
