<template>
    <div>
        <Table :columns="columns"
               :data="data"
               :loading="loading"
               border
               v-column-resizable="storage_name"
        />

        <Page :total="pagination.total_objects"
              :current="pagination.page"
              :page-size="pagination.per_page"
              @on-change="changePage"
              @on-page-size-change="changePageSize"
              show-total
              n-show-sizer/>
    </div>
</template>

<script>
    import moment from 'moment'
    import {Vehicle} from "../../../models/Vehicle"

    export default {
        props: {
            search: {
                type: String,
            },
            range: {
                type: Array,
            }
        },

        data() {
            return {
                lastSearch: null,
                loading: true,
                columns: [
                    {
                        title: 'Image',
                        key: 'image',
                        width: 100,
                        resizable: true,
                        ellipsis: true,
                        className: 'image-column',
                        render: (h, params) => {
                            let elements = [];
                            if (params.row.thumb_preview != null) {
                                elements.push(h('img', {
                                    style: {'width': '100px', 'height': '75px'},
                                    attrs: {
                                        src: params.row.thumb_preview,
                                    },
                                }));
                                return h('a', {attrs: {href: '/customer/inventories/' + params.row.id}}, elements)
                            } else {
                                elements.push(h('img', {
                                    style: {'width': '100px', 'height': '75px', 'padding-top': '10px', 'padding-bottom': '7px'},
                                    attrs: { src: "/assets/images/no-photo-1.svg"}
                                }));
                                return h('a', {attrs: {href: '/customer/inventories/' + params.row.id }}, elements)
                            }
                        },
                    },

                    {
                        title: 'Date',
                        minWidth: 90,
                        width: 90,
                        resizable: true,
                        ellipsis: true,
                        key: 'date_of_receiving',
                        render: (h, params) => {
                            return h('span', params.row.date_of_receiving && params.row.date_of_receiving.isValid() ? params.row.date_of_receiving.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Year/Make/Model',
                        minWidth: 45,
                        width: 200,
                        resizable: true,
                        ellipsis: true,
                        key: 'year',
                        render: (h, params) => {
                            let elements = [];
                            elements.push(h('span', params.row.year && params.row.year.isValid() ? params.row.year.format('YYYY') : null));
                            elements.push(h('span', {style: 'padding-left: 3px'}, params.row.make));
                            elements.push(h('span', {style: 'padding-left: 3px'}, params.row.model));

                            return h('span', elements)
                        },
                    },
                    {
                        title: 'VIN',
                        minWidth: 60,
                        width: 230,
                        resizable: true,
                        ellipsis: true,
                        key: 'vin',
                        render: (h, params) => {
                            let elements = [];
                            elements.push(
                                h('Badge', {
                                    props: {
                                        'dot': '',
                                    },
                                    class: {
                                        'badge-green': params.row.is_images,
                                    }
                                })
                            );
                            elements.push(
                                h('span', {
                                        style: {
                                            'padding-left': '10px',
                                        },
                                    },
                                    params.row.vin
                                ),
                            );
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'Color',
                        minWidth: 50,
                        width: 80,
                        resizable: true,
                        ellipsis: true,
                        key: 'color',
                        render: (h, params) => {
                            return h('div', params.row && params.row.color ? params.row.color.name : '')
                        },
                    },
                    {
                        title: 'Keys',
                        minWidth: 110,
                        key: 'keys',
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('span', params.row.key_humanize )
                        },
                    },
                    {
                        title: 'Title',
                        minWidth: 50,
                        width: 100,
                        resizable: true,
                        ellipsis: true,
                        key: 'title',
                        render: (h, params) => {
                            return h('span', params.row.title ? params.row.title.name : null)
                        },
                    },
                    {
                        title: 'Branch',
                        key: 'branch_name',
                        minWidth: 50,
                        resizable: true,
                        ellipsis: true,
                    },
                    {
                        title: 'Container',
                        key: 'container',
                        minWidth: 100,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h(params.row.container_tracking_url ? 'a' : 'span', {
                                attrs: {
                                    target: '_blank',
                                    href: params.row.container_tracking_url,
                                },
                            }, params.row.container_number)
                        },
                    },
                    {
                        title: 'Booking',
                        key: 'booking',
                        minWidth: 220,
                        width: 220,
                        render: (h, params) => {
                            return h((params.row && params.row.booking_tracking_url) ? 'a' : 'span', {
                                attrs: {
                                    target: '_blank',
                                    href: params.row ? params.row.booking_tracking_url : '',
                                },
                            }, (params.row && params.row.booking_number) ? params.row.booking_number : '')
                        },
                    },
                    {
                        title: 'Actions',
                        width: 80,
                        key: 'actions',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {type: 'default', size: 'small'},
                                    style: {marginRight: '5px'},
                                    on: {
                                        click: () => this.show(params.index)
                                    }
                                }, 'View'),
                            ])
                        }
                    }
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 20,
                    page: 1,
                },
                data: [],
                lastObject: null,
            }
        },

        methods: {
            type() {
                return this.$route.path.split('/').pop()
            },

            show(index) {
                this.$emit('on-action', this.type(), 'show', this.data[index].clone());
                this.$router.push({name: 'customersInventoriesView', params: {id: this.data[index].id}})
            },

            storage_name() {
                return 'customer_inventory_' + this.type()
            },

            async reload() {
                this.loading = true;
                this.data = [];
                this.lastObject && this.lastObject.cancel('ignore');
                let vehicle = this.lastObject = new Vehicle()
                    .with(['color', 'title'])
                    .where('custom_state_filter', this.type());
                if (this.range && this.range.filter(item => !!item).length) {
                    vehicle = vehicle.where('date_of_receiving', this.range.map(item => moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD')).join(','))
                }
                if (this.search) {
                    vehicle = vehicle.where('search', encodeURIComponent(this.search))
                }
                if (this.search !== this.lastSearch) {
                    this.lastSearch = this.search
                    this.pagination.page = 1
                }
                const response = await vehicle
                    .paginate(this.pagination.per_page, this.pagination.page)
                    .catch((err) => {
                        if (!err.toString().startsWith('Cancel')) {
                            console.warn(err);
                            this.loading = false;
                            throw err
                        }
                    });
                this.data = response.data || [];
                this.pagination.total_objects = response.meta.total_objects;
                this.loading = false
            },

            changePage(page) {
                this.pagination.page = page;
                this.reload()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                this.reload()
            },
        }
    }
</script>
<style lang="scss">
    .ivu-table td.image-column {
        .ivu-table-cell {
            padding-left: 0px;
            padding-bottom: 0px;
        }
    }
</style>
