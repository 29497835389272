<template>
    <Button :type="type"
            :size="size"
            :disabled="disabled"
            :loading="loading"
            icon="ios-download-outline"
            @click="action">
        <template>{{name}}</template>
    </Button>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      disabled: {
        type: Boolean,
      },
      type:{
        type: String,
        default: 'info'
      },
      name: {
        type: String,
      },
      size: {
        type: String,
        default: 'default'
      },
      url: {
        type: String,
      },
    },

    data() {
      return {
        task: null,
        loading: false,
      }
    },

    methods: {
      async action() {
        this.loading = true;
        const response = await axios.request(`/api/${this.url}`);
        this.task = response.data.task_uuid;
        this.check()
      },

      async check() {
        const response = await axios.request(`/api/downloads/${this.task}/status`);
        if (response.data.state === 'pending') {
          setTimeout(this.check, 1000);
          return
        }
        if (response.data.state === 'completed') {
            window.location.assign(response.data.url);
        }
        if (response.data.state === 'error') {
          this.$Notice.error({title: 'Generate file failed!'})
        }
        this.loading = false
      }
    }
  }
</script>
