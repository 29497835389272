<template>
    <Modal v-model="visible"
           ref="modal"
           :loading="true"
           width="800"
           :mask-closable="false"
           @on-ok="onOk"
           @on-cancel="onCancel">
        <Form ref="form"
              :model="form"
              :rules="form.rules"
              label-position="top"
              v-if="visible">
            <Row :gutter="16">
                <Col span="24">
                    <Divider orientation="left" size="small">Booking and Customer information</Divider>
                    <Row :gutter="8">
                        <Col span="6">
                            <FormItem prop="number"
                                      label="Booking number">
                                <Input size="large"
                                       v-model="form.number"
                                       :autofocus="true"
                                       type="text"
                                       placeholder="Booking number"/>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItemSelectCompany v-model="form.company"
                                                   ref="company"/>
                        </Col>
                        <Col span="6">
                            <FormItem prop="branch"
                                      label="Branch">
                                <Select :value="form.branch ? form.branch.id : null"
                                        size="large"
                                        @on-change="onChangeBranch">
                                    <Option v-for="item in listBranches"
                                            v-if="!item.is_deleted || (item.is_deleted && form.branch && form.branch.id === item.id)"
                                            :disabled="item.is_deleted"
                                            :value="item.id"
                                            :key="item.id">
                                        <template>{{item.name}}</template>
                                    </Option>
                                </Select>
                            </FormItem>
                        </Col>

                    </Row>
                    <Divider orientation="left" size="small">Shipping information</Divider>
                    <Row :gutter="8">
                        <Col span="8">
                            <FormItem prop="shipping_line"
                                      label="Shipping Line">
                                <Select :value="form.shipping_line ? form.shipping_line.id : null"
                                        filterable
                                        size="large"
                                        @on-change="onChangeLine">
                                    <Option v-for="item in listShippingLines"
                                            v-if="!item.is_deleted || (item.is_deleted && form.shipping_line && form.shipping_line.id === item.id)"
                                            :disabled="item.is_deleted"
                                            :value="item.id"
                                            :key="item.id">
                                        <template>{{item.name}}</template>
                                    </Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span="8">
                            <FormItem prop="vessel_name"
                                      label="Vessel name">
                                <Input size="large"
                                       v-model="form.vessel_name"
                                       type="text"
                                       placeholder="Vessel name"/>
                            </FormItem>
                        </Col>
                        <Col span="8">
                            <FormItem prop="voyage"
                                      label="Voyage">
                                <Input size="large"
                                       v-model="form.voyage"
                                       type="text"
                                       placeholder="Voyage"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="8">
                        <Col span="8">
                            <FormItem prop="out_terminal"
                                      label="Terminal Out">
                                <Select :value="form.out_terminal ? form.out_terminal.id : null"
                                        filterable
                                        size="large"
                                        @on-change="onChangeOut">
                                    <Option v-for="item in listTerminals"
                                            v-if="!item.is_deleted || (item.is_deleted && form.out_terminal && form.out_terminal.id === item.id)"
                                            :disabled="item.is_deleted"
                                            :value="item.id"
                                            :key="item.id">
                                        <template>{{item.name}}</template>
                                    </Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span="8">
                            <FormItem prop="in_terminal"
                                      label="Terminal In">
                                <Select :value="form.in_terminal ? form.in_terminal.id : null"
                                        filterable
                                        size="large"
                                        @on-change="onChangeIn">
                                    <Option v-for="item in listTerminals"
                                            v-if="!item.is_deleted || (item.is_deleted && form.in_terminal && form.in_terminal.id === item.id)"
                                            :disabled="item.is_deleted"
                                            :value="item.id"
                                            :key="item.id">
                                        <template>{{item.name}}</template>
                                    </Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Divider orientation="left" size="small">Port Information</Divider>
                    <Row :gutter="8">
                        <Col span="4">
                            <FormItem prop="cut_off_day"
                                      label="Cut Off Day">
                                <DatePicker
                                        :value="form.cut_off_day && form.cut_off_day.isValid() ? form.cut_off_day.toDate() : null"
                                        type="date"
                                        placeholder="Select date"
                                        size="large"
                                        format="MM/dd/yyyy"
                                        @on-change="onChangeCutOffDay"/>
                            </FormItem>
                        </Col>
                        <Col span="4">
                            <FormItem prop="early_gate"
                                      label="Early Gate">
                                <DatePicker
                                        :value="form.early_gate && form.early_gate.isValid() ? form.early_gate.toDate() : null"
                                        type="date"
                                        placeholder="Select date"
                                        size="large"
                                        format="MM/dd/yyyy"
                                        @on-change="onChangeEarlyGate"/>
                            </FormItem>

                        </Col>
                        <Col span="4">
                            <FormItem prop="early_gate_confirmation"
                                      label="Confirmed EG">
                                <i-switch v-model="form.early_gate_confirmation">
                                    <Icon type="android-done"
                                          slot="open"/>

                                    <Icon type="android-close"
                                          slot="close"/>
                                </i-switch>
                            </FormItem>
                        </Col>
                    </Row>

                    <Divider orientation="left" size="small">Container Information</Divider>
                    <Row :gutter="8">
                        <FormItem v-for="(item, index) in form.slots"
                                  :label="index === 0 ? 'Containers' : ''"
                                  :key="item.id"
                                  :prop="'slots.' + item.id +''">
                            <InputNumber :max="99"
                                         :min="1"
                                         v-model="item.count"
                                         size="large"/>

                            <RadioGroup v-model="item.size"
                                        type="button"
                                        size="large">
                                <Radio label="20"><span>20'</span></Radio>
                                <Radio label="40"><span>40'</span></Radio>
                                <Radio label="45"><span>45'</span></Radio>
                                <Radio label="RORO"><span>RORO</span></Radio>
                            </RadioGroup>

                            <ButtonGroup>
                                <Button @click="delSlot(item.id)"
                                        type="error"
                                        size="large">
                                    Delete
                                </Button>
                                <Button @click="addSlot"
                                        type="success"
                                        icon="md-add"
                                        size="large">
                                    <template>Add more</template>
                                </Button>
                            </ButtonGroup>
                        </FormItem>
                    </Row>
                    <Divider orientation="left" size="small">Destination</Divider>
                    <Row :gutter="8">
                        <Col span="4">
                            <FormItem prop="departure_date"
                                      label="Departure date">
                                <DatePicker
                                        :value="form.departure_date && form.departure_date.isValid() ? form.departure_date.toDate() : null"
                                        type="date"
                                        placeholder="Select date"
                                        size="large"
                                        format="MM/dd/yyyy"
                                        @on-change="onChangeDeparture_date"/>
                            </FormItem>
                        </Col>
                        <Col span="4">
                            <FormItem prop="arrival_date"
                                      label="Arrival date">
                                <DatePicker
                                        :value="form.arrival_date && form.arrival_date.isValid() ? form.arrival_date.toDate() : null"
                                        type="date"
                                        placeholder="Select date"
                                        size="large"
                                        format="MM/dd/yyyy"
                                        @on-change="onChangeArrival_date"/>
                            </FormItem>
                        </Col>
                        <Col span="8">
                            <FormItem prop="destination"
                                      label="Destination">
                                <Select :value="form.destination ? form.destination.id : null"
                                        filterable
                                        size="large"
                                        @on-change="onChangeDestination">
                                    <Option v-for="item in listDestinations"
                                            v-if="!item.is_deleted || (item.is_deleted && form.destination && form.destination.id === item.id)"
                                            :disabled="item.is_deleted"
                                            :value="item.id"
                                            :key="item.id">
                                        <template>{{item.name}}</template>
                                    </Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    import moment from 'moment'
    import {Branch} from '../../../../models/Branch'
    import {Booking} from '../../../../models/Booking'
    import {Company} from '../../../../models/Company'
    import {Terminal} from '../../../../models/Terminal'
    import {ShippingLine} from '../../../../models/ShippingLine'
    import {Destination} from "../../../../models/Destination";
    import uuid from '../../../../utils/uuid'

    import FormItemSelectCompany from '../../../../components/FormItemSelectCompany'

    export default {
        props: {
            value: Boolean,
        },

        components: {
            FormItemSelectCompany
        },

        data() {
            return {
                listBranches: [],
                listTerminals: [],
                listShippingLines: [],
                listDestinations: [],
                visible: false,
                loading: true,
                form: {},
            }
        },

        created() {
            this.reload()
        },

        methods: {
            async open(object) {
                this.form = null;
                await this.reload();
                if (object) {
                    this.form = object
                } else {
                    this.form = new Booking();
                    this.$set(this.form, 'slots', []);
                    this.form.slots.push({id: uuid(), count: 1, size: '40'})
                }
                this.visible = true;
            },

            async reload() {
                let response = await new Terminal().all();
                this.listTerminals = response.data || [];
                response = await new ShippingLine().all();
                this.listShippingLines = response.data || [];
                response = await new Branch().all();
                this.listBranches = response.data || [];
                response = await new Destination().all();
                this.listDestinations = response.data || [];
            },

            onChangeIn(id) {
                let filtered = this.listTerminals.filter(item => item.id === id);
                this.form.in_terminal = filtered.length ? filtered[0] : null
            },

            onChangeOut(id) {
                let filtered = this.listTerminals.filter(item => item.id === id);
                this.form.out_terminal = filtered.length ? filtered[0] : null
            },

            onChangeBranch(id) {
                let filtered = this.listBranches.filter(item => item.id === id);
                this.form.branch = filtered.length ? filtered[0] : null
            },

            onChangeDestination(id) {
                let filtered = this.listDestinations.filter(item => item.id === id);
                this.form.destination = filtered.length ? filtered[0] : null
            },

            onChangeLine(id) {
                let filtered = this.listShippingLines.filter(item => item.id === id);
                this.form.shipping_line = filtered.length ? filtered[0] : null
            },

            onChangeEarlyGate(value) {
                this.form.early_gate = moment(value, 'MM/DD/YYYY')
            },

            onChangeDeparture_date(value) {
                this.form.departure_date = moment(value, 'MM/DD/YYYY')
            },

            onChangeArrival_date(value) {
                this.form.arrival_date = moment(value, 'MM/DD/YYYY')
            },

            onChangeCutOffDay(value) {
                this.form.cut_off_day = moment(value, 'MM/DD/YYYY')
            },

            addSlot() {
                this.form.slots.push({id: uuid(), count: 1, size: '20'})
            },

            delSlot(id) {
                this.$set(this.form, 'slots', this.form.slots.filter(item => item.id !== id))
            },

            async onOk() {
                console.log(this.$refs);
                try {
                    if (this.form.company && this.form.company._uuid) {
                        let company = new Company();
                        company.name = this.form.company.name;
                        let response = await company.save();
                        await this.$refs.company.reload();
                        this.form.company = response || {}
                    }
                    await this.saveItem()
                } catch (err) {
                    this.$refs['form'].validate();
                    this.$refs['modal'].buttonLoading = false
                }
            },

            async saveItem() {
                await this.form.save();
                this.visible = false;
                this.$emit('on-ok')
            },

            onCancel() {
                this.$emit('on-cancel')
            },
        }
    }
</script>
