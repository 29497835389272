<template>
    <div>
        <Row :gutter="16">
            <Col :md="{span: 24, push: 0}"
                 :sm="{span: 16, push: 4}">
                <Upload ref="upload"
                        multiple
                        type="drag"
                        :action="'not-implemented'"
                        :show-upload-list="false"
                        :before-upload="handleBeforeUpload">
                    <div style="padding: 20px 0">
                        <Icon type="ios-cloud-upload"
                              size="52"
                              style="color: #3399ff"/>

                        <p>Click or drag files here to upload</p>
                    </div>
                </Upload>

                <div v-if="listOfNewFiles.length > 0">
                    <Progress :percent="uploadPercent"
                              hide-info/>
                    {{listOfNewFiles.length}} image(s) waiting to upload. Please, click save button!
                </div>

                <br/>
            </Col>
        </Row>

        <Row :gutter="8">
            <Col :md="24">
                <div style="column-count: 3; column-gap: 0; line-height: 0;"
                     v-viewer="{title: false, rotatable: false, scalable: false}">
                    <div class="upload-preview-list"
                         v-for="item in listOfNewFiles"
                         :key="item.uuid"
                         style="width: 100%; height: auto;">
                        <img :src="item.link" style="width: 100%; height: 100%;">

                        <div class="upload-preview-list-cover">
                            <!--<Icon type="ios-eye-outline"-->
                                  <!--@click.native="handleView(item.link)"/>-->

                            <Icon type="ios-trash-outline"
                                  @click.native="$emit('delFile', item)"/>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>

        <br/>

        <Row :gutter="8">
            <Col :md="24">
                <div style="column-count: 3; column-gap: 0; line-height: 0;"
                     v-viewer="{title: false, rotatable: false, scalable: false}">
                    <div class="upload-preview-list"
                         v-for="(item, index) in form.images"
                         :key="index" style="width: 100%; height: auto;">
                        <img :src="item.url" style="width: 100%; height: 100%;">

                        <div class="upload-preview-list-cover">
                            <!--<Icon type="ios-eye-outline"-->
                                  <!--@click.native="handleView(item.url)"/>-->

                            <Icon type="ios-trash-outline"
                                  @click.native="handleRemove(item)"/>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>

        <Modal title="View Image"
               v-model="visible">
            <img :src="imageUrl"
                 v-if="visible"
                 style="width: 100%">
        </Modal>
    </div>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      form: {
        type: Object,
      },
      listOfNewFiles: {
        type: Array,
      },
      uploadPercent: {
        type: Number,
      }
    },

    data() {
      return {
        headers: {
          authorization: localStorage.getItem('user-token'),
        },
        imageUrl: '',
        visible: false,
      }
    },

    methods: {
      handleView(url) {
        this.imageUrl = url;
        this.visible = true
      },

      handleBeforeUpload(file) {
        this.$emit('addFile', file);
        return false
      },

      handlePreviewRemove(item) {
        this.listOfNewFiles = this.listOfNewFiles.filter(temp => temp.uuid !== item.uuid)
      },

      async handleRemove(item) {
        await axios
          .request({
              url: this.form.resourceImages() + item.url.split('/').pop(),
              method: 'delete',
            }
          )
          .catch(console.warn);
        this.form.images = this.form.images.filter(temp => temp.url !== item.url)
      },
    },
  }
</script>