import {Base} from './Base'
import {Driver} from "./Driver"

export class Carrier extends Base {

  resourceName() {
    return 'carriers'
  }

  fields() {
    return [
      'name',
      'address1',
      'address2',
      'city',
      'region',
      'country',
      'postal_code',
      'phone',
      'fax',
      'email',
      'is_deleted',
    ]
  }

  relations() {
    return {
      drivers: (...arg) => new Driver(...arg),
    }
  }

  fullAddress(object = null) {
    if (!object) {
      object = this
    }
    return [
      object.address1,
      object.address2,
      object.city,
      object.region,
      object.country,
      object.postal_code
    ].filter((item) => item).join(', ')
  }

  async replace(replace_id) {
    return await this.request({
      url: `${this.baseUrl()}/${this.resourceName()}/${this.id}/replace/${replace_id}`,
      method: 'GET',
    })
  }

}
