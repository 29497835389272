<template>
    <Layout class="layout-white">
        <Content style="display: flex">
            <div style="margin: auto">
                <img src="../assets/hmotors-logo-new.png">
                <div class="main-block-style">
                    <router-link to="/app/login" style="float: left" class="main-link-style">
                        <template>Employee login</template>
                    </router-link>
                    <router-link to="/customer/login" style="float: right" class="main-link-style">
                        <template>Customer login</template>
                    </router-link>
                </div>
            </div>
        </Content>
    </Layout>
</template>

<script>
  export default {}
</script>

<style>
    .main-block-style {
        padding-top: 20px;
    }
    .main-link-style {
        font-size: 20px;
        color: black;
    }
</style>