<template>
    <div>
        <Row :gutter="16">
            <Col :md="{span: 24, push: 0}"
                 :sm="{span: 16, push: 4}">
                <Upload ref="upload"
                        multiple
                        type="drag"
                        :action="'not-implemented'"
                        :before-upload="handleBeforeUpload">
                    <div style="padding: 20px 0">
                        <Icon type="ios-cloud-upload"
                              size="52"
                              style="color: #3399ff"/>
                        <p>Click or drag files here to upload</p>
                    </div>
                </Upload>

                <br/>
            </Col>
        </Row>

        <Row :gutter="8">
            <Col :md="24">
                <div style="column-count: 3; column-gap: 10px; line-height: 0;"
                     v-if="form.attachments && form.attachments.data && form.attachments.data.length > 0">
                    <div style="display: inline-block; width: 100%;"
                         v-for="item in getAttachments()"
                         :key="item.id">
                        <Alert show-icon closable
                               @on-close="handleClose(item)">
                            <Icon type="md-copy" slot="icon"/>
                            <template>{{item.file_name}}&nbsp;</template>

                            <Icon type="document" slot="icon"/>

                            <template slot="desc">
                                <a :href="item.url"
                                   target="_blank">
                                    <template>Download file</template>
                                </a>
                            </template>
                        </Alert>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      form: {
        type: Object,
      },
      type: {
        type: String,
        required: false,
        default: 'open',
      },
    },

    data() {
      return {}
    },

    methods: {
      getAttachments() {
        return this.form.attachments.data.filter(item => item.access === this.type)
      },

      async handleClose(item) {
        await axios.request({url: this.form.resourceAttachments() + item.id, method: 'delete',});
        this.$emit('reload')
      },

      handleBeforeUpload(file) {
        this.handleUpload(file);
        return false
      },

      async handleUpload(file) {

        let data = new FormData();
        data.append('file', file);
        data.append('access', this.type);

        await axios
          .request({url: this.form.resourceAttachments(), data: data, method: 'post'})
          .then(() => this.$Notice.success({title: 'Attachment added'}))
          .catch(error => {
            console.warn(error);
            this.$Notice.error({
              title: 'Save attachment failed',
              desc: (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 && error.response.data.errors[0].detail) || `The remote server returned an error: (${error.response.status}) ${error.response.statusText}`
            })
          });
        this.$emit('reload');
      },
    },
  }
</script>
