<template>
    <Layout>
        <Sider ref="side"
               hide-trigger
               collapsible
               :collapsed-width="78"
               v-model="isCollapsed">
            <Menu :active-name="$route.meta.menu"
                  theme="light"
                  width="100%"
                  :class="menuitemClasses"
                  accordion
                  @on-select="menuSelect">
                <MenuItem name="appDashboard"
                          v-if="$store.getters.hasAccess('dashboard', 'read')">
                    <Icon type="ios-speedometer-outline"/>
                    <span>Dashboard</span>
                </MenuItem>

                <MenuItem name="appBooking"
                          v-if="$store.getters.hasAccess('booking', 'read')">
                    <Icon type="ios-boat"/>
                    <span>Booking</span>
                </MenuItem>

                <MenuItem name="appContainers"
                          v-if="$store.getters.hasAccess('container', 'read')">
                    <Icon type="ios-grid"/>
                    <span>Containers</span>
                </MenuItem>

                <MenuItem name="appInventories"
                          v-if="$store.getters.hasAccess('inventory', 'read')">
                    <Icon type="ios-car"/>
                    <span>Inventories</span>
                </MenuItem>

                <MenuItem name="appLoadPlans"
                          v-if="$store.getters.hasAccess('inventory', 'read')">
                    <Icon type="ios-list-box"/>
                    <span>Load Plans</span>
                </MenuItem>

                <MenuItem name="partnerInventories"
                          v-if="$store.getters.isPartner()">
                    <Icon type="ios-car"/>
                    <span>Inventories</span>
                </MenuItem>
                <Submenu name="appCRM" v-if="$store.getters.hasAccess('company', 'read')">
                    <template slot="title">
                        <Icon type="ios-people"/>
                        <span>CRM</span>
                    </template>
                    <MenuItem name="appCompanies"
                              v-if="$store.getters.hasAccess('company', 'read')">
                        <Icon type="ios-people"/>
                        <span>Customers</span>
                    </MenuItem>
                    <MenuItem name="appCarriers"
                              v-if="$store.getters.hasAccess('company', 'read')">
                        <Icon type="ios-speedometer"/>
                        <span>Carriers</span>
                    </MenuItem>
                </Submenu>

                <MenuItem name="appNotifications"
                          v-if="$store.getters.isAdmin()">
                    <Icon type="ios-alert"/>
                    <span>Notifications</span>
                </MenuItem>

                <MenuItem name="appConditionReport"
                          v-if="$store.getters.hasAccess('cr', 'create')">
                    <Icon type="ios-color-wand"/>
                    <span>Condition Report</span>
                </MenuItem>

                <Submenu name="appSettings" v-if="$store.getters.isAdmin()">
                    <template slot="title">
                        <Icon type="ios-settings"/>
                        <span>Settings</span>
                    </template>
                    <MenuItem name="appBranches">
                        <Icon type="ios-folder-open"/>
                        <span>Dictionaries</span>
                    </MenuItem>
                    <MenuItem name="appEmployees">
                        <Icon type="ios-person"/>
                        <span>Employees</span>
                    </MenuItem>
                </Submenu>
            </Menu>
        </Sider>

        <Content>
            <Header class="layout-header-bar">
                <div style="padding: 0 20px;">
                    <Icon @click.native="collapsedSider"
                          :class="rotateIcon"
                          type="ios-menu"
                          size="24"/>
                </div>

                <Breadcrumb>
                    <BreadcrumbItem v-for="item in breadcrumbs"
                                    :key="item.name">
                        <router-link v-if="item.path"
                                     :to="item.path">
                            <template>{{item.name}}</template>
                        </router-link>

                        <template v-else>{{item.name}}</template>
                    </BreadcrumbItem>
                </Breadcrumb>

                <div style="float: right; padding-right: 20px;">
                    <span>{{fullName}}</span>

                    <Dropdown trigger="click"
                              @on-click="menuClick"
                              placement="bottom-end">
                        <user-avatar :first-name="firstName"
                                     :last-name="lastName"/>

                        <DropdownMenu slot="list">
                            <DropdownItem name="logout"
                                          divided>
                                <template>Logout</template>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </Header>
            <Content style="margin: 3px 0 0; background: #fff; min-height: calc(100vh - 160px);">
                <Notification/>
                <router-view/>
            </Content>

            <Footer class="layout-footer-center">Copyright &copy; {{ new Date().getFullYear() }} H-MOTORS Inc.</Footer>
        </Content>
    </Layout>
</template>

<script>
    import {AUTH_LOGOUT} from '../../store/actions/auth'
    import UserAvatar from "../../components/UserAvatar";
    import Notification from '../../components/NotificationCustomer'

    export default {
        components: {
            UserAvatar,
            Notification
        },
        data() {
            return {
                isCollapsed: localStorage.getItem('menu') === 'true'
            }
        },

        computed: {
            fullName() {
                return this.$store.getters.getFullName
            },

            firstName() {
                return this.$store.getters.firstName
            },

            lastName() {
                return this.$store.getters.lastName
            },

            rotateIcon() {
                return [
                    'menu-icon',
                    this.isCollapsed ? 'rotate-icon' : ''
                ];
            },

            menuitemClasses() {
                return [
                    'menu-item',
                    this.isCollapsed ? 'collapsed-menu' : ''
                ]
            },

            breadcrumbs() {
                return this.$route.matched.filter(item => !!item.meta.breadcrumb)
                    .map(item => new Object({
                        name: item.meta.breadcrumb,
                        path: (this.$route.name === item.name) ? null : item.path
                    }))
            }
        },

        methods: {
            menuClick(name) {
                if (name === 'logout') {
                    this.logout()
                }
                if (name === 'reset') {
                    for (const item in {...localStorage}) {
                        if (item.startsWith('size_of_table')) {
                            localStorage.removeItem(item)
                        }
                    }
                    location.reload()
                }
            },

            menuSelect(name) {
                this.$router.push({name: name})
            },

            collapsedSider() {
                this.$refs.side.toggleCollapse();
                localStorage.setItem('menu', this.isCollapsed)
            },

            async logout() {
                await this.$store.dispatch(AUTH_LOGOUT);
                this.$router.push({name: 'appLogin'})
            }
        }
    }
</script>

<style lang="scss">
    .menu-item span {
        width: inherit !important;
    }

    .collapsed-menu span {
        width: 0 !important;
    }
</style>
