<template>
    <div>
        <Table :columns="columns"
               :data="data"
               border/>

        <ModifiedContainer ref="modal"
                           @on-ok="onOk"
                           :booking="false"/>

        <ModifiedRequirement ref="modalRequirement"
                             @on-ok="onOkRequirement"/>
    </div>
</template>

<script>
    import {Container} from "../../../models/Container"
    import ModifiedContainer from '../modals/containers/modified'
    import ModifiedRequirement from '../modals/requirement/modified'

    export default {
        props: {
            data: Array,
        },

        components: {
            ModifiedContainer,
            ModifiedRequirement,
        },

        data() {
            return {

                columns: [
                    {
                        title: 'Container',
                        key: 'number',
                        width: 150,
                        ellipsis: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            let elements = [];
                            elements.push(
                                h('Badge', {
                                    props: {
                                        'dot': '',
                                    },
                                    class: {
                                        'badge-green': params.row.is_images,
                                    }
                                })
                            );
                            elements.push(
                                h(params.row.container_tracking_url ? 'a' : 'span', {
                                    style: {
                                        'padding-left': '10px',
                                    },
                                    attrs: {
                                        target: '_blank',
                                        href: params.row.container_tracking_url,
                                    },
                                }, (params.row.number || '')),
                            );
                            elements.push(h('span', ' ' + params.row.size + "'"));
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'Chassis',
                        key: 'chassis',
                        width: 120,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('span', params.row.chassis ? params.row.chassis.name : null)
                        },
                    },
                    {
                        title: 'Date/Driver Out',
                        key: 'date_out',
                        width: 200,
                        ellipsis: true,
                        render: (h, params) => {
                            let elements = [];
                            elements.push(h('span', params.row.date_out && params.row.date_out.isValid() ? params.row.date_out.format('MM/DD/YYYY') : null));
                            elements.push(h('span', ' ' + params.row.driver_out));
                            return elements
                        },
                    },
                    {
                        title: 'Date/Driver In',
                        key: 'date_in',
                        width: 200,
                        ellipsis: true,
                        render: (h, params) => {
                            let elements = [];
                            elements.push(h('span', params.row.date_in && params.row.date_in.isValid() ? params.row.date_in.format('MM/DD/YYYY') : null));
                            elements.push(h('span', ' ' + params.row.driver_in));
                            return elements
                        },
                    },
                    {
                        title: 'Terminal',
                        key: 'terminal',
                        minWidth: 80,
                        width: 120,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('span', [params.row.terminal_out_name, params.row.terminal_in_name].join('/'))
                        },
                    },
                    {
                        title: 'Seal',
                        key: 'seal',
                        minWidth: 40,
                        width: 100,
                        ellipsis: true,
                    },
                    {
                        title: 'State',
                        key: 'state_humanize',
                        minWidth: 40,
                        width: 120,
                        ellipsis: true,
                    },
                    {
                        title: 'Notes',
                        key: 'notes',
                        minWidth: 40,
                        width: 120,
                        ellipsis: true,
                    },
                    {
                        title: 'Actions',
                        width: 310,
                        key: 'actions',
                        fixed: 'right',
                        render: (h, params) => {
                            return h('div',
                                [
                                    ...this.buttons(h, params),
                                    ...this.columnActions(h, params),
                                ]
                            )
                        }
                    }
                ],
                templates: {
                    forming: [{action: 'pull', name: 'Pull',}],
                    pulled: [{action: 'rollback', name: 'Rollback',}, {action: 'accept', name: 'Accept',}],
                    in_yard_empty: [{action: 'rollback', name: 'Rollback',}, {action: 'upload', name: 'Upload',}],
                    in_yard_full: [{action: 'rollback', name: 'Rollback',}, {action: 'push', name: 'Push',}],
                    pushed: [{action: 'rollback', name: 'Rollback',}, {action: 'in_gate', name: 'In Gate',}],
                }
            }
        },

        methods: {
            columnActions(h, params) {
                let actions = [];

                if (this.show && params.row.state !== 'forming') {
                    actions.push(h('Button', {
                        props: {type: 'success', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {click: () => this.show(params.index)}
                    }, 'View'))
                }

                if (this.$store.getters.hasAccess('container', 'update')) {
                    actions.push(h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {click: () => this.edit(params.index)}
                    }, 'Edit'))
                }

                if (this.$store.getters.hasAccess('container', 'delete') && params.row.state === 'forming') {
                    actions.push(h('Poptip', {
                        props: {
                            confirm: true,
                            title: 'Are you sure you want to delete this item?',
                            placement: 'left',
                            transfer: true,
                        },
                        on: {
                            'on-ok': () => this.remove(params.index)
                        }
                    }, [h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                    }, 'Delete')]));
                }
                return actions
            },

            buttons(h, params) {
                let result = [];

                if (this.$store.getters.hasAccess('requirement', 'create') && this.templates[params.row.state]) {
                    this.templates[params.row.state].forEach(({action, name}) => {
                        if (!(action === 'upload' && params.row.loads_count === 0)) {
                            result.push(
                                h('Button', {
                                    props: {
                                        type: 'default',
                                        size: 'small',
                                    },
                                    style: {
                                        marginRight: '5px',
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.modalRequirement.open({
                                                direction: action,
                                                container: new Container().resolveItem(this.data[params.index])
                                            })
                                        }
                                    }
                                }, name)
                            )
                        }
                    })
                }

                return result
            },

            onOk() {
                this.$emit('on-change')
            },

            onOkRequirement() {
                this.$emit('on-change')
            },

            show(index) {
                this.$router.push({name: 'appContainersInventories', params: {id: this.data[index].id}})
            },

            edit(index) {
                this.$refs.modal.open(new Container().resolveItem(this.data[index]))
            },

            async remove(index) {
                await new Container().resolveItem(this.data[index]).delete();
                this.$emit('on-change')
            },
        },
    }
</script>
