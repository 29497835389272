<template>
    <Modal v-model="visible"
           ref="modal"
           :title="title"
           :loading="true"
           @on-ok="onOk"
           @on-cancel="onCancel">
        <h3 style="text-align: center">Please, confirm your action!</h3><br/>

        <Form ref="form"
              :model="form"
              :rules="form.rules"
              label-position="right"
              :label-width="120">

            <FormItem prop="requirement_date"
                      label="Requirement Date"
                      v-if="field.includes('requirement_date')">
                <DatePicker type="date"
                            placeholder="Select date"
                            size="large"
                            format="MM/dd/yyyy"
                            :value="form.requirement_date && form.requirement_date.isValid && form.requirement_date.isValid() ? form.requirement_date.toDate() : null"
                            @on-change="onChangeRequirementDate"/>
            </FormItem>

            <FormItem prop="driver_name"
                      label="Driver"
                      v-if="field.includes('driver')">
                <Input size="large"
                       v-model="form.driver_name"
                       type="text"
                       placeholder="Driver"/>
            </FormItem>

            <FormItem prop="container_number"
                      label="Container Number"
                      v-if="field.includes('container_number')">
                <Input size="large"
                       v-model="form.container_number"
                       type="text"
                       placeholder="Container Number"/>
            </FormItem>

            <FormItem prop="chassis"
                      label="Chassis"
                      v-if="field.includes('chassis')">
                <Select v-model="form.chassis_id"
                        filterable
                        size="large">
                    <Option v-for="item in chassis"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
            </FormItem>

            <FormItem prop="seal"
                      label="Seal"
                      v-if="field.includes('seal')">
                <Input size="large"
                       v-model="form.seal"
                       type="text"
                       placeholder="Seal"/>
            </FormItem>

            <FormItem prop="loaders"
                      label="Loaders"
                      v-if="field.includes('loaders')">
                <Input size="large"
                       v-model="form.loaders"
                       type="text"
                       placeholder="loaders name's"/>
            </FormItem>
        </Form>
    </Modal>
</template>

<script>
  import moment from 'moment'
  import {Chassis} from '../../../../models/Chassis'
  import {Requirement} from '../../../../models/Requirement'

  export default {
    props: {
      value: Boolean,
    },

    data() {
      return {
        visible: false,
        chassis: [],
        form: {},
        title: '',
        field: [],
        configuration: {
          pull: {
            title: 'Pull request',
            field: ['requirement_date', 'driver'],
          },
          accept: {
            title: 'Accept request',
            field: ['container_number', 'chassis'],
          },
          upload: {
            title: 'Upload request',
            field: ['seal', 'loaders'],
          },
          rollback: {
            title: 'Rollback request',
            field: [],
          },
          push: {
            title: 'Push request',
            field: ['requirement_date', 'driver'],
          },
          in_gate: {
            title: 'In gate request',
            field: [],
          },
        }
      }
    },

    methods: {
      open(object = null) {
        this.title = this.configuration[object.direction].title;
        this.field = this.configuration[object.direction].field;

        this.form = new Requirement();
        this.form.requirement_date = moment();
        if (object) {
          if (object.container) {
            this.form.container = object.container;
            this.form.container_number = object.container.number;
          }
          if (object.direction) {
            this.form.direction = object.direction
          }
        }
        this.loadChassis();
        this.visible = true
      },

      async loadChassis() {
        let response = await new Chassis().all();
        this.chassis = (response.data || []).filter(item => !item.is_deleted);
      },

      onChangeRequirementDate(value) {
        this.form.requirement_date = moment(value, 'MM/DD/YYYY')
      },

      onOk() {
        this.form
          .save()
          .then(resp => {
            this.visible = false;
            this.$emit('on-ok')
          })
          .catch(err => {
            this.$refs['form'].validate();
            this.$refs['modal'].buttonLoading = false
            throw err;
          })
      },

      onCancel() {
        this.$emit('on-cancel')
      },
    }
  }
</script>