import Vue from 'vue'
import moment from 'moment'

Vue.filter('humanize', (value) => {
  if (!value) return '';
  return value.toString().toLowerCase().replace(/[_-]/g, ' ').replace(/(?:^|\s)\S/g, (str) => str.toUpperCase())
});

Vue.filter('to_humanize_date', (value) => {
  if (!value || !value.format) return '';
  if (value.isValid && !value.isValid()) return '';
  return value.format('MM/DD/YYYY')
});

Vue.filter('to_humanize_year', (value) => {
  if (!value || !value.format) return '';
  if (value.isValid && !value.isValid()) return '';
  return value.format('YYYY')
});

Vue.filter('to_humanize_boolean', (value = null) => {
  return (value === null || value === undefined) ? '' : (value ? 'yes' : 'no')
});

Vue.filter('to_string', (value = null) => {
  if (value && value.__proto__.constructor.name === 'Array') {
    return value.map(item => moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD')).join(',')
  }
});