<template>
    <div>
        <div class="upload-zone">
            <Upload
                    ref="upload"
                    name="image"
                    :headers="headers"
                    :show-upload-list="false"
                    :default-file-list="imageList"
                    :on-success="handleSuccess"
                    :format="['jpg','jpeg','png']"
                    :max-size="10240"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :before-upload="handleBeforeUpload"
                    multiple
                    type="drag"
                    :action="addImageUrl">
                <div style="padding: 20px 0">
                    <Icon type="ios-camera" size="80" style="color: #3399ff"/>

                    <p>Click or drag images here to upload</p>
                </div>
            </Upload>
        </div>
        <div class="spin-col" v-if="loading">
            <Spin fix>
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>Loading images...</div>
            </Spin>
        </div>
        <div>
            <div class="upload-list" v-for="item in uploadList">
                <template v-if="item.status === 'finished'">
                    <img :src="item.thumb">
                    <div class="upload-list-cover">
                        <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                        <Icon type="ios-trash-outline" @click.native="handleDeleteClick(item)"></Icon>
                    </div>
                </template>
                <template v-else>
                    <i-progress v-if="item.showProgress" :percent="item.percentage" :stroke-width="20" status="active"
                                text-inside="true"></i-progress>
                </template>
            </div>
        </div>

        <Modal title="View Image" v-model="visible" width="1000">
            <img :src="imageUrl" v-if="visible" style="width: 100%">
        </Modal>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        props: {
            addImageUrl: {
                type: String,
                required: true
            },
            imageList: {
                type: Array,
                default() {
                    return [];
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                headers: {
                    authorization: localStorage.getItem('user-token'),
                },
                uploadList: [],
                imageUrl: '',
                visible: false,
            }
        },
        methods: {
            handleDeleteClick(item){
                this.$confirm(
                    {
                        message: '🔥 Are you sure you want to delete the photo? Once deleted, a photo cannot be restored',
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: confirm => {
                            if (confirm === true) {
                                this.handleRemove(item)
                            }
                        }

                    },

                )
            },

            handleView(url) {
                this.imageUrl = url;
                this.visible = true
            },
            async handleRemove(file) {
                const fileList = this.$refs.upload.fileList;
                this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
                await axios
                    .request({
                            url: this.addImageUrl + file.key,
                            method: 'delete',
                        }
                    )
                    .catch(console.warn);
            },

            handleSuccess(res, file) {
                file.thumb = res.thumb;
                file.url = res.url;
                file.original_url = res.original_url
                file.key = res.key;
            },
            handleFormatError(file) {
                this.$Notice.warning({
                    title: 'The file format is incorrect',
                    desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
                });
            },
            handleMaxSize(file) {
                this.$Notice.warning({
                    title: 'Exceeding file size limit',
                    desc: 'File  ' + file.name + ' is too large, no more than 10M.'
                });
            },
            handleBeforeUpload() {
                const check = this.uploadList.length < 150;
                if (!check) {
                    this.$Notice.warning({
                        title: 'Up to 150 pictures can be uploaded.'
                    });
                }
                return check;
            },


        },
        mounted() {
            this.uploadList = this.$refs.upload.fileList;
        },

        updated() {
            this.uploadList = this.$refs.upload.fileList;
        }


    }
</script>
<style>
    .upload-list {
        display: inline-block;
        width: 220px;
        height: 220px;
        text-align: center;
        line-height: 220px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
        margin-right: 4px;
    }

    .upload-list img {
        width: 100%;
        height: 100%;
    }

    .upload-list-cover {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .6);
    }

    .upload-list:hover .upload-list-cover {
        display: block;
    }

    .upload-list-cover i {
        color: #fff;
        font-size: 40px;
        cursor: pointer;
        margin: 0 2px;
    }

    .upload-zone {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .spin-col {
        height: 100px;
        position: relative;
        border: 1px solid #eee;
        margin: 10px 0px;
    }

    .spin-icon-load {
        animation: ani-demo-spin 1s linear infinite;
    }

</style>
