<template>
    <Tabs :value="type"
          @on-click="onTabClick">
        <TabPane v-for="state in stateList"
                 :label="state.label"
                 :name="state.name"
                 :key="state.name">
            <containers :ref="state.name"
                        :type="type"
                        :search="search"/>
        </TabPane>
        <template slot="extra">
            <Input :value="search"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   @on-change="onChangeSearch"/>
        </template>
    </Tabs>
</template>

<script>
    import containers from './_list'

    export default {
        props: {
            type: {
                type: String,
                default: 'in_gated',
            }
        },

        components: {
            containers,
        },

        data() {
            return {
                search: '',
                stateList: [
                    {label: 'In Gated', name: 'in_gated'},
                ],
            }
        },

        mounted() {
            this.reload()
        },

        watch: {
            '$route': 'reload',
        },

        methods: {
            reload() {
                this.$refs[this.type][0].reload()
            },

            onTabClick(name) {
                this.$router.push({path: name})
            },
            onChangeSearch(event) {
                this.search = event.target.value;
                this.reload();
            },
        }
    }
</script>
