import Vue from 'vue'
import ViewUI from 'view-design';
import store from '../store'
import Router from 'vue-router'

import {parseJwt, validToken} from '../utils/jwt'

import index from '../modules/index'

import appLogin from '../modules/app/login'
import appLayout from '../modules/app/layout'
import appChildren from './employee'

import customersLogin from '../modules/customers/login'
import customersLayout from '../modules/customers/layout'
import customersChildren from './customer'
import support from '../modules/app/support'

import {USER_REQUEST} from '../store/actions/user'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/support',
      name: 'support',
      component: support,
    },
    // customers zone
    {
      path: '/customer/login',
      name: 'customersLogin',
      component: customersLogin,
    },
    {
      path: '/customer',
      component: customersLayout,
      children: customersChildren,
    },

    // employees zone
    {
      path: '/app/login',
      name: 'appLogin',
      component: appLogin,
    },
    {
      path: '/app',
      component: appLayout,
      children: appChildren,
    },

    // public zone
    {
      path: '',
      name: 'index',
      component: index,
    },

    // unknown zone
    {
      path: '*',
      name: 'NotFound',
      redirect: {
        name: 'appDashboard'
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();

  const isPartner = (localStorage.getItem('isPartner') || 'false') === 'true';
  let token = localStorage.getItem('user-token');
  let parse = parseJwt(token);
  let valid = validToken(parse);
  // equals token with path
  if (valid) {
    if (!store.getters.isProfileLoaded) {
      store.dispatch(USER_REQUEST)
    }
    if (parse.scp === 'employee' && !to.path.startsWith('/app')) {
      next({name: 'appLogin'})
    }
    if (parse.scp === 'customer' && !to.path.startsWith('/customer')) {
      next({name: 'customersLogin'})
    }
  }

  // employees authorization
  if (to.path.startsWith('/app/login')) {
    valid ? next({name: isPartner ? 'partnerInventories' : 'appDashboard'}) : next()
  } else

  // employees zone
  if (to.path.startsWith('/app')) {
    console.warn('isPartner', isPartner, valid);
    if (valid && isPartner && !to.path.startsWith('/app/partner')) {
      next({name: 'partnerInventories'})
    }
    valid ? next() : next({name: 'appLogin'})
  } else

  // customers authorization
  if (to.path === '/customer/login') {
    valid ? next({name: 'customersDashboard'}) : next()
  } else

  // customers zone
  if (to.path.startsWith('/customer')) {
    valid ? next() : next({name: 'customersLogin'})
  } else

  // public zone
    next()
});

router.afterEach((to) => {
  ViewUI.LoadingBar.finish();
  window.scrollTo(0, 0)
});

export default router
