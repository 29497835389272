import {Base} from './Base'

export class Attachment extends Base {
  resourceName() {
    return 'attachments'
  }

  fields() {
    return [
      'url',
      'file_name',
      'file_size',
      'content_type',
      'access',
    ]
  }
}