<template>
    <Content id="dashboard"
             style="padding: 16px;">
        <Row>
            <Col :xs="24" :md="16" :lg="6" :xl="6"
                 v-if="$store.getters.hasAccess('dashboard', 'read')"
                 v-for="(item,index) in todayAnalytics" :key="index"  style="padding-bottom: 10px">
                <InfoCard :name="item.name"
                          :count="item.count" />
            </Col>
        </Row>
      <Row>
        <MarinaTraffic />
<!--        <Col :xs="24" :md="16" :lg="6" :xl="6"-->
<!--             v-if="$store.getters.hasAccess('dashboard', 'read')" style="padding-bottom: 10px">-->
<!--          <MarinaTraffic />-->
<!--        </Col>-->
      </Row>
<!--      Убираем пока наши красивые графики-->
<!--        <Row :gutter="16">-->
<!--            <Col :xs="24" :md="24" :lg="16" :xl="16"-->
<!--                 style="margin-bottom: 16px"-->
<!--                 v-if="$store.getters.hasAccess('dashboard', 'read')">-->
<!--                <Card dis-hover class="dashboard-chart">-->
<!--                    <Spin fix v-if="inventoriesChartData.loading">-->
<!--                        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>-->
<!--                        <div>Loading</div>-->
<!--                    </Spin>-->
<!--                    <p slot="title">Inventories by status over time</p>-->
<!--                    <DatePicker format="MM/dd/yyyy"-->
<!--                                type="daterange"-->
<!--                                size="small"-->
<!--                                slot="extra"-->
<!--                                placeholder="Select date"-->
<!--                                style="width: 180px; font-size: 12px;"-->
<!--                                @on-change="onInventoriesChartChangeRange"/>-->

<!--                    <BarChart :chartData="inventoriesChartData.data"-->
<!--                              :options="inventoriesChartData.options"-->
<!--                              :height="300"-->
<!--                    />-->
<!--                </Card>-->

<!--            </Col>-->
<!--            <Col :xs="24" :md="24" :lg="8" :xl="8"-->
<!--                 style="margin-bottom: 16px"-->
<!--                 v-if="$store.getters.hasAccess('dashboard', 'read')">-->
<!--                <Card dis-hover class="dashboard-chart">-->
<!--                    <Spin fix v-if="inventoryTotalData.loading">-->
<!--                        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>-->
<!--                        <div>Loading</div>-->
<!--                    </Spin>-->
<!--                    <p slot="title">Inventories by status</p>-->
<!--                    <DatePicker format="MM/dd/yyyy"-->
<!--                                type="daterange"-->
<!--                                size="small"-->
<!--                                slot="extra"-->
<!--                                placeholder="Select date"-->
<!--                                style="width: 180px; font-size: 12px; float: right;"-->
<!--                                @on-change="onInventoryChangeRange"/>-->

<!--                    <PieChart v-show="this.inventoryTotalData && this.inventoryTotalData.data && this.inventoryTotalData.data.datasets && this.inventoryTotalData.data.datasets[0] && this.inventoryTotalData.data.datasets[0].data && this.inventoryTotalData.data.datasets[0].data.length !== 0"-->
<!--                              :chartData="inventoryTotalData.data"-->
<!--                              :options="inventoryTotalData.options"-->
<!--                              :height="350"/>-->
<!--                    <div v-show="this.inventoryTotalData && this.inventoryTotalData.data && this.inventoryTotalData.data.datasets && this.inventoryTotalData.data.datasets[0] && this.inventoryTotalData.data.datasets[0].data && this.inventoryTotalData.data.datasets[0].data.length === 0" style="height: 300px;">-->
<!--                        <img src="/images/purr.gif" style="position: absolute; margin: auto;top: 0; left: 0; right: 0;bottom: 0;"/>-->
<!--                        <h1 style="text-align: center; color: gray; padding: 10px;">-->
<!--                            waiting-->
<!--                        </h1>-->
<!--                    </div>-->
<!--                </Card>-->

<!--            </Col>-->
<!--            <Col :xs="24" :md="24" :lg="16" :xl="16"-->
<!--                 style="margin-bottom: 16px"-->
<!--                 v-if="$store.getters.hasAccess('dashboard', 'read')">-->
<!--                <Card dis-hover class="dashboard-chart">-->
<!--                    <Spin fix v-if="containersChartData.loading">-->
<!--                        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>-->
<!--                        <div>Loading</div>-->
<!--                    </Spin>-->
<!--                    <p slot="title">Containers by status over time</p>-->
<!--                    <DatePicker format="MM/dd/yyyy"-->
<!--                                type="daterange"-->
<!--                                size="small"-->
<!--                                slot="extra"-->
<!--                                placeholder="Select date"-->
<!--                                style="width: 180px; font-size: 12px;"-->
<!--                                @on-change="onContainersChartChangeRange"/>-->

<!--                    <BarChart :chartData="containersChartData.data"-->
<!--                              :options="containersChartData.options"-->
<!--                              :height="300"-->
<!--                    />-->
<!--                </Card>-->
<!--                </Col>-->

<!--                <Col :xs="24" :md="24" :lg="8" :xl="8"-->
<!--                     style="margin-bottom: 16px"-->
<!--                     v-if="$store.getters.hasAccess('dashboard', 'read')">-->
<!--                    <Card dis-hover class="dashboard-chart">-->
<!--                        <Spin fix v-if="containerTotalData.loading">-->
<!--                            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>-->
<!--                            <div>Loading</div>-->
<!--                        </Spin>-->
<!--                        <p slot="title">Containers by status</p>-->
<!--                        <DatePicker format="MM/dd/yyyy"-->
<!--                                    type="daterange"-->
<!--                                    size="small"-->
<!--                                    slot="extra"-->
<!--                                    placeholder="Select date"-->
<!--                                    style="width: 180px; font-size: 12px; float: right;"-->
<!--                                    @on-change="onContainerChangeRange"/>-->
<!--                          <PieChart v-show="this.containerTotalData && this.containerTotalData.data && this.containerTotalData.data.datasets && this.containerTotalData.data.datasets[0] && this.containerTotalData.data.datasets[0].data && this.containerTotalData.data.datasets[0].data.length !== 0"-->
<!--                                      :chartData="containerTotalData.data"-->
<!--                                      :options="containerTotalData.options"-->
<!--                                      :height="300"/>-->
<!--                        <div v-show="this.containerTotalData && this.containerTotalData.data && this.containerTotalData.data.datasets && this.containerTotalData.data.datasets[0] && this.containerTotalData.data.datasets[0].data && this.containerTotalData.data.datasets[0].data.length ===  0" style="height: 300px;">-->
<!--                            <img src="/images/purr.gif" style="position: absolute; margin: auto;top: 0; left: 0; right: 0;bottom: 0;"/>-->
<!--                            <h1 style="text-align: center; color: gray; padding: 10px;">-->
<!--                                waiting-->
<!--                            </h1>-->
<!--                        </div>-->
<!--                    </Card>-->

<!--                </Col>-->

<!--        </Row>-->
    </Content>
</template>

<script>
    import BarChart from '../../../components/BarChart'
    import InfoCard from '../../../components/InfoCard'
    import PieChart from "../../../components/PieChart"
    import MarinaTraffic from "../../../components/MarineTraffic"
    import axios from 'axios'

    export default {
        name: 'Dashboard',
        components: {
            BarChart,
            InfoCard,
            PieChart,
            MarinaTraffic
        },

        data() {
            return {
                todayAnalytics: [],

                containersChartData: {
                    loading: false,
                    data: {},
                    options: {
                        response: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                            position: 'right'
                        },
                        scales: {
                            xAxes: [{
                                stacked: true

                            }],
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                    }
                },

                inventoriesChartData: {
                    loading: false,
                    data: {},
                    options: {
                        response: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                            position: 'right'
                        },
                        scales: {
                            xAxes: [{
                                stacked: true

                            }],
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                    }
                },

                inventoryTotalData: {
                    loading: false,
                    options: {
                        response: true,
                        legend: {
                            position: 'bottom'
                        },
                    },
                    data: {}
                },
                containerTotalData: {
                    loading: false,
                    options: {
                        response: true,
                        legend: {
                            position: 'bottom'
                        },
                    },
                    data: {}
                }
            }
        },

        mounted() {

            this.fillTodayData();
            this.fillContainersChartData();
            this.fillInventoriesChartData();
            this.fillContainersData();
            this.fillInventoriesData();
        },
        methods: {
            createCR() {
                this.$router.push({name: 'appConditionReport'})
            },
            fillTodayData(){
                axios
                    .get('/api/analytics/today')
                    .then(response => {
                        this.todayAnalytics = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            fillContainersChartData(date_from = undefined, date_till = undefined){
                this.containersChartData.loading = true;
                axios
                    .get('/api/analytics/containers-by-state',{
                        params: {
                            ...(date_from ? {date_from: date_from } : {}),
                            ...(date_till ? {date_till: date_till} : {})
                        }
                    })
                    .then(response => {
                        this.containersChartData.data = response.data;
                        this.containersChartData.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.containersChartData.loading = false;
                    })
            },

            fillInventoriesChartData(date_from = undefined, date_till = undefined){
                this.inventoriesChartData.loading = true;
                axios
                    .get('/api/analytics/inventories-by-state',{
                        params: {
                            ...(date_from ? {date_from: date_from } : {}),
                            ...(date_till ? {date_till: date_till} : {})
                        }
                    })
                    .then(response => {
                        this.inventoriesChartData.data = response.data;
                        this.inventoriesChartData.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.inventoriesChartData.loading = false;
                    })
            },
            fillInventoriesData(date_from = undefined, date_till = undefined){
                this.inventoryTotalData.loading = true;
                axios
                    .get('/api/analytics/inventories', {
                        params: {
                        ...(date_from ? {date_from: date_from } : {}),
                        ...(date_till ? {date_till: date_till} : {})
                        }
                    })
                    .then(response => {
                        this.inventoryTotalData.data = response.data;
                        this.inventoryTotalData.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.inventoryTotalData.loading = false;
                    })
            },
            fillContainersData(date_from = undefined, date_till = undefined){
                this.containerTotalData.loading = true;
                axios
                    .get('/api/analytics/containers',{
                        params: {
                            ...(date_from ? {date_from: date_from } : {}),
                            ...(date_till ? {date_till: date_till} : {})
                        }
                    })
                    .then(response => {
                        this.containerTotalData.data = response.data;
                        this.containerTotalData.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.containerTotalData.loading = false;
                    })
            },

            onInventoryChangeRange(range) {
                const [date_from, date_till] = range;
                this.fillInventoriesData(date_from, date_till);
            },

            onContainerChangeRange(range) {
                const [date_from, date_till] = range;
                this.fillContainersData(date_from, date_till);
            },

            onInventoriesChartChangeRange(range) {
                const [date_from, date_till] = range;
                this.fillInventoriesChartData(date_from, date_till);
            },

            onContainersChartChangeRange(range) {
                const [date_from, date_till] = range;
                this.fillContainersChartData(date_from, date_till);
            },

        },
    }
</script>

<style lang="scss">
    .dashboard-booking .ivu-card-extra, .dashboard-chart .ivu-card-extra {
        top: 14px
    }
</style>
