<template>
    <Modal v-model="visible"
           ref="modal"
           :loading="true"
           width="800"
           :mask-closable="false"
           @on-ok="onOk"
           @on-cancel="onCancel">
    </Modal>
</template>

<script>
    import moment from 'moment'
    import {Branch} from '../../../../models/Branch'
    import {Booking} from '../../../../models/Booking'
    import {Company} from '../../../../models/Company'
    import {Terminal} from '../../../../models/Terminal'
    import {ShippingLine} from '../../../../models/ShippingLine'
    import {Destination} from "../../../../models/Destination";
    import uuid from '../../../../utils/uuid'

    import FormItemSelectCompany from '../../../../components/FormItemSelectCompany'

    export default {
        props: {
            value: Boolean,
        },

        components: {
            FormItemSelectCompany
        },

        data() {
            return {
                listBranches: [],
                listTerminals: [],
                listShippingLines: [],
                listDestinations: [],
                visible: false,
                loading: true,
                form: {},
            }
        },

        created() {
            this.reload()
        },

        methods: {
            async open(object) {
                this.form = null;
                await this.reload();
                if (object) {
                    this.form = object
                } else {
                    this.form = new Booking();
                    this.$set(this.form, 'slots', []);
                    this.form.slots.push({id: uuid(), count: 1, size: '40'})
                }
                this.visible = true;
            },

            async reload() {
                let response = await new Terminal().all();
                this.listTerminals = response.data || [];
                response = await new ShippingLine().all();
                this.listShippingLines = response.data || [];
                response = await new Branch().all();
                this.listBranches = response.data || [];
                response = await new Destination().all();
                this.listDestinations = response.data || [];
            },

            onChangeIn(id) {
                let filtered = this.listTerminals.filter(item => item.id === id);
                this.form.in_terminal = filtered.length ? filtered[0] : null
            },

            onChangeOut(id) {
                let filtered = this.listTerminals.filter(item => item.id === id);
                this.form.out_terminal = filtered.length ? filtered[0] : null
            },

            onChangeBranch(id) {
                let filtered = this.listBranches.filter(item => item.id === id);
                this.form.branch = filtered.length ? filtered[0] : null
            },

            onChangeDestination(id) {
                let filtered = this.listDestinations.filter(item => item.id === id);
                this.form.destination = filtered.length ? filtered[0] : null
            },

            onChangeLine(id) {
                let filtered = this.listShippingLines.filter(item => item.id === id);
                this.form.shipping_line = filtered.length ? filtered[0] : null
            },

            onChangeEarlyGate(value) {
                this.form.early_gate = moment(value, 'MM/DD/YYYY')
            },

            onChangeDeparture_date(value) {
                this.form.departure_date = moment(value, 'MM/DD/YYYY')
            },

            onChangeArrival_date(value) {
                this.form.arrival_date = moment(value, 'MM/DD/YYYY')
            },

            onChangeCutOffDay(value) {
                this.form.cut_off_day = moment(value, 'MM/DD/YYYY')
            },

            addSlot() {
                this.form.slots.push({id: uuid(), count: 1, size: '20'})
            },

            delSlot(id) {
                this.$set(this.form, 'slots', this.form.slots.filter(item => item.id !== id))
            },

            async onOk() {
                console.log(this.$refs);
                try {
                    if (this.form.company && this.form.company._uuid) {
                        let company = new Company();
                        company.name = this.form.company.name;
                        let response = await company.save();
                        await this.$refs.company.reload();
                        this.form.company = response || {}
                    }
                    await this.saveItem()
                } catch (err) {
                    this.$refs['form'].validate();
                    this.$refs['modal'].buttonLoading = false
                }
            },

            async saveItem() {
                await this.form.save();
                this.visible = false;
                this.$emit('on-ok')
            },

            onCancel() {
                this.$emit('on-cancel')
            },
        }
    }
</script>
