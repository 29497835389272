const parseJwt = (token) => {
  if (!token) {
    return {exp: 0, scp: 'unknown'}
  }
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

const validToken = (object) => {
  if (object.exp > new Date().getTime() / 1000) {
    return true
  } else {
    localStorage.removeItem('user-token')
    return false
  }
}


export {parseJwt, validToken}
