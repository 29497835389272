import Vue from 'vue'
import axios from 'axios'
import {parseJwt} from '../../utils/jwt'
import {Employee} from '../../models/Employee'
import {AUTH_LOGOUT} from '../actions/auth'
import {CUSTOMERS_AUTH_LOGOUT} from '../actions/customers'
import {USER_ERROR, USER_REQUEST, USER_SUCCESS} from '../actions/user'

const state = {
  status: '',
  profile: {},
  employee: {},
  hasLoadedOnce: true,
};

const getters = {
  getProfile: state => state.profile,

  getFullName: state => {
    let full_name = 'anonymous';
    if (!state.profile || !state.profile.attributes) return full_name;
    full_name = [state.profile.attributes.first_name, state.profile.attributes.last_name].filter(item => item);
    return full_name.length > 0 ? full_name.join(' ') : state.profile.attributes.email
  },

  firstName: state => state.profile.attributes.first_name,

  lastName: state => state.profile.attributes.last_name,

  isProfileLoaded: state => !state.hasLoadedOnce,

  hasBranch: state => () => !!state.employee.branch_name,

  branches: state => () => state.employee.branches,

  isPartner: state => () => state.employee.partner,

  isAdmin: state => () => state.employee.admin,

  hasAccess: state => (object, action) => {
    if (!(state && state.employee && state.employee.accesses && state.employee.accesses.data)) return false;
    return state.employee.admin || (state.employee.accesses.data.find(item => item.object === object && item.action === action) || {}).grant
  },
};

const actions = {
  [USER_REQUEST]: ({commit, dispatch}) => {
    commit(USER_REQUEST);

    let token = localStorage.getItem('user-token');
    let parse = parseJwt(token);

    axios({url: '/api' + (parse.scp === 'customer' ? '/customers' : '') + '/profile?include=accesses' + (parse.scp === 'customer' ? '' : ',branches')})
      .then(resp => {
        commit(USER_SUCCESS, resp)
      })
      .catch(resp => {
        commit(USER_ERROR)
      })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    commit(AUTH_LOGOUT)
  },
  [CUSTOMERS_AUTH_LOGOUT]: ({commit, dispatch}) => {
    commit(CUSTOMERS_AUTH_LOGOUT)
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },

  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success';
    Vue.set(state, 'profile', resp.data.data);
    if (resp.data && resp.data.data && resp.data.data.type === 'employees') {
      Vue.set(state, 'employee', new Employee().respond(resp.data));
    }
    state.hasLoadedOnce = false
  },

  [USER_ERROR]: (state) => {
    state.status = 'error'
  },

  [AUTH_LOGOUT]: (state) => {
    state.profile = null;
    state.employee = null;
    state.status = null;
  },
  [CUSTOMERS_AUTH_LOGOUT]: (state) => {
    state.profile = null;
    state.employee = null;
    state.status = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
