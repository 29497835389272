<template>
    <Layout class="h-layout-login">
        <Content style="display: flex">
            <div style="margin: auto">
                <Form ref="formLogin"
                      :model="form"
                      :rules="ruleForm">
                  <h3 class="support-header">Support form</h3>
                  <Alert type="success" show-icon v-show="alertShow">Thank you, we will contact you soon</Alert>
                    <FormItem prop="email">
                        <Input size="large"
                               v-model="form.email"
                               :autofocus="true"
                               type="text"
                               placeholder="email"/>
                    </FormItem>

                  <FormItem prop="phone">
                    <Input size="large"
                           v-model="form.phone"
                           :autofocus="true"
                           type="text"
                           placeholder="phone"/>
                  </FormItem>

                  <FormItem prop="message">
                    <Input size="large"
                           v-model="form.message"
                           :autofocus="true"
                           type="textarea"
                           :autosize="{minRows: 2,maxRows: 5}"
                           placeholder="Enter your message here"/>
                  </FormItem>



                    <FormItem>
                        <Button type="primary"
                                @click.native.prevent="handleSubmit('formLogin')"
                                long
                                size="large"
                                html-type="submit">
                            <template>Submit</template>
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </Content>
    </Layout>
</template>

<script>
  export default {
    data() {
      return {
        alertShow: false,
        form: {
          email: '',
          phone: '',
          message: ''
        },
        ruleForm: {
          email: [
            {required: true, message: 'Please fill your email', trigger: 'blur'},
          ],
          message: [
            {required: true, message: 'Please fill message.', trigger: 'blur'},
          ]
        }
      }
    },

    methods: {
      handleSubmit(name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.alertShow = true;
            this.form.email = this.form.phone = this.form.message = '';
          }
        })
      }

    }
  }
</script>

<style lang="scss">
  .support-header {
    margin-bottom: 10px;
    text-align: center
  }

</style>
