import {Base} from './Base'
import {Customer} from "./Customer"
import {Booking} from "./Booking"

export class Company extends Base {

  resourceName() {
    return 'companies'
  }

  fields() {
    return [
      'name',
      'address1',
      'address2',
      'city',
      'region',
      'country',
      'postal_code',
      'phone',
      'fax',
      'website',
      'email',
      'is_deleted',
    ]
  }

  relations() {
    return {
      bookings: (...arg) => new Booking(...arg),
      customers: (...arg) => new Customer(...arg),
    }
  }

  fullAddress(object = null) {
    if (!object) {
      object = this
    }
    return [
      object.address1,
      object.address2,
      object.city,
      object.region,
      object.country,
      object.postal_code
    ].filter((item) => item).join(', ')
  }

}