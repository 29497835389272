/* eslint no-console: 0 */

import Vue from 'vue'
import ViewUI from 'view-design'
import VueGtm from 'vue-gtm'
import VueConfirmDialog from 'vue-confirm-dialog'

import App from '../app.vue'
import store from '../store'
import router from '../router'

import locale from 'view-design/dist/locale/en-US'
import axios from 'axios'
import {AUTH_401} from "../store/actions/auth"
import VueChartkick from 'vue-chartkick'
import Viewer from 'v-viewer'
import '../directives/focus'
import '../directives/column-resizable.js'
import '../directives/column-resizable.scss'
import '../filters'
import 'view-design/dist/styles/iview.css'
import '../assets/theme.less'
import '../assets/style.scss'

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://a1de03f0c3e94633b3ebf67bb8656e10@o181327.ingest.sentry.io/1507545",
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
    }),
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1,
});



Vue.use(ViewUI, {locale});
Vue.use(VueChartkick);
Vue.use(Viewer);
Vue.use(VueConfirmDialog)

Vue.component('UserAvatar', require('../components/UserAvatar').default);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

const MEDIA_TYPE = 'application/vnd.api+json';

document.addEventListener('DOMContentLoaded', () => {

  axios.interceptors.request.use((config) => {
    config.headers.common['Authorization'] = localStorage.getItem('user-token');
    config.headers.common['Accept'] = config.headers['Content-Type'] = MEDIA_TYPE;
    return config
  }, (err) => {
    return Promise.reject(err)
  });

  axios.interceptors.response.use((response) => {
    return response
  }, (err) => {
    console.error('axios.interceptors.response::error', err);
    if (err.toString().startsWith('Cancel')) {
      throw err
    }
    if (err && err.response && err.response.status === 401) {
      store.dispatch(AUTH_401).then(() => {
        router.push({name: window.location.pathname.startsWith('/customer') ? 'customersLogin' : 'appLogin'})
      })
    }
    if (err && err.response && err.response.status === 403) {
      ViewUI.Notice.error({
        title: 'Error!', desc: err.response.data.errors[0].detail
      })
    }
    if (err && err.response && err.response.status === 500) {
      ViewUI.Notice.error({
        title: err.response.data.errors[0].title,
        desc: (err.response.data.errors[0].meta || {}).exception || 'Unknown',
        duration: 10
      })
    }
    if (err && !err.response) {
      ViewUI.Notice.error({
        title: err.message, desc: 'Please, check internet connection and <a href=".">reload</a> this page!', duration: 0
      })
    }
    throw err
  });

  new Vue({
    el: document.body.appendChild(document.createElement('app')),
    router,
    store,
    render: h => h(App),
  });

  Vue.use(VueGtm, {
    id: 'GTM-MMBKPKH', // Your GTM ID
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  });
});

