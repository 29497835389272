import {Base} from './Base'

export class Condition extends Base {

  resourceName() {
    return 'conditions'
  }

  fields() {
    return [
      'name',
      'short_name',
      'is_deleted',
    ]
  }

}