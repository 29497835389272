<template>
    <Tabs value="all">
        <TabPane label="All"
                 name="all">
            <Table :columns="columns"
                   :data="data"
                   :row-class-name="allowLogin"
                   :loading="loading"/>

            <Page :total="pagination.total_objects"
                  :current="pagination.page"
                  :page-size="pagination.per_page"
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                  show-total
                  n-show-sizer/>
        </TabPane>

        <template slot="extra">
            <template>&nbsp;</template>
            <Button type="primary"
                    icon="md-add"
                    @click="create"
                    v-if="$store.getters.hasAccess('dictionary', 'create')">
                <template>New employee</template>
            </Button>
        </template>

        <Modal v-model="modalEmployee"
               :title="formEmployeeTitle"
               @on-ok="onOk"
               @on-cancel="onCancel"
               :loading="true"
               ref="modalEmployee">
            <Form ref="formEmployee"
                  :model="formEmployee"
                  :rules="formEmployee.rules"
                  label-position="right"
                  :label-width="100">
                <FormItem prop="first_name"
                          label="First Name">
                    <Input size="large"
                           v-model="formEmployee.first_name"
                           :autofocus="true"
                           type="text"
                           placeholder="First Name"/>
                </FormItem>

                <FormItem prop="last_name"
                          label="Last Name">
                    <Input size="large"
                           v-model="formEmployee.last_name"
                           type="text"
                           placeholder="Last Name"/>
                </FormItem>

                <FormItem prop="username"
                          label="Login">
                    <Input size="large"
                           prefix="ios-contact"
                           v-model="formEmployee.username"
                           type="text"
                           placeholder="Login"/>
                </FormItem>

                <FormItem prop="email"
                          label="Email">
                    <Input size="large"
                           prefix="ios-mail-outline"
                           v-model="formEmployee.email"
                           type="text"
                           placeholder="Email"/>
                </FormItem>

                <FormItem prop="password"
                          label="Password">
                    <Input size="large"
                           prefix="ios-lock-outline"
                           v-model="formEmployee.password"
                           type="password"
                           password
                           placeholder="Password"/>
                </FormItem>
                <FormItem prop="allow_login"
                          label="Security">
                    <Checkbox :label="formEmployee.allow_login"
                              :key="formEmployee.allow_login"
                              size="large"
                              v-model="formEmployee.allow_login">
                        <template>Allow login</template>
                    </Checkbox>
                </FormItem>

                <template>
                    <Divider class="line">Branches</Divider>
                    <p class="line-text">Select branches that the employee can see when working with the program</p>
                    <CheckboxGroup :value="activeBranches()"
                                   class="employee-branches"
                                   @on-change="onChangeBranch">
                        <template v-for="item in branchList">
                            <Checkbox :label="item.id"
                                      :key="item.id"
                                      size="large">
                                <template>{{item.name}}</template>
                            </Checkbox>
                            <br/>
                        </template>
                    </CheckboxGroup>
                </template>
            </Form>
        </Modal>

        <ModifiedPermissions ref="modifiedPermissions"/>
    </Tabs>
</template>

<script>
    import {Branch} from '../../../models/Branch'
    import {Employee} from '../../../models/Employee'
    import ModifiedPermissions from '../modals/permissions/modified'

    export default {
        components: {
            ModifiedPermissions,
        },

        data() {
            return {
                branchList: [],
                branch: {},
                modalEmployee: false,
                formEmployee: new Employee(),
                loading: false,
                columns: [
                    {
                        title: ' ',
                        key: 'avatar',
                        width: 50,
                        render: (h, params) => {
                            return h('UserAvatar', {props:
                                    {'firstName': params.row.first_name,
                                     'lastName': params.row.last_name
                                    }
                            });
                        }
                    },
                    {
                        title: 'Name',
                        key: 'full_name',
                        render: (h, params) => {
                            return h('div', [params.row.first_name, params.row.last_name].filter((item) => item).join(' '));
                        }
                    },
                    {
                        title: 'Branch',
                        key: 'branch',
                        width: 100,
                        render: (h, params) => h('div', params.row.branch ? params.row.branch.name : '')
                    },
                    {title: 'Email', key: 'email', width: 300,},
                    {title: 'Login', key: 'username', width: 200,},
                    {
                        title: 'Actions',
                        width: 310,
                        key: 'actions',
                        render: (h, params) => h('div', {style: {textAlign: 'right'}}, [...this.columnActions(h, params)]),
                    },
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 50,
                    page: 1
                },
                data: []
            }
        },

        computed: {
            formEmployeeTitle() {
                return this.formEmployee.id ? 'Edit Employee' : 'Create Employee'
            }
        },

        created() {
            this.reload();
        },

        methods: {
            activeBranches() {
                return this.formEmployee.branches ? this.formEmployee.branches.data.map(item => item.id) : []
            },

            onChangeBranch(data) {
                if (!this.formEmployee.branches) {
                    this.$set(this.formEmployee, 'branches', {data: []});
                }
                this.formEmployee.branches.data.splice(0);
                data.forEach(item => {
                    const branches = this.branchList.filter(branch => branch.id === item);
                    if (branches.length > 0) {
                        this.formEmployee.branches.data.push(branches[0])
                    }
                });
            },

            columnActions(h, params) {
                let actions = [];

                if (this.$store.getters.hasAccess('admin', 'admin')) {
                    actions.push(h('Button', {
                            props: {type: 'default', size: 'small'},
                            style: {marginRight: '5px'},
                            on: {
                                click: () => this.permissions(params.index)
                            }
                        }, 'Permissions'),
                    )
                }

                if (this.show) {
                    actions.push(h('Button', {
                            props: {type: 'default', size: 'small'},
                            style: {marginRight: '5px'},
                            on: {
                                click: () => this.show(params.index)
                            }
                        }, 'View'),
                    )
                }

                if (this.$store.getters.hasAccess('dictionary', 'update')) {
                    actions.push(h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => this.edit(params.index)
                        }
                    }, 'Edit'))
                }

                if (this.$store.getters.hasAccess('dictionary', 'delete')) {
                    actions.push(h('Poptip', {
                        props: {
                            confirm: true,
                            title: 'Are you sure you want to delete this item?',
                            placement: 'left',
                            transfer: true,
                        },
                        on: {
                            'on-ok': () => this.remove(params.index)
                        }
                    }, [h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                    }, 'Delete')]));
                }

                return actions
            },

            onChange(id) {
                let filtered = this.branchList.filter(item => item.id === id);
                this.formEmployee.branch = filtered.length ? filtered[0] : null
            },

            onOk() {
                this.formEmployee
                    .save()
                    .then(resp => {
                        this.modalEmployee = false;
                        this.reload()
                    })
                    .catch(err => {
                        this.$refs['formEmployee'].validate();
                        this.$refs['modalEmployee'].buttonLoading = false
                    })
            },

            onCancel() {
            },

            create() {
                this.formEmployee = new Employee();
                this.modalEmployee = true
            },

            edit(index) {
                this.formEmployee = this.data[index].clone();
                this.modalEmployee = true
            },

            remove(index) {
                this.data[index]
                    .delete()
                    .then(resp => {
                        this.reload()
                    })
                    .catch(err => {
                        this.reload()
                    })
            },

            permissions(index) {
                this.$refs.modifiedPermissions.open(this.data[index])
            },

            reload() {
                this.loading = true;
                new Branch().all().then(resp => this.branchList = resp.data).catch(console.err);
                new Employee()
                    .with(['branch', 'accesses', 'branches'])
                    .paginate(this.pagination.per_page, this.pagination.page)
                    .then(resp => {
                        this.data = resp.data || [];
                        this.pagination.total_objects = resp.meta.total_objects;
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false
                    });
            },

            changePage(page) {
                this.pagination.page = page;
                this.reload()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                this.reload()
            },
            allowLogin (row, index) {
                if (row.allow_login) {
                    return '';
                }else {
                    return 'user-disable-row';
                }
                return '';
            }
        }
    }
</script>

<style lang="scss">
    .employee-branches {
        .ivu-checkbox-wrapper {
            padding-left: 72px;

            .ivu-checkbox {
                padding-right: 12px;
            }
        }
    }

    .line {
        margin: 2px 0 !important;
    }

    .line-text {
        padding-bottom: 10px;
    }

    .ivu-table .user-disable-row td{
        background-color: #f5768b;
        color: #fff;
    }
</style>
