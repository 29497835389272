import {Base} from './Base'
import {Company} from "./Company"

export class Customer extends Base {

  resourceName() {
    return 'customers'
  }

  fields() {
    return [
      'first_name',
      'last_name',
      'email',
      'password',
      'is_deleted',
      'notification',
    ]
  }

  computed() {
    return {
      fullName(employee) {
        return [employee.first_name, employee.last_name].filter((item) => item).join(' ')
      }
    }
  }

  relations() {
    return {
      company: (...arg) => new Company(...arg),
    };
  }
}