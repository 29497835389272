import empty from '../components/empty'

import customersDashboard from '../modules/customers/dashboard'

import customersContainers from '../modules/customers/containers/index'
import customersContainersView from '../modules/customers/containers/view'
import customersInventories from '../modules/customers/inventories/index'
import customersInventoriesView from '../modules/customers/inventories/view'

export default [
  {
    path: '',
    redirect: {
      name: 'customersDashboard',
    },
  },
  {
    path: 'dashboard',
    name: 'customersDashboard',
    component: customersDashboard,
    redirect: {
      name: 'customersInventoriesInYard',
    },
    meta: {
      breadcrumb: 'Dashboard',
      menu: 'customersDashboard',
    },
  },
  {
    path: 'inventories',
    component: empty,
    meta: {
      breadcrumb: 'Inventories',
    },
    children: [
      {
        path: 'in_yard,immersed',
        name: 'customersInventoriesInYard',
        props: {
          type: 'in_yard,immersed'
        },
        component: customersInventories,
        meta: {
          breadcrumb: 'In Yard',
          menu: 'customersInventories',
        },
      },
      {
        path: 'shipped',
        name: 'customersInventoriesShipped',
        props: {
          type: 'shipped'
        },
        component: customersInventories,
        meta: {
          breadcrumb: 'Shipped',
          menu: 'customersInventories',
        },
      },
      {
        path: ':id',
        name: 'customersInventoriesView',
        component: customersInventoriesView,
        meta: {
          breadcrumb: 'View',
          menu: 'customersInventories'
        },
      },
      {
        path: '',
        name: 'customersInventories',
        redirect: {
          name: 'customersInventoriesInYard',
        },
      },
    ],
  },
  {
    path: 'containers',
    component: empty,
    meta: {
      breadcrumb: 'Containers',
    },
    children: [
      {
        path: 'in_gated',
        name: 'customersContainersInGated',
        props: {type: 'in_gated'},
        component: customersContainers,
        meta: {
          breadcrumb: 'In Gated',
          menu: 'customersContainers',
        },
      },
      {
        path: '',
        name: 'customersContainers',
        redirect: {
          name: 'customersContainersInGated',
        },
      },
      {
        path: ':id',
        name: 'customersContainersView',
        component: customersContainersView,
        meta: {
          breadcrumb: 'View',
          menu: 'customersContainers',
        },
      }
    ],
  },
]
