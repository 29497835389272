import Vue from 'vue'

Vue.directive('column-resizable', {
  inserted: (el, binding, vnode) => {
    let index;
    let offset;
    let column;
    let timeout;

    const onStart = (event, th) => {
      offset = th.offsetWidth - (event.changedTouches && event.changedTouches[0] || event).pageX;
      index = [...th.parentNode.childNodes].indexOf(th);
      column = vnode.componentOptions.propsData.columns[index];
    };

    const onStop = () => {
      index = undefined;
      offset = undefined;
      column = undefined;
    };

    const debounce = (column, width) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        Vue.set(column, 'width', width);
      }, 200);
    };

    const onMove = (event) => {
      if (index > -1) {
        let width = (offset || 0) + (event.changedTouches && event.changedTouches[0] || event).pageX;
        if (column.minWidth && column.minWidth > width) {
          width = column.minWidth;
        }
        if (column.maxWidth && column.maxWidth < width) {
          width = column.maxWidth;
        }
        debounce(column, width);
      }
    };

    if (binding.value) {
      (JSON.parse(localStorage.getItem('size_of_table_' + binding.value())) || [])
        .forEach((item, index) => Vue.set(vnode.componentOptions.propsData.columns[index], 'width', parseInt(item, 10)))
    }

    [...el.querySelectorAll('.ivu-table-header th')].forEach((th) => {
      th.className = 'ivu-table-column-resizable';
      th.style.userSelect = 'none';
      const resize = th.appendChild(document.createElement('span'));
      resize.className = 'ivu-table-cell-resizable';
      ['mousedown', 'touchstart'].forEach(
        name => resize.addEventListener(name, (event) => onStart(event, th, vnode))
      );
      ['mouseup', 'touchend'].forEach(name => document.addEventListener(name, onStop));
      ['mousemove', 'touchmove'].forEach(name => document.addEventListener(name, onMove));
    })
  },

  componentUpdated: (el, binding, vnode) => {
    if (vnode && binding.value) {
      const name = 'size_of_table_' + binding.value();
      const prevValue = localStorage.getItem(name);
      const nextValue = JSON.stringify(vnode.componentOptions.propsData.columns.map(item => item.width));
      if (prevValue !== nextValue) {
        localStorage.setItem(name, nextValue)
      }
    }
  }
});