<template>
    <div>
        <Row :gutter="8"
             v-for="row in getGrid()"
             :key="row.key">
            <Col span="12">
                <template>&nbsp; <img :src="row.img" style="width: 100%"></template>
            </Col>

            <Col span="12">
                <Row>
                    <Col v-for="position in row.col"
                         :key="'position_' + position">
                        <FormItem :prop="'position_' + position"
                                  :label="''+position">
                            <Select :value="getValueByPosition(position)"
                                    clearable
                                    size="large"
                                    @on-change="onChangeCondition($event, position)">
                                <Option v-for="item in listConditions"
                                        :value="item.id"
                                        :key="item.id">
                                    <template>{{item.short_name}} - {{item.name}}</template>
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
</template>

<script>
  import {Condition} from '../../../models/Condition'

  export default {
    props: {
      form: {
        type: Object
      },
      shift: {
        type: Number,
        default: -1,
      }
    },

    data() {
      return {
        loading: true,
        listConditions: [],
        grid: [
          {
            key: 1,
            img: '/images/front.png',
            col: [1, 2, 3, 4, 5]
          },
          {
            key: 2,
            img: '/images/back.png',
            col: [6, 7, 8, 9, 10]
          },
          {
            key: 3,
            img: '/images/driver side.png',
            col: [11, 12, 13, 14, 15, 16]
          },
          {
            key: 4,
            img: '/images/passenger side.png',
            col: [17, 18, 19, 20, 21]
          }
        ]
      }
    },

    mounted() {
      this.loadConditions()
    },

    methods: {
      getGrid() {
        return this.shift >= 0 ? [this.grid[this.shift]] : this.grid
      },

      async loadConditions() {
        const response = await new Condition().all().catch(console.log);
        this.listConditions = ((response && response.data) || []).filter(item => !item.is_deleted);
        this.loading = false
      },

      onChangeCondition(value, position) {
        if (!this.form.conditions) {
          this.$set(this.form, 'conditions', [])
        }
        this.form.conditions = this.form.conditions.filter(item => item.position !== position);
        if (value) {
          this.form.conditions.push({condition_id: value, position: position})
        }
      },

      getValueByPosition(position) {
        let filtered = (this.form.conditions || []).filter(item => item.position === position);
        return filtered.length === 0 ? null : filtered[0].condition_id
      },
    },
  }
</script>