<template>
    <div class="customer-layout">
        <Layout>
            <Header :style="{width: '100%'}" class="customer-header">
                <Menu :active-name="$route.meta.menu"
                      mode="horizontal"
                      theme="primary"
                      @on-select="menuSelect">
                    <div class="customer-layout-logo"></div>
                    <div class="customer-layout-nav">
                        <MenuItem name="customersInventories">
                            <Icon type="ios-car"/>
                            <span>Inventories</span>
                        </MenuItem>
                        <MenuItem name="customersContainers">
                            <Icon type="ios-grid"/>
                            <span>Containers</span>
                        </MenuItem>
                        <div style="float: right; padding-right: 20px;">
                            <MenuItem name="profile">
                                <span>{{fullName}}</span>
                                <Icon type="ios-log-out" v-on:click="this.logout" />
                            </MenuItem>
                        </div>
                    </div>
                </Menu>
            </Header>
            <Content :style="{minHeight: '500px'}">
                <Notification/>
                <Content style="margin: 20px 20px 0; background: #fff; min-height: calc(100vh - 160px);">

                    <router-view/>
                </Content>
                <Footer class="layout-footer-center">Copyright &copy; {{ new Date().getFullYear() }} H-MOTORS Inc.
                </Footer>
            </Content>
        </Layout>
    </div>
</template>

<script>
    import {CUSTOMERS_AUTH_LOGOUT} from '../../store/actions/customers'
    import Notification from '../../components/NotificationCustomer'

    export default {
        components: {
            Notification,
        },

        data() {
            return {
                isCollapsed: false
            }
        },

        computed: {
            fullName() {
                return this.$store.getters.getFullName
            },

            rotateIcon() {
                return [
                    'menu-icon',
                    this.isCollapsed ? 'rotate-icon' : ''
                ];
            },


            breadcrumbs() {
                return this.$route.matched.filter(item => !!item.meta.breadcrumb)
                    .map(item => new Object({name: item.meta.breadcrumb, path: item.path}))
            }
        },

        methods: {
            menuClick(name) {
                if (name === 'logout') {
                    this.logout()
                }
            },

            menuSelect(name) {
                this.$router.push({name: name})
            },

            collapsedSider() {
                this.$refs.side.toggleCollapse()
            },

            logout() {
                this.$store.dispatch(CUSTOMERS_AUTH_LOGOUT)
                    .then(() => {
                        this.$router.push({name: 'customersLogin'})
                    })
            }
        }
    }
</script>

<style lang="scss">
    .customer-header {
        background: #676767 !important;
    }

    .customer-layout {
        position: relative;
        /*overflow: hidden;*/
    }

    /*.customer-layout-logo {*/
        /*background-image: url('../../assets/hmotors-logo-new.png');*/
        /*float: left;*/
        /*position: relative;*/
        /*top: 7px;*/
        /*left: 20px;*/

        /*background-size: 368px 50px;*/
        /*position: absolute;*/
        /*text-align: center;*/
        /*width: 368px;*/
        /*height: 50px;*/
    /*}*/

    .customer-layout-nav {

        margin: 0 auto;
        margin-right: 20px;
    }

</style>
