<template>
    <div>
        <i-table border :columns="columns"
               :data="data"
               :loading="loading"/>

        <Page :total="pagination.total_objects"
              :current="pagination.page"
              :page-size="pagination.per_page"
              @on-change="changePage"
              @on-page-size-change="changePageSize"
              show-total
              n-show-sizer/>
    </div>
</template>

<script>
    import {Container} from '../../../models/Container'

    export default {
        props: {
            search: {
                type: String,
            },
        },
        data() {
            return {
                loading: true,
                columns: [
                    {
                        title: 'Container',
                        key: 'container',
                        minWidth: 250,
                        width: 250,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            let elements = [];
                            elements.push(
                                h((params.row && params.row.booking && params.row.booking.booking_tracking_url) ? 'a' : 'span', {
                                    style: {
                                        'display': 'block',
                                    },
                                    attrs: {
                                        target: '_blank',
                                        href: (params.row && params.row.booking) ? params.row.booking.booking_tracking_url : '',
                                    },
                                }, (params.row && params.row.booking && params.row.booking.number) ? "Booking#: " + params.row.booking.number : '')
                            );
                            elements.push(
                                h('span', {}, "Container#:")
                            );
                            elements.push(
                                h('Badge', {
                                    props: {
                                        'dot': '',
                                    },
                                    class: {
                                        'badge-green': params.row.is_images,
                                    },
                                    style: {
                                        'padding-left': '5px',
                                    }
                                })
                            );
                            elements.push(
                                h(params.row.container_tracking_url ? 'a' : 'span', {
                                    style: {
                                        'padding-left': '5px',
                                    },
                                    attrs: {
                                        target: '_blank',
                                        href: params.row.container_tracking_url,
                                    },
                                }, (params.row.number || '')),
                            );
                            elements.push(
                                h('span', {style: {'padding-left': '5px',}}, params.row.size + "'")
                            );
                            return h('span', elements)
                        },
                    },

                    {
                        title: 'Cut off day',
                        key: 'cut_off_day',
                        width: 100,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('div', params.row.booking && params.row.booking.cut_off_day && params.row.booking.cut_off_day.isValid() ? params.row.booking.cut_off_day.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Destination',
                        key: 'destination',
                        width: 100,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('div', params.row.booking && params.row.booking.destination ? params.row.booking.destination.name : null)
                        },
                    },
                    {
                        title: 'Departure / Arrival date',
                        key: 'departure_arrival_date',
                        width: 180,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            let elements = [];
                            elements.push( h('span', params.row.booking && params.row.booking.departure_date && params.row.booking.departure_date.isValid() ? params.row.booking.departure_date.format('MM/DD/YYYY') : null));
                            elements.push( h('span', ' - '));
                            elements.push( h('span', params.row.booking && params.row.booking.arrival_date && params.row.booking.arrival_date.isValid() ? params.row.booking.arrival_date.format('MM/DD/YYYY') : null));
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'Details',
                        key: 'details',
                        minWidth: 220,
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            let elements = [];
                            for (let item of params.row.vehicles.data) {
                                elements.push(
                                    h('a', {
                                        style: {'display': 'block', 'padding-top': '3px', 'font-size': '10px'},
                                        attrs: {
                                            target: '_blank',
                                            href: '/customer/inventories/' + item.id,
                                        }
                                    }, '-' + item.vehicle_make_and_model_humanize)
                                );
                            }
                            return h('span', {
                                style: {
                                    'display': 'table',
                                    'padding-top': '10px',
                                    'padding-bottom': '10px',
                                }
                            }, elements);
                        },
                    },
                    {
                        title: 'Actions',
                        width: 80,
                        key: 'actions',
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {type: 'default', size: 'small'},
                                    style: {marginRight: '5px'},
                                    on: {
                                        click: () => this.show(params.index)
                                    }
                                }, 'View'),
                            ])
                        }
                    }
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 10,
                    page: 1,
                },
                data: [],
                lastObject: null,
            }
        },

        methods: {
            type() {
                return this.$route.path.split('/').pop()
            },

            show(index) {
                this.$emit('on-action', this.type(), 'show', this.data[index].clone())
                this.$router.push({name: 'customersContainersView', params: {id: this.data[index].id}})
            },

            reload() {
                this.loading = true;
                this.data = [];
                this.lastObject && this.lastObject.cancel('ignore');
                let container = this.lastObject = new Container()
                    .with(['booking.out_terminal', 'booking.destination', 'vehicles'])
                    .where('custom_state_filter', this.type());
                if (this.search) {
                    container = container.where('search', encodeURIComponent(this.search))
                }
                if (this.search !== this.lastSearch) {
                    this.lastSearch = this.search;
                    this.pagination.page = 1;
                }
                container
                    .paginate(this.pagination.per_page, this.pagination.page)
                    .then(resp => {
                        this.data = resp.data || [];
                        this.pagination.total_objects = resp.meta.total_objects;
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false
                    });
            },

            changePage(page) {
                this.pagination.page = page
                this.reload()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page
                this.reload()
            },
        }
    }
</script>
