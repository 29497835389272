<template>
    <div>
        <Row :gutter="8">
            <Col :md="4"
                 :sm="8">
                <FormItem prop="keys"
                          label="Keys">
                    <i-switch v-model="form.keys">
                        <Icon type="android-done"
                              slot="open"/>

                        <Icon type="android-close"
                              slot="close"/>
                    </i-switch>
                </FormItem>
            </Col>

            <Col :md="4"
                 :sm="8"
                 :key="item.id"
                 v-for="item in listOptions">
                <FormItem :prop="item.id"
                          :label="item.name">
                    <i-switch :value="getValueById(item)"
                              @on-change="changeOption($event, item)">
                        <Icon type="android-done"
                              slot="open"/>

                        <Icon type="android-close"
                              slot="close"/>
                    </i-switch>
                </FormItem>
            </Col>
        </Row>
    </div>
</template>

<script>
  import {Option} from '../../../models/Option'

  export default {
    props: {
      form: {
        type: Object
      }
    },

    data() {
      return {
        listOptions: []
      }
    },

    mounted() {
      this.loadOptions()
    },

    methods: {
      async loadOptions() {
        const response = await new Option().all().catch(console.log);
        this.listOptions = ((response && response.data) || []).filter(item => !item.is_deleted)
      },

      changeOption(state, option) {
        if (!this.form.options) {
          this.$set(this.form, 'options', [])
        }
        this.form.options = this.form.options.filter(item => option.id !== item.option_id);
        if (state) {
          this.form.options.push({option_id: option.id})
        }
      },

      getValueById(option) {
        return (this.form.options || []).filter(item => option.id === item.option_id).length > 0
      },
    },
  }
</script>