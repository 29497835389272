<template>
    <Tabs :value="type"
          @on-click="onTabClick">
        <TabPane v-for="state in stateList"
                 :label="state.label"
                 :name="state.name"
                 :key="state.name">
            <inventories :ref="state.name"
                         @on-action="onAction"
                         :type="type"
                         :range="[$route.query.date_from || undefined, $route.query.date_till || undefined]"
                         :page="parseInt($route.query.page) || undefined"
                         :sort="{key: $route.query.key || undefined, order: $route.query.order || undefined}"
                         :branch="$route.query.branch || undefined"
                         :search="$route.query.search || undefined"/>
        </TabPane>

        <template slot="extra">
            <DatePicker :value="[$route.query.date_from || undefined, $route.query.date_till || undefined]"
                        format="MM/dd/yyyy"
                        type="daterange"
                        size="large"
                        placement="bottom-end"
                        placeholder="Select date"
                        style="width: 200px; font-size: 12px;"
                        @on-change="onChangeRange"/>

            <Input size="large"
                   :value="$route.query.search || undefined"
                   @on-change="onSearchChange"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   search
            />

            <Select :value="$route.query.branch || 'all'"
                    size="large"
                    style="width: 150px;"
                    @on-change="onChangeBranch">
                <Option v-for="item in branches()"
                        :value="item.id"
                        :key="item.id">
                    <template>{{item.name}}</template>
                </Option>
            </Select>
        </template>
    </Tabs>
</template>

<script>
  import Vue from 'vue'
  import Downloads from '../../../components/Downloads'
  import inventories from './inventories/_state'

  export default {
    props: {
      type: {
        type: String,
        default: 'received',
      },
    },

    components: {
      inventories,
      Downloads,
    },

    data() {
      return {
        stateList: [
          {label: 'Received', name: 'received'},
        ],
      }
    },

    mounted() {
      this.reload()
    },

    watch: {
      '$route': 'reload',
    },

    methods: {
      branches() {
        const items = [{id: 'all', name: 'All'}];
        if (this.$store.getters.branches()) {
          return [...items, ...this.$store.getters.branches().data]
        }
        return items
      },

      onAction(name, action, object) {
        if (['checked', 'cancel'].includes(action)) {
          this.reload()
        }
      },

      reload() {
        this.$nextTick(() => {
          this.$refs[this.type][0].reload()
        })
      },

      onOk() {
        this.reload()
      },

      create() {
        this.$router.push({name: 'partnerInventoriesCreate'})
      },

      onTabClick(name) {
        this.$router.push({path: name})
      },

      onChangeBranch(item) {
        this.changeUrl({branch: (item === 'all' ? undefined : item)})
      },

      onChangeRange(range) {
        const [date_from, date_till] = range;
        this.changeUrl({date_from: date_from || undefined, date_till: date_till || undefined});
      },

      range_to_string() {
        return Vue.options.filters.to_string([this.$route.query.date_from || undefined, this.$route.query.date_till || undefined])
      },

      onSearchChange(event) {
        this.changeUrl({search: event.currentTarget.value || undefined, page: undefined})
      },

      changeUrl(object) {
        this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
      },
    }
  }
</script>