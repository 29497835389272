import {Base} from './Base'
import {Booking} from "./Booking"
import {Chassis} from "./Chassis"
import {Inventory} from "./Inventory"
import {Vehicle} from "./Vehicle"
import {Attachment} from "./Attachment"

export class Container extends Base {

  resourceName() {
    return 'containers'
  }

  fields() {
    return [
      'size',
      'seal',
      'state',
      'notes',
      'images',
      'number',
      'loaders',
      'is_images',
      'driver_in',
      'driver_out',
      'loads_count',
      'terminal_in_name',
      'terminal_out_name',
      'container_tracking_url',
    ]
  }

  dates() {
    return {
      date_in: 'YYYY-MM-DD',
      date_out: 'YYYY-MM-DD',
    }
  }

  resourceImages() {
    return `${this.baseUrl()}/v2/${this.resourceName()}/${this.id}/images/`
  }

  relations() {
    return {
      booking: (...arg) => new Booking(...arg),
      chassis: (...arg) => new Chassis(...arg),
      inventories: (...arg) => new Inventory(...arg),
      vehicles: (...arg) => new Vehicle(...arg),
      attachments: (...arg) => new Attachment(...arg)
    };
  }

  async pictures() {
    const result = await this.request({
      url: `${this.baseUrl()}/v2/${this.resourceName()}/${this.id}/images`
    })
    return result.data || []
  }

  computed() {
    return {
      state_humanize(container) {
        return container.state.toString().toLowerCase().replace(/[_-]/g, ' ').replace(/(?:^|\s)\S/g, (str) => str.toUpperCase())
      }
    }
  }
}
