<template>
    <div>
        <i-table :columns="columns"
                 :data="data"
                 :loading="loading"
                 @on-sort-change="changeSort"
                 @on-column-width-resize="storage_name"
                 stripe
                 border/>

        <Page :total="pagination.total_objects"
              :current="page"
              :page-size="pagination.per_page"
              @on-change="changePage"
              @on-page-size-change="changePageSize"
              show-total
              show-elevator
              show-sizer/>
    </div>
</template>

<script>
    import {Container} from '../../../models/Container'

    export default {
        props: {
            search: {
                type: String,
            },
            range: {
                type: Array,
            },
            page: {
                type: Number,
                default: 1,
            },
            branch: {
                type: String,
            },
            company: {
                type: String,
            },
            sort: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                loading: true,
                columns: [
                    {
                        title: 'Booking info',
                        key: 'number',
                        width: 250,
                        ellipsis: true,
                        resizable: true,
                        sortable: 'custom',
                        render: (h, params) => {
                            let elements = [];
                            elements.push(
                                h('div',
                                    [h('span', 'Booking #: '),
                                        h((params.row && params.row.booking && params.row.booking.booking_tracking_url) ? 'a' : 'span', {
                                            attrs: {
                                                target: '_blank',
                                                href: (params.row && params.row.booking) ? params.row.booking.booking_tracking_url : '',
                                            },
                                        }, (params.row && params.row.booking && params.row.booking.number) ? params.row.booking.number : '')
                                    ]
                                ));
                            if (params.row.number) {
                                elements.push(
                                    h('div',
                                        [
                                            h('span', 'container #: '),
                                            h(params.row.container_tracking_url ? 'a' : 'span', {
                                                style: {
                                                    'padding-right': '10px',
                                                },
                                                attrs: {
                                                    target: '_blank',
                                                    href: params.row.container_tracking_url,
                                                },
                                            }, (params.row.number || '')),
                                            h('Badge', {
                                                props: {
                                                    'dot': '',
                                                },
                                                class: {
                                                    'badge-green': params.row.is_images,
                                                }
                                            })
                                        ],
                                    )
                                );
                            }

                            elements.push(h('div', 'Size: ' + params.row.size));
                            if (params.row.seal) {
                                elements.push(h('div', 'Seal: ' + params.row.seal));
                            }
                            if (params.row.chassis) {
                                elements.push(h('div', 'Chassis: ' + params.row.chassis.name));
                            }
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'Customer',
                        key: 'customer',
                        minWidth: 80,
                        width: 120,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                        render: (h, params) => {
                            return h('span', params.row.booking && params.row.booking.company ? params.row.booking.company.name : null)
                        },
                    },
                    {
                        title: 'Date and Driver Out',
                        key: 'date_out',
                        width: 135,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                        render: (h, params) => {
                            let elements = [];
                            elements.push(h('div', params.row.date_out && params.row.date_out.isValid() ? params.row.date_out.format('MM/DD/YYYY') : null));
                            elements.push(h('div', params.row.driver_out));
                            return h('span', elements);
                        },
                    },
                    {
                        title: 'Date and Driver In',
                        key: 'date_in',
                        width: 145,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                        render: (h, params) => {
                            let elements = [];
                            elements.push(h('div', params.row.date_in && params.row.date_in.isValid() ? params.row.date_in.format('MM/DD/YYYY') : null));
                            elements.push(h('div', params.row.driver_in));
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'Terminals',
                        key: 'terminal',
                        minWidth: 80,
                        width: 120,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                        render: (h, params) => {
                            return h('span', [params.row.terminal_out_name, params.row.terminal_in_name].join('/'))
                        },
                    },
                    {
                        title: 'Early Gate',
                        key: 'early_gate',
                        minWidth: 70,
                        width: 100,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                        render: (h, params) => {
                            let elements = [];
                            if (params.row && params.row.booking && params.row.booking.early_gate && params.row.booking.early_gate.isValid()) {
                                elements.push(
                                    h('span', {
                                            style: {
                                                'padding-right': '10px',
                                            },
                                        },
                                        params.row.booking.early_gate.format('MM/DD/YYYY')
                                    ),
                                );
                                elements.push(
                                    h('Badge', {
                                        props: {
                                            'dot': '',
                                        },
                                        class: {
                                            'badge-green': params.row.booking.early_gate_confirmation,
                                        }
                                    })
                                )
                            }
                            return h('div', elements)
                        },
                    },
                    {
                        title: 'Cut Off Day',
                        key: 'cut_off_day',
                        minWidth: 70,
                        width: 100,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                        render: (h, params) => {
                            return h('span', (params.row && params.row.booking && params.row.booking.cut_off_day && params.row.booking.cut_off_day.isValid() ? params.row.booking.cut_off_day.format('MM/DD/YYYY') : null))
                        },
                    },
                    {
                        title: 'State',
                        key: 'state_humanize',
                        minWidth: 40,
                        width: 120,
                        tooltip: true,
                        ellipsis: true,
                        resizable: true,
                    },
                    {
                        title: 'Details',
                        key: 'details',
                        width: 500,
                        resizable: true,
                        render: (h, params) => {
                            let elements = [];
                            for (let item of params.row.vehicles.data.slice(0, 4)) {
                                elements.push(
                                    h('a', {
                                        style: {'display': 'block', 'padding-top': '3px', 'font-size': '14px'},
                                        attrs: {
                                            target: '_blank',
                                            href: '/app/inventories/' + item.id,
                                        }
                                    }, '-' + item.vehicle_make_and_model_humanize)
                                );
                            }

                            if (params.row.vehicles.data.length > 4) {
                                elements.push(
                                    h('span', 'and ' + (params.row.vehicles.data.length - 4) + ' more inventories')
                                );
                            }
                            return h('span', {
                                style: {
                                    'display': 'table',
                                    'padding-top': '10px',
                                    'padding-bottom': '10px',
                                }
                            }, elements);
                        },
                    },
                    {
                        title: 'Notes',
                        key: 'notes',
                        minWidth: 40,
                        width: 120,
                        resizable: true,
                    },
                    {
                        title: "Loaders name's",
                        key: 'loaders',
                        minWidth: 40,
                        width: 140,
                        ellipsis: true,
                        resizable: true,
                    },
                    {
                        title: 'Actions',
                        width: 190,
                        key: 'actions',
                        fixed: 'right',
                        render: (h, params) => h('div', {style: {textAlign: 'right'},}, [...this.columnActions(h, params)],),
                    }
                ],
                pagination: {
                    total_objects: 0,
                    per_page: 20,
                },
                data: [],
                lastObject: null,
            }
        },

        methods: {
            columnActions(h, params) {
                let actions = [];

                if (this.show && params.row.state !== 'forming') {
                    actions.push(h('Button', {
                            props: {type: 'success', size: 'small'},
                            style: {marginRight: '5px'},
                            on: {click: () => this.show(params.index)}
                        }, 'View'),
                    )
                }

                if (this.$store.getters.hasAccess('container', 'update')) {
                    actions.push(h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                        on: {click: () => this.edit(params.index)}
                    }, 'Edit'))
                }

                if ((this.$store.getters.hasAccess('container', 'delete') && params.row.state === 'forming') || (this.$store.getters.isAdmin() && params.row.state === 'manually')) {
                    actions.push(h('Poptip', {
                        props: {
                            confirm: true,
                            title: 'Are you sure you want to delete this item?',
                            placement: 'left',
                            transfer: true,
                        },
                        on: {
                            'on-ok': () => this.remove(params.index)
                        }
                    }, [h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                    }, 'Delete')]));
                }
                return actions
            },

            type() {
                return this.$route.path.split('/').pop()
            },

            storage_name() {
                return 'containers_' + this.type()
            },

            show(index) {
                this.$emit('on-action', this.type(), 'show', this.data[index].clone())
                this.$router.push({name: 'appContainersInventories', params: {id: this.data[index].id}})
            },

            edit(index) {
                this.$emit('on-action', this.type(), 'edit', this.data[index].clone())
            },

            remove(index) {
                this.$emit('on-action', this.type(), 'remove', this.data[index].clone())
                this.data[index]
                    .delete()
                    .then(resp => {
                        this.reload()
                    })
                    .catch(err => {
                        this.reload()
                    })
            },

            reload() {
                this.loading = true;
                this.data = [];
                this.lastObject && this.lastObject.cancel('ignore');
                let container = this.lastObject = new Container();
                if (this.search) {
                    container = container.where('search', encodeURIComponent(this.search))
                }
                if (this.sort && this.sort.key && this.sort.order) {
                    container = container[this.sort.order === 'asc' ? 'orderBy' : 'orderByDesc'](this.sort.key)
                }
                if (this.branch) {
                    container = container.where('branch', this.branch)
                }
                if (this.company) {
                    container = container.where('company', this.company)
                }
                container
                    .with(['booking.company', 'chassis', 'vehicles'])
                    .where('custom_state_filter', this.type())
                    .paginate(this.pagination.per_page, this.page)
                    .then(resp => {
                        if (parseInt(resp.meta.total_objects) > 0 && parseInt(resp.meta.total_pages) < this.page) {
                            this.changePage()
                        }
                        this.data = resp.data || [];
                        this.pagination.total_objects = resp.meta.total_objects;
                        this.loading = false
                    })
                    .catch(err => {
                        if (!err.toString().startsWith('Cancel')) {
                            console.warn(err);
                            this.loading = false
                        }
                    });
            },

            changePage(page = undefined) {
                this.$router.push({name: this.$route.name, query: {...this.$route.query, page}});
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                this.reload()
            },

            changeSort({column, key, order}) {
                const sort = {};
                if (order !== 'normal') {
                    sort.key = column.model || key;
                    sort.order = order
                }
                this.changeUrl(sort);
            },

            changeUrl(object) {
                this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
            },
        },
    }
</script>
