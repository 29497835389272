import {Base} from './Base'
import {Booking} from './Booking'

export class ShippingLine extends Base {

  resourceName() {
    return 'shipping_lines'
  }

  fields() {
    return [
      'name',
      'is_deleted',
    ]
  }

  relations() {
    return {
      booking: (...arg) => new Booking(...arg),
    }
  }

}