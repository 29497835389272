import {Base} from './Base'
import {Option} from "./Option";
import {Vehicle} from "./Vehicle"

export class VehicleOption extends Base {

  resourceName() {
    return 'vehicle_options'
  }

  fields() {
    return []
  }

  relations() {
    return {
      option: (...arg) => new Option(...arg),
      vehicle: () => new Vehicle(),
    }
  }

}