<template>
  <div>
    <i-table
        :columns="columns"
        :data="data"
        :loading="loading"
        :row-class-name="rowClassName"
        @on-sort-change="changeSort"
        stripe
        border/>

    <Page :total="pagination.total_objects"
          :current="page"
          :page-size="pagination.per_page"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-total
          show-elevator
          show-sizer/>
    <ModifiedRelease ref="modalRelease"
                     @on-ok="reload"/>
  </div>
</template>

<script>
import moment from 'moment'
import {Vehicle} from '../../../models/Vehicle'
import ModifiedRelease from '../modals/release/modified'

export default {
  props: {
    search: {
      type: String,
    },
    range: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
    branch: {
      type: String,
    },
    company: {
      type: String,
    },
    carrier: {
      type: String,
    },
    inventory_type: {
      type: String,
    },
    title: {
      type: String,
    },
    lien: {
      type: String,
    },
    days_of_storage: {
      type: Number,
      default: -1,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ModifiedRelease,
  },
  computed: {
    windowHeight: function () {
      return document.documentElement.clientHeight * 0.65
    }

  },

  data() {
    return {
      loading: true,
      columns: [
        {
          title: 'Image',
          key: 'image',
          minWidth: 100,
          className: 'image-column',
          render: (h, params) => {
            let elements = [];
            if (params.row.thumb_preview != null) {
              elements.push(h('img', {
                style: {'width': '100px', 'height': '75px'},
                attrs: {
                  src: params.row.thumb_preview,
                },
              }));
              return h('a', {attrs: {href: '/app/inventories/' + params.row.id}}, elements)
            } else {
              elements.push(h('img', {
                style: {'width': '100px', 'height': '75px', 'padding-top': '10px', 'padding-bottom': '7px'},
                attrs: {src: "/assets/images/no-photo-1.svg"}
              }));
              return h('a', {attrs: {href: '/app/inventories/' + params.row.id}}, elements)
            }
          },
        },
        {
          title: 'Date',
          minWidth: 60,
          width: 80,
          resizable: true,
          ellipsis: true,
          key: 'date_of_receiving',
          sortable: 'custom',
          render: (h, params) => {
            let elements = [];
            elements.push(
                h('div', params.row.date_of_receiving && params.row.date_of_receiving.isValid() ? params.row.date_of_receiving.format('MM/DD/YYYY') : null),
            );
            return h('span', elements)
          },
        },
        {
          title: 'Storage days',
          minWidth: 65,
          width: 65,
          resizable: true,
          ellipsis: true,
          key: 'days_of_storage',
          render: (h, params) => {
            let elements = [];
            if (params.row.state === 'in_yard' || params.row.state === 'released') {
              elements.push(
                  h('span', params.row.days_of_storage),
              );
            }
            return h('span', elements)
          },
        },
        {
          title: 'Branch',
          minWidth: 50,
          width: 120,
          resizable: true,
          ellipsis: true,
          key: 'branch_name',
        },
        {
          title: 'Customer',
          minWidth: 90,
          width: 180,
          resizable: true,
          ellipsis: true,
          key: 'company',
          model: 'company.name',
          sortable: 'custom',
          render: (h, params) => {
            return h('span', params.row.company ? params.row.company.name : null)
          },
        },
        {
          title: 'Carrier',
          minWidth: 80,
          width: 180,
          resizable: true,
          ellipsis: true,
          key: 'carrier',
          model: 'carrier.name',
          sortable: 'custom',
          render: (h, params) => {
            return h('span', params.row.carrier ? params.row.carrier.name : null)
          },
        },
        {
          title: 'COD',
          minWidth: 50,
          width: 90,
          resizable: true,
          ellipsis: true,
          key: 'cod_humanize',
          render: (h, params) => {
            let elements = [];
            elements.push(
                h('Badge', {
                  props: {
                    'dot': '',
                  },
                  class: {
                    'badge-green': params.row.cod_confirmed,
                  }
                })
            );
            elements.push(
                h('span', {
                      style: {
                        'padding-left': '10px',
                      },
                    },
                    params.row.cod_humanize
                ),
            );
            return h('span', elements)
          },
        },
        {
          title: 'Keys',
          minWidth: 110,
          resizable: true,
          key: 'keys',
          render: (h, params) => {
            return h('span', params.row.key_humanize)
          },
        },
        {
          title: 'Title / Lien',
          minWidth: 50,
          width: 150,
          resizable: true,
          ellipsis: true,
          key: 'title',
          render: (h, params) => {
            let elements = [];
            elements.push(h('span', params.row.title ? params.row.title.name : null));
            if (params.row.lien) {
              elements.push(h('span', {style: 'padding: 0 3px'}, '/'));
              elements.push(h('span', params.row.lien ? params.row.lien.name : null));
            }
            return h('span', elements)
          },
        },
        {
          title: 'Year/Make/Model',
          minWidth: 45,
          width: 200,
          resizable: true,
          ellipsis: true,
          key: 'year',
          render: (h, params) => {
            let elements = [];
            elements.push(h('span', params.row.year && params.row.year.isValid() ? params.row.year.format('YYYY') : null));
            elements.push(h('span', {style: 'padding-left: 3px'}, params.row.make));
            elements.push(h('span', {style: 'padding-left: 3px'}, params.row.model));

            return h('span', elements)
          },
        },
        {
          title: 'VIN',
          minWidth: 170,
          width: 170,
          resizable: true,
          tooltip: true,
          key: 'vin',
        },
        {
          title: 'HAT #',
          minWidth: 55,
          width: 70,
          resizable: true,
          tooltip: true,
          key: 'hat_number',
        },
        {
          title: 'Value',
          minWidth: 50,
          width: 80,
          resizable: true,
          tooltip: true,
          key: 'cost_humanize',
        },
        {
          title: 'Color',
          minWidth: 50,
          width: 80,
          resizable: true,
          ellipsis: true,
          key: 'color',
          render: (h, params) => {
            return h('div', params.row && params.row.color ? params.row.color.name : '')
          },
        },
        {
          title: 'Notes',
          minWidth: 55,
          width: 80,
          resizable: true,
          tooltip: true,
          key: 'note',
        },
        {
          title: 'Actions',
          width: 200,
          key: 'actions',
          fixed: 'right',
          className: 'table-custom-fixed-right',
          render: (h, params) => h('div', {style: {textAlign: 'right'},}, [...this.columnActions(h, params)],),
        }
      ],
      pagination: {
        total_objects: 0,
        per_page: 20,
      },
      data: [],
      lastObject: null,
    }
  },

  methods: {
    columnActions(h, params) {
      let actions = [];
      let listMenu = [];

      if (this.$store.getters.hasAccess('admin', 'admin') && params.row.is_deleted) {
        actions.push(h('Button', {
              props: {type: 'warning', size: 'small'},
              style: {marginRight: '5px'},
              on: {click: () => this.restore(params.index)}
            }, 'Restore'),
        )
      }

      if (this.$store.getters.hasAccess('inventory', 'update') && this.checked && params.row.state === 'received' && !params.row.is_deleted) {
        actions.push(h('Button', {
          props: {type: 'default', size: 'small'},
          style: {marginRight: '5px'},
          on: {click: () => this.checked(params.index)}
        }, 'Check'))
      }

      if (this.$store.getters.hasAccess('inventory', 'update') && this.checked && params.row.state === 'on_the_way' && !params.row.is_deleted) {
        actions.push(h('Button', {
          props: {type: 'default', size: 'small'},
          style: {marginRight: '5px'},
          on: {click: () => this.received(params.index)}
        }, 'Received'))
      }
      if (this.$store.getters.hasAccess('admin', 'admin') && params.row.state === 'in_yard' && !params.row.is_deleted) {
        listMenu.push(h('DropdownItem', {props: {name: 'shipped'}}, 'To shipped'));
      }

      if (this.$store.getters.hasAccess('inventory', 'update') && params.row.state === 'in_yard' && !params.row.is_deleted) {
        listMenu.push(h('DropdownItem', {props: {name: 'cancel'}}, 'To received'))
        listMenu.push(h('DropdownItem', {props: {name: 'released'}}, 'To released'))

        actions.push(
            h('Dropdown', {
                  props: {trigger: 'click'},
                  on: {'on-click': (name) => this.action(name, params.index)}
                },
                [
                  h('Button', {
                        props: {type: 'primary', size: 'small'},
                        style: {marginRight: '5px'},
                      }, ['Actions',
                        h('Icon', {props: {type: 'ios-arrow-down'}})]
                  ),
                  h('DropdownMenu', {slot: 'list'}, listMenu)
                ]
            )
        )
      }


      if (this.$store.getters.hasAccess('inventory', 'read')) {
        actions.push(h('Button', {
              props: {type: 'success', size: 'small'},
              style: {marginRight: '5px'},
              on: {click: () => this.show(params.index)}
            }, 'View'),
        )
      }

      if (this.$store.getters.hasAccess('inventory', 'delete') && !params.row.is_deleted) {
        actions.push(h('Poptip', {
          props: {
            confirm: true,
            title: 'Are you sure you want to delete this item?',
            placement: 'left',
            transfer: true,
          },
          on: {
            'on-ok': () => this.remove(params.index)
          }
        }, [h('Button', {
          props: {
            type: 'error',
            size: 'small'
          },
          style: {
            marginRight: '5px'
          },
        }, 'Delete')]));
      }
      return actions
    },

    async checked(index) {
      await this.data[index].checked();
      this.$emit('on-action', this.type(), 'checked', this.data[index].clone())
    },

    async received(index) {
      await this.data[index].received();
      this.$router.push({name: 'appInventoriesEdit', params: {id: this.data[index].id}})
    },

    async action(name, index) {
      if (name === 'cancel') {
        await this.data[index].canceled();
        this.$emit('on-action', this.type(), 'cancel', this.data[index].clone())
      } else if (name === 'released') {
        this.$refs.modalRelease.open({
          inventory: this.data[index]
        })
      } else if (name === 'shipped') {
        await this.data[index].shipped();
        this.$Notice.success({
          title: 'Notification title',
          desc: 'Inventory changed status to shipped. '
        })
        this.reload();
      }

    },

    rowClassName(row) {
      if (row.is_deleted) {
        return 'inventory-deleted-row'
      }
      if (!row.title_date.isValid()) {
        return 'inventory-no-title'
      }
      if (row.lien && row.lien.name === 'LIEN') {
        return 'inventory-lien'
      }
      if (row.state === 'received') {
        return 'inventory-state-received'
      }
      return ''
    },


    type() {
      return this.$route.path.split('/').pop()
    },

    storage_name() {
      return 'inventories_' + this.type()
    },

    show(index) {
      this.$emit('on-action', this.type(), 'show', this.data[index].clone());
      this.$router.push({name: 'appInventoriesView', params: {id: this.data[index].id}})
    },

    async restore(index) {
      await this.data[index].restore();
      this.reload();
    },

    edit(index) {
      this.$emit('on-action', this.type(), 'edit', this.data[index].clone());
      this.$router.push({name: 'appInventoriesEdit', params: {id: this.data[index].id}})
    },

    async remove(index) {
      this.$emit('on-action', this.type(), 'remove', this.data[index].clone());
      await this.data[index].delete().catch(console.log);
      this.reload()
    },

    reload() {
      this.loading = true;
      this.data = [];
      this.lastObject && this.lastObject.cancel('ignore');
      let vehicle = this.lastObject = new Vehicle();
      if (this.range && this.range.filter(item => !!item).length) {
        vehicle = vehicle.where('date_of_receiving', this.range.map(item => moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD')).join(','))
      }
      if (this.search) {
        vehicle = vehicle.where('search', encodeURIComponent(this.search))
      }
      if (this.sort && this.sort.key && this.sort.order) {
        vehicle = vehicle[this.sort.order === 'asc' ? 'orderBy' : 'orderByDesc'](this.sort.key)
      }
      if (this.branch) {
        vehicle = vehicle.where('branch', this.branch)
      }
      if (this.days_of_storage && this.days_of_storage >= 0) {
        vehicle = vehicle.where('days_of_storage', this.days_of_storage);
      }
      if (this.deleted) {
        vehicle = vehicle.where('deleted', this.deleted)
      }
      if (this.company) {
        vehicle = vehicle.where('company', this.company)
      }
      if (this.carrier) {
        vehicle = vehicle.where('carrier', this.carrier)
      }
      if (this.title) {
        vehicle = vehicle.where('title', this.title)
      }
      if (this.lien) {
        vehicle = vehicle.where('lien', this.lien)
      }
      if (this.inventory_type) {
        vehicle = vehicle.where('inventory_type', this.inventory_type)
      }
      vehicle
          .with([
            'cod',
            'lien',
            'color',
            'title',
            'carrier',
            'company',
          ])
          .where('custom_state_filter', this.type())
          .paginate(this.pagination.per_page, this.page)
          .then(resp => {
            if (parseInt(resp.meta.total_objects) > 0 && parseInt(resp.meta.total_pages) < this.page) {
              this.changePage()
            }
            this.data = resp.data || [];
            this.pagination.total_objects = resp.meta.total_objects;
            this.loading = false
          })
          .catch(err => {
            if (!err.toString().startsWith('Cancel')) {
              console.warn(err);
              this.loading = false
            }
          });
    },

    changePage(page = undefined) {
      this.$router.push({name: this.$route.name, query: {...this.$route.query, page}});
    },

    changePageSize(per_page) {
      this.pagination.per_page = per_page;
      this.reload()
    },

    changeSort({column, key, order}) {
      const sort = {};
      if (order !== 'normal') {
        sort.key = column.model || key;
        sort.order = order
      }
      this.changeUrl(sort);
    },

    changeUrl(object) {
      this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
    },
  },
}
</script>
<style lang="scss">
.ivu-table td.image-column {
  .ivu-table-cell {
    padding-left: 0px;
    padding-bottom: 0px;
  }
}

.ivu-table-fixed-body {
  overflow: visible !important;
}

</style>
