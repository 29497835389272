<template>
    <Tabs :value="type"
          @on-click="onTabClick">
        <TabPane v-for="state in stateList"
                 :label="state.label"
                 :name="state.name"
                 :key="state.name">
            <inventories :ref="state.name"
                         :type="type"
                         :range="range"
                         :search="search"/>
        </TabPane>

        <template slot="extra">
            <DatePicker :value="range"
                        format="MM/dd/yyyy"
                        type="daterange"
                        placement="bottom-end"
                        placeholder="Select date"
                        style="width: 200px; font-size: 12px;"
                        @on-change="onChangeRange"/>

            <Input :value="search"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   @on-change="onChangeSearch"/>
        </template>
    </Tabs>
</template>

<script>
  import inventories from './_list'

  export default {
    props: {
      type: {
        type: String,
        default: 'in_yard',
      },
    },

    components: {
      inventories,
    },

    data() {
      return {
        range: [],
        search: '',
        stateList: [
          {label: 'In Yard', name: 'in_yard,immersed'},
          {label: 'Shipped', name: 'shipped'},
        ],
      }
    },

    mounted() {
      this.reload()
    },

    watch: {
      '$route': 'reload',
    },

    methods: {
      reload() {
        this.$nextTick(() => {
          this.$refs[this.type][0].reload()
        })
      },

      onTabClick(name) {
        //this.$router.push({path: name})
        this.$router.push({path: name, query: this.$route.query })
      },

      onChangeRange(range) {
        this.range = range
        this.reload()
      },

      onChangeSearch(event) {
        this.search = event.target.value
        this.reload()
      },
    }
  }
</script>
