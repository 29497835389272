<template>
    <Modal v-model="visible"
           ref="modal"
           :title="this.form.id ? 'Edit Container' : 'Create Container'"
           :loading="true"
           @on-ok="onOk"
           @on-cancel="onCancel">
        <Form ref="form"
              :model="form"
              :rules="form.rules"
              label-position="right"
              :label-width="100">
            <FormItem prop="number"
                      label="Number"
                      v-if="fields.includes('number')">
                <Input size="large"
                       v-model="form.number"
                       type="text"
                       placeholder="Number"/>
            </FormItem>

            <FormItem prop="seal"
                      label="Seal"
                      v-if="fields.includes('seal')">
                <Input size="large"
                       v-model="form.seal"
                       type="text"
                       placeholder="Seal"/>
            </FormItem>

            <FormItem prop="booking"
                      label="Booking"
                      v-if="fields.includes('booking')">
                <Select :value="this.booking_id"
                        filterable
                        filter-by-label
                        clearable
                        ref="bookingSelect"
                        placeholder="Booking number"
                        @on-set-default-options="setDefaultOptions"
                        :remote-method="searchBooking"
                        size="large"
                        :loading="bookingLoading"
                        @on-change="onChange">
                    <Option v-for="item in bookingList"
                            :value="item.id"
                            :key="item.id"
                            :label="item.number">
                    </Option>
                </Select>
            </FormItem>

            <FormItem prop="size"
                      label="Size"
                      v-if="fields.includes('size')">
                <RadioGroup v-model="form.size"
                            type="button"
                            size="large">
                    <Radio label="20">
                        <span>20'</span>
                    </Radio>
                    <Radio label="40">
                        <span>40'</span>
                    </Radio>
                    <Radio label="45">
                        <span>45'</span>
                    </Radio>
                    <Radio label="RORO">
                        <span>RORO</span>
                    </Radio>
                </RadioGroup>
            </FormItem>

            <FormItem prop="loaders"
                      label="Loaders name's"
                      v-if="fields.includes('loaders')">
                <Input size="large"
                       v-model="form.loaders"
                       type="text"
                       placeholder="Loaders name's"/>
            </FormItem>
            <FormItem prop="notes"
                      label="Notes"
                      v-if="fields.includes('notes')">
                <Input size="large"
                       v-model="form.notes"
                       type="text"
                       placeholder="Notes"/>
            </FormItem>
        </Form>
    </Modal>
</template>

<script>
    import {Booking} from '../../../../models/Booking'
    import {Container} from '../../../../models/Container'
    import {Chassis} from "../../../../models/Chassis";

    export default {
        props: {
            value: Boolean,
        },

        data() {
            return {
                bookingList: [],
                visible: false,
                form: {},
                fields: [],
                booking_id: null,
                bookingLoading: false
            }
        },

        mounted() {

        },

        methods: {
            setDefaultOptions(option){
                console.log(option)
            },

            async open(object) {
                this.form = object || new Container();
                this.booking_id = this.form.booking ? this.form.booking.id : null
                if(typeof this.$refs.bookingSelect !== "undefined" && object && object.booking) {
                    console.log(this.$refs.bookingSelect.query)
                    console.log(new Date())
                    this.$refs.bookingSelect.onQueryChange(null)
                }
                if (object && object.booking) {
                    this.bookingList.length = 0
                    this.bookingList.push(this.form.booking)
                }
                if (['forming', 'pulled', 'in_yard_empty', 'in_yard_full', 'pushed', 'in_gated', 'manually'].includes(this.form.state || 'forming')) {
                    this.fields.push('size');
                    this.fields.push('notes');

                }
                if ((object && object.booking) || !object) {
                    this.fields.push('booking')
                }
                if (['in_yard_empty', 'in_yard_full', 'pushed', 'in_gated', 'manually'].includes(this.form.state)) {
                    this.fields.push('number');
                    this.fields.push('loaders');
                }
                if (['in_yard_full', 'pushed', 'in_gated'].includes(this.form.state)) {
                    this.fields.push('seal')
                }
                this.visible = true
            },

            async searchBooking(number) {
                if (number !== '' && number.length >= 3) {
                    this.bookingLoading = true
                    const response = await new Booking().where('search', number).all()
                    this.bookingList = response.data || []
                    this.bookingLoading = false
                }
            },

            onChange(id) {
                let filtered = this.bookingList.filter(item => item.id === id);
                this.form.booking = filtered.length ? filtered[0] : null
            },

            clear(){
              this.form = {};
              this.fields = [];
              this.bookingList = [];
              this.booking_id = null;
            },

            onOk() {
                let current_state = this.form.state;
                delete this.form.state;
                this.form
                    .save()
                    .then(resp => {
                        this.visible = false;
                        if (current_state === 'manually'){
                          this.$router.push({name: 'appContainersInventories', params: {id: this.form.id}})
                        }else{
                          this.$emit('on-ok');
                        }
                        this.clear();
                    })
                    .catch(err => {
                        this.$refs['form'].validate();
                        this.$refs['modal'].buttonLoading = false
                    })
            },

            onCancel() {
                delete this.$refs.bookingSelect
                this.clear();
                this.$emit('on-cancel')
            },
        }
    }
</script>

<style>
    .ivu-modal {
        top: 20px !important;
    }
</style>
