<template>
  <iframe name="marinetraffic" id="marinetraffic" width="100%" height="500" scrolling="no" frameborder="0" src="http://www.marinetraffic.com/en/ais/embed/zoom:3/centery:36/centerx:23/maptype:4/shownames:false/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:/remember:false">Browser does not support embedded objects.&lt;br/&gt;Visit directly &lt;a href="http://www.marinetraffic.com/"&gt;www.marinetraffic.com&lt;/a&gt;</iframe>
</template>

<script>
export default {
  // created() {
  //   let ckeditor = document.createElement('script');
  //   ckeditor.setAttribute('src',"https://www.marinetraffic.com/js/embed.js");
  //   document.head.appendChild(ckeditor);
  // }
}
</script>