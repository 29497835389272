<template>
    <Layout class="h-layout-login">
        <Content breakpoint="md" collapsible :collapsed-width="78" style="display: flex">
            <div style="margin: auto">
                <Form ref="form" :model="form" :rules="rule">
                    <h3 style="text-align: center; margin-bottom: 20px">CUSTOMER PORTAL</h3>
                    <FormItem prop="email">
                        <Input size="large" v-model="form.email" :autofocus="true" type="text"
                               placeholder="E-mail" prefix="ios-person-outline"/>
                    </FormItem>
                    <FormItem prop="password">
                        <Input size="large"
                               v-model="form.password"
                               type="password"
                               password
                               placeholder="Password"
                               prefix="ios-lock-outline"/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click.native.prevent="handleSubmit('form')" long size="large"
                                html-type="submit">Sign in
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </Content>
    </Layout>
</template>

<script>
  import {CUSTOMERS_AUTH_REQUEST} from '../../store/actions/customers'

  export default {
    name: 'Login',
    data: () => {
      return {
        form: {
          email: '',
          password: '',
        },
        rule: {
          email: [
            {required: true, message: 'Please fill in the user name', trigger: 'blur'}
          ],
          password: [
            {required: true, message: 'Please fill in the password.', trigger: 'blur'},
            {type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur'}
          ]
        }
      }
    },
    methods: {
      handleSubmit(name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.$store.dispatch(CUSTOMERS_AUTH_REQUEST, {
              email: this.form.email,
              password: this.form.password
            }).then(() => {
              // this.$Notice.success({
              //   title: 'Authentication', desc: 'Success'
              // });
              this.$router.push({name: 'customersDashboard'});
            }).catch((error) => {
              this.$Notice.error({
                title: 'Authentication', desc: error.response.data.message
              });
            })
          }
        })
      }
    }
  }
</script>