import {Base} from './Base'

export class Lien extends Base {

  resourceName() {
    return 'liens'
  }

  fields() {
    return [
      'name',
      'is_deleted',
    ]
  }

}