import {Base} from './Base'

export class Notification extends Base {
    resourceName() {
        return 'notifications'
    }

    fields() {
        return [
            'message',
            'notification_type',
        ]
    }

    dates() {
        return {
            due_from: 'YYYY-MM-DD',
            due_to: 'YYYY-MM-DD',
        }
    }
}
