import {Base} from './Base'

export class Cod extends Base {
  resourceName() {
    return 'cods'
  }

  fields() {
    return [
      'name',
      'is_deleted',
    ]
  }
}