import moment from 'moment'
import inflection from 'inflection'
import {Cod} from './Cod'
import {Lien} from './Lien'
import {Color} from './Color'
import {Title} from './Title'
import {Branch} from './Branch'
import {Carrier} from './Carrier'
import {Company} from './Company'
import {Employee} from './Employee'
import {Inventory} from './Inventory'
import {Attachment} from './Attachment'
import {VehicleModel} from './VehicleModel'
import {InventoryType} from './InventoryType'
import {VehicleOption} from './VehicleOption'
import {VehicleCondition} from './VehicleCondition'
import {Release} from "./Release";



export class Vehicle extends Inventory {
  resourceName() {
    return 'vehicles'
  }

  fields() {
    return [
      'vin',
      'cost',
      'hat_number',
      'cod_amount',
      'cod_status',
      'cod_confirmation_time',
      'keys',
      'note',
      'images',
      'thumb_preview',
      'state',
      'options',
      'conditions',
      'towed_from',
      'towed_amount',
      'storage_amount',
      'lot_number',
      'inv_number',
      'license_number',
      'destination',
      'gwvr',
      'towed',
      'operable',
      'damaged',
      'container_number',
      'container_id',
      'booking_number',
      'booking_id',
      'booking_tracking_url',
      'branch_name',
      'make',
      'model',
      'dimension',
      'items',
      'title_present',
      'v_type',
      'cod_confirmed',
      'is_images',
      'check_number',
      'days_of_storage',
      'shipping_line',
      'vessel_name',
      'arrival_date',
      'departure_date',
      'is_deleted',
      'stock_number',
      'title_info',

    ]
  }

  dates() {
    return {
      year: 'YYYY',
      title_date: 'YYYY-MM-DD',
      date_of_receiving: 'YYYY-MM-DD',
      arrival_date: 'YYYY-MM-DD',
      departure_date: 'YYYY-MM-DD',
    }
  }

  relations() {
    return {
      cod: (...arg) => new Cod(...arg),
      lien: (...arg) => new Lien(...arg),
      color: (...arg) => new Color(...arg),
      title: (...arg) => new Title(...arg),
      owner: (...arg) => new Employee(...arg),
      branch: (...arg) => new Branch(...arg),
      carrier: (...arg) => new Carrier(...arg),
      company: (...arg) => new Company(...arg),
      attachments: (...arg) => new Attachment(...arg),
      vehicle_model: (...arg) => new VehicleModel(...arg),
      vehicle_options: (...arg) => new VehicleOption(...arg),
      vehicle_conditions: (...arg) => new VehicleCondition(...arg),
      inventory_type: (...arg) => new InventoryType(...arg),
      release: (...arg) => new Release(...arg),
    };
  }

  resourceImages() {
    return `${this.baseUrl()}/v2/${this.resourceName()}/${this.id}/images/`
  }

  async pictures() {
    const result = await this.request({
      url: `${this.baseUrl()}/v2/${this.resourceName()}/${this.id}/images`
    })
    return result.data || []
  }

  computed() {
    return {

      cod_humanize(vehicle) {
        return vehicle.cod_amount ? ('$' + (1.0 * vehicle.cod_amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')) : null
      },

      cod_status_humanize(vehicle){
        return vehicle.cod && vehicle.cod.name ? vehicle.cod.name : 'no info'
      },

      cost_humanize(vehicle) {
        return vehicle.cost ? ('$' + vehicle.cost) : null
      },

      year_humanize(vehicle) {
        return vehicle.year && vehicle.year > 0 ? moment(String(vehicle.year) + '-01-01T00:00:00.000', 'YYYY') : null
      },

      vehicle_make_and_model(vehicle) {
        return vehicle.vehicle_model && vehicle.vehicle_model.vehicle_make ?
          [vehicle.vehicle_model.vehicle_make.id, vehicle.vehicle_model.id] :
          []
      },

      vehicle_make_and_model_humanize(vehicle) {
        return (vehicle.make && vehicle.model ?
          [vehicle.year.format('YYYY'), vehicle.make, vehicle.model, '(vin: ' + vehicle.vin + ')'].filter(item => !!item) :
          []).join(' ')
      },

      key_humanize(vehicle){
         return inflection.humanize(vehicle.keys)
      },

      carrier_and_driver(vehicle) {
        return vehicle.driver && vehicle.driver.carrier ?
          [vehicle.driver.carrier.id, vehicle.driver.id] :
          []
      },

      options(vehicle) {
        if (vehicle.options) {
          return vehicle.options
        }
        return vehicle.vehicle_options && vehicle.vehicle_options.data ?
          vehicle.vehicle_options.data.map(vehicle_option => {
            return {option_id: vehicle_option.option.id}
          }) :
          []
      },

      conditions(vehicle) {
        if (vehicle.conditions) {
          return vehicle.conditions
        }
        return vehicle.vehicle_conditions && vehicle.vehicle_conditions.data ?
          vehicle.vehicle_conditions.data.map(vehicle_condition => {
            return {condition_id: vehicle_condition.condition.id, position: vehicle_condition.position}
          }) :
          []
      },

    }
  }
}
