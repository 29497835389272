<template>
    <Content>
        <Content>
            <Card class="dashboard-booking"
                  :bordered="false"
                  dis-hover>
                <p slot="title">Container Details</p>

                <template slot="extra">

                    <template v-if="$store.getters.hasAccess('requirement', 'create')">
                        <Button v-if="item.state !== 'forming'"
                                @click="action('rollback')">Rollback
                        </Button>

                        <Button v-if="item.state === 'forming'"
                                @click="action('pull')">Pull
                        </Button>

                        <Button v-if="item.state === 'pulled' || item.state === 'manually'"
                                @click="action('accept')">Accept
                        </Button>

                        <Button v-if="item.state === 'in_yard_empty'"
                                @click="action('upload')">Upload
                        </Button>

                        <Button v-if="item.state === 'in_yard_full'"
                                @click="action('push')">Push
                        </Button>

                        <Button v-if="item.state === 'pushed'"
                                @click="action('in_gate')">In Gate
                        </Button>

                        <ModifiedRequirement ref="modalRequirement"
                                             @on-ok="reload"/>
                    </template>
                </template>

                <Content>
                    <Row :gutter="8">
                        <Col :xs="24" :md="24" :lg="8"  :xl="8">
                            <CardInfo title="Container Info" :data="cardInfoData" :loading="loading" />
                        </Col>
                    </Row>
                </Content>
            </Card>
        </Content>

        <Content>
            <Tabs v-model="tab">
                <template slot="extra">
                    <span>&nbsp;</span>

                    <Downloads name="Download All"
                               :url="`containers/${item.id}/zip`"
                               v-if="tab === 'images'"/>
                </template>

                <TabPane label="Inventories"
                         name="inventories">
                    <Table :columns="[...columns, ...columnsAdd()]"
                           :data="data"
                           :loading="loadingContainerInventories"
                           border
                    />

                    <div style="padding-top: 20px"></div>

                    <template v-if="item.state === 'in_yard_empty'">
                        <Content>
                            <Input v-model="search"
                                   type="text"
                                   placeholder="Search ..."
                                   style="width: 200px; float: right;"/>
                        </Content>

                        <div style="padding-top: 40px"></div>

                        <Table :columns="[...columns, ...columnsFree()]"
                               :data="inventories"
                               :loading="loadingInventories"
                               border=""
                        />

                        <Page :total="pagination.total_objects"
                              :current="pagination.page"
                              :page-size="pagination.per_page"
                              @on-change="changePage"
                              @on-page-size-change="changePageSize"
                              show-total
                              n-show-sizer/>
                    </template>
                </TabPane>

                <TabPane label="Images" name="images">
                    <MultiUploader :add-image-url="item.resourceImages()"
                                   :image-list="pictures"
                                    :loading="loadingPictures"/>
                    <Pictures :form="item" />
                </TabPane>

                <TabPane label="Attachments (Internal)"
                         name="attachments_secret">
                    <Attachments :form="item"
                                 type="secret"
                                 @reload="reloadContainer"/>
                </TabPane>

                <TabPane label="Attachments (External)"
                         name="attachments_open">
                    <Attachments :form="item"
                                 type="open"
                                 @reload="reloadContainer"/>
                </TabPane>
                <TabPane label="Activity"
                         name="activity"
                         v-if="item.id">
                    <Versions :form="item"
                              resource="containers"/>
                </TabPane>

            </Tabs>
            <Spin fix v-if="imagesUpload" size="large"/>
        </Content>
    </Content>
</template>

<script>
    import {Vehicle} from '../../../models/Vehicle'
    import {Container} from '../../../models/Container'
    import Pictures from '../../../components/OldUploader'
    import Attachments from '../../../components/Attachments'
    import Versions from '../../../components/Versions'
    import MultiUploader from '../../../components/ImageMultiUploader'
    import Downloads from '../../../components/Downloads'
    import ModifiedRequirement from '../modals/requirement/modified'
    import CardInfo from '../../../components/CardInfo'
    import uuid from '../../../utils/uuid'
    import axios from 'axios'

    export default {

        components: {
            Pictures,
            Attachments,
            ModifiedRequirement,
            Versions,
            Downloads,
            CardInfo,
            MultiUploader,
        },

        data() {
            return {
                tab: 'inventories',
                item: {},
                data: [],
                loading: true,
                buttonLoading: false,
                pictures: [],
                loadingInventories: true,
                loadingContainerInventories: true,
                loadingPictures: true,
                inventories: [],
                pagination: {
                    total_objects: 0,
                    page: 1,
                    per_page: 50,
                },
                range: [],
                search: '',
                lastSearch: null,
                imagesUpload: false,
                cardInfoData: [],

                columns: [
                    {
                        title: 'Date',
                        minWidth: 60,
                        width: 80,
                        resizable: true,
                        ellipsis: true,
                        key: 'date_of_receiving',
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('span', params.row.date_of_receiving && params.row.date_of_receiving.isValid() ? params.row.date_of_receiving.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Customer',
                        minWidth: 90,
                        width: 180,
                        resizable: true,
                        ellipsis: true,
                        key: 'company',
                        model: 'company.name',
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('span', params.row.company ? params.row.company.name : null)
                        },
                    },
                    {
                        title: 'Carrier',
                        minWidth: 80,
                        width: 180,
                        resizable: true,
                        ellipsis: true,
                        key: 'carrier',
                        model: 'carrier.name',
                        sortable: 'custom',
                        render: (h, params) => {
                            return h('span', params.row.carrier ? params.row.carrier.name : null)
                        },
                    },
                    {
                        title: 'COD',
                        minWidth: 50,
                        width: 90,
                        resizable: true,
                        ellipsis: true,
                        key: 'cod_humanize',
                        render: (h, params) => {
                            let elements = [];
                            elements.push(
                                h('Badge', {
                                    props: {
                                        'dot': '',
                                    },
                                    class: {
                                        'badge-green': params.row.cod_confirmed,
                                    }
                                })
                            );
                            elements.push(
                                h('span', {
                                        style: {
                                            'padding-left': '10px',
                                        },
                                    },
                                    params.row.cod_humanize
                                ),
                            );
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'Keys',
                        minWidth: 110,
                        resizable: true,
                        key: 'keys',
                        render: (h, params) => {
                            return h('span', params.row.key_humanize )
                        },
                    },
                    {
                        title: 'Title',
                        minWidth: 50,
                        width: 100,
                        resizable: true,
                        ellipsis: true,
                        key: 'title',
                        render: (h, params) => {
                            return h('span', params.row.title ? params.row.title.name : null)
                        },
                    },
                    {
                        title: 'Lien',
                        minWidth: 50,
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        key: 'lien',
                        render: (h, params) => {
                            return h('span', params.row.lien ? params.row.lien.name : null)
                        },
                    },
                    {
                        title: 'Year',
                        minWidth: 50,
                        width: 70,
                        resizable: true,
                        ellipsis: true,
                        key: 'year',
                        render: (h, params) => {
                            return h('span', params.row.year && params.row.year.isValid() ? params.row.year.format('YYYY') : null)
                        },
                    },
                    {
                        title: 'Make',
                        minWidth: 50,
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        key: 'make',
                    },
                    {
                        title: 'Model',
                        minWidth: 55,
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        key: 'model',
                    },
                    {
                        title: 'VIN',
                        minWidth: 60,
                        width: 230,
                        resizable: true,
                        ellipsis: true,
                        key: 'vin',
                        sortable: 'custom',
                        render: (h, params) => {
                            let elements = [];
                            elements.push(
                                h('Badge', {
                                    props: {
                                        'dot': '',
                                    },
                                    class: {
                                        'badge-green': params.row.is_images,
                                    }
                                })
                            );
                            elements.push(
                                h('span', {
                                        style: {
                                            'padding-left': '10px',
                                        },
                                    },
                                    params.row.vin
                                ),
                            );
                            return h('span', elements)
                        },
                    },
                    {
                        title: 'HAT #',
                        minWidth: 55,
                        width: 70,
                        resizable: true,
                        ellipsis: true,
                        key: 'hat_number',
                    },
                    {
                        title: 'Value',
                        minWidth: 50,
                        width: 80,
                        resizable: true,
                        ellipsis: true,
                        key: 'cost_humanize',
                    },
                    {
                        title: 'Color',
                        minWidth: 50,
                        width: 80,
                        resizable: true,
                        ellipsis: true,
                        key: 'color',
                        render: (h, params) => {
                            return h('div', params.row && params.row.color ? params.row.color.name : '')
                        },
                    },
                ],
                lastObject: null,
            }
        },

        watch: {
            'search': function (prev, next) {
                this.debounceSearch()
            }
        },

        mounted() {
            this.debounceSearch = _.debounce(this.reloadInventories, 500);
            this.reload();
        },

        methods: {
            storage_name_add() {
                return 'container_inventory_add'
            },

            storage_name_free() {
                return 'container_inventory_free'
            },

            card_info_collection(){
                this.cardInfoData = [];
                this.cardInfoData.push({title: 'Container #', value: this.item.number });
                this.cardInfoData.push({title: 'State', value:  this.$options.filters.humanize(this.item.state)  });
                this.cardInfoData.push({title: 'Size', value: this.item.size ? this.item.size + "'" : "" });
                this.cardInfoData.push({title: 'Booking #', value: this.item.booking && this.item.booking.number ? this.item.booking.number : "" });
                this.cardInfoData.push({title: 'Shipping Line:', value: this.item.booking && this.item.booking.shipping_line && this.item.booking.shipping_line.name ? this.item.booking.shipping_line.name : "" });
                this.cardInfoData.push({title: 'Vessel name', value: this.item.booking && this.item.booking.vessel_name });
                this.cardInfoData.push({title: 'Departure date', value: this.item.booking && this.item.booking.departure_date.isValid() ? this.item.booking.departure_date.format('MM/DD/YYYY') : null });
                this.cardInfoData.push({title: 'Arrival date', value: this.item.booking && this.item.booking.arrival_date.isValid() ? this.item.booking.arrival_date.format('MM/DD/YYYY') : null });
                this.cardInfoData.push({title: 'Seal', value: this.item.seal });
                this.cardInfoData.push({title: 'Loaders', value: this.item.loaders });
                this.cardInfoData.push({title: 'Customer', value: this.item.booking && this.item.booking.company && this.item.booking.company.name ? this.item.booking.company.name : "" });
                this.cardInfoData.push({title: 'Notes', value: this.item.notes });

            },

            columnsFree() {
                if (!this.$store.getters.hasAccess('inventory', 'update')) return [];
                return [{
                    title: 'Actions',
                    width: 80,
                    key: 'actions',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {type: 'primary', size: 'small'},
                                on: {
                                    click: () => this.append(params.index)
                                }
                            }, 'Add')
                        ]);
                    }
                },]
            },

            addActions(h, params) {
                if (!this.$store.getters.hasAccess('inventory', 'update')) return [];

                let actions = [];

                if (this.item.state !== 'pulled') {

                    actions.push(h('Button', {
                            props: {type: 'success', size: 'small'},
                            style: {marginRight: '5px'},
                            on: {click: () => this.showInventory(params.index)}
                        }, 'View'),
                    );

                }

                if (this.item.state === 'in_yard_empty') {
                    actions.push(
                        h('Poptip', {
                            props: {
                                confirm: true,
                                title: 'Are you sure you want to delete this item?',
                                placement: 'left',
                                transfer: true,
                            },
                            on: {
                                'on-ok': () => this.remove(params.index)
                            }
                        }, [h('Button', {
                            props: {
                                type: 'error',
                                size: 'small'
                            },
                            style: {
                                marginRight: '5px'
                            },
                        }, 'Delete')])
                    );
                }

                return actions

            },

            columnsAdd() {

                return [{
                    title: 'Actions',
                    width: 120,
                    key: 'actions',
                    fixed: 'right',
                    render: (h, params) => h('div', {style: {textAlign: 'right'},}, [...this.addActions(h, params)],),
                }]
            },

            reload() {
                this.reloadContainer();
                this.reloadContainerInventories();
                console.log(this.item );
            },

            async reloadContainerInventories() {
                this.loadingContainerInventories = true;
                const response = await new Vehicle()
                    .with([
                        'lien',
                        'color',
                        'title',
                        'carrier',
                        'company',
                    ])
                    .where('in_container', this.$route.params.id)
                    .all()
                    .catch(console.warn);
                this.data = (response || {data: []}).data || [];
                this.loadingContainerInventories = false
            },

            reloadContainer() {
                this.loading = true;
                this.loadingPictures = true;

                new Container()
                    .with(['booking.company', 'booking.shipping_line', 'attachments'])
                    .find(this.$route.params.id)
                    .then(resp => {
                        this.item = resp;
                        this.reloadInventories()
                        this.card_info_collection();
                        this.item.pictures().then(response =>{
                            this.pictures = response || [] ;
                            this.loadingPictures = false;
                        }).catch(err =>{
                            console.log(err);
                            this.loadingPictures = false
                        });
                        this.loading = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false;
                        this.loadPictures = false;
                        this.reloadInventories()
                    });

            },

            reloadInventories() {
                this.loadingInventories = true;
                this.lastObject && this.lastObject.cancel('ignore');
                let vehicle = this.lastObject = new Vehicle();
                if (this.range && this.range.filter(item => !!item).length) {
                    vehicle = vehicle.where('date_of_receiving', this.range.map(item => moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD')).join(','))
                }
                if (this.search) {
                    vehicle = vehicle.where('search', encodeURIComponent(this.search))
                }
                if (this.search !== this.lastSearch) {
                    this.lastSearch = this.search;
                    this.pagination.page = 1
                }
                //vehicle.where('company', this.item.booking.company.id);
                vehicle
                    .with([
                        'lien',
                        'color',
                        'title',
                        'carrier',
                        'company',
                    ])
                    .where('custom_state_filter', 'in_yard')
                    .paginate(this.pagination.per_page, this.pagination.page)
                    .then(resp => {
                        this.inventories = resp.data || [];
                        this.pagination.total_objects = resp.meta.total_objects;
                        this.loadingInventories = false
                    })
                    .catch(err => {
                        if (!err.toString().startsWith('Cancel')) {
                            console.warn(err);
                            this.loadingInventories = false
                        }
                    });
            },

            async remove(index) {
                await this.item.detach(this.data[index]);
                this.reloadInventories();
                this.reloadContainerInventories()
            },

            async append(index) {
                await this.item.attach(this.inventories[index]);
                this.reloadInventories();
                this.reloadContainerInventories()
            },

            showInventory(index) {
                let routeData = this.$router.resolve({name: 'appInventoriesView', params: {id: this.data[index].id}});
                window.open(routeData.href, '_blank');
            },

            changePage(page) {
                this.pagination.page = page;
                this.reloadInventories()
            },

            changePageSize(per_page) {
                this.pagination.per_page = per_page;
                this.reloadInventories()
            },


            action(type) {
                this.$refs.modalRequirement.open({
                    direction: type,
                    container: this.item
                })
            }


        },
    }
</script>
