<template>
    <Content>
        <Card :bordered="false"
              dis-hover
              title="Container Details">

            <template slot="extra">
                <Downloads name="Download images"
                           :url="`containers/${form.id}/zip`"/>
            </template>
            <Row :gutter="8">
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <Card class="info-card">
                        <p slot="title">
                            Vehicle Images
                        </p>
                        <Carousel :starting-image="2"
                                  :images="images">
                        </Carousel>
                    </Card>
                </Col>
                <Col :xs="24" :md="24" :lg="12" :xl="12">
                    <CardInfo title="Container Info" :data="cardInfoData" :loading="loading"/>
                </Col>
                <Col :xs="24" :md="24" :lg="12" :xl="12" style="margin-top: 10px">
                    <Card class="info-card" title="Attachments">
                        <Attachments :attachment-list="((form.attachments|| {}).data || [])"/>
                    </Card>

                </Col>
            </Row>
            <Spin fix v-if="loading">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>Loading</div>
            </Spin>
        </Card>

        <Tabs v-model="tab">
            <TabPane label="Inventories"
                     name="inventories">
                <Table :columns="columns"
                       :data="data"
                       :loading="loading"
                       v-column-resizable="storage_name"
                       border
                />
            </TabPane>
        </Tabs>
    </Content>
</template>

<script>
    import {Container} from '../../../models/Container'
    import {Vehicle} from '../../../models/Vehicle'
    import Downloads from '../../../components/Downloads'
    import CardInfo from '../../../components/CardInfo'
    import Carousel from "../../../components/Carousel";
    import Attachments from "../../../components/AttachmentsCustomer";

    export default {
        components: {
            Downloads,
            CardInfo,
            Carousel,
            Attachments
        },

        data() {
            return {
                tab: 'inventories',
                form: {},
                imageUrl: '',
                images: [],
                visible: false,
                cardInfoData: [],
                columns: [
                    {
                        title: 'Branch',
                        key: 'branch_name',
                        width: 90,
                        resizable: true,
                        ellipsis: true,
                    },
                    {
                        title: 'Date',
                        width: 90,
                        resizable: true,
                        ellipsis: true,
                        key: 'date_of_receiving',
                        render: (h, params) => {
                            return h('span', params.row.date_of_receiving && params.row.date_of_receiving.isValid() ? params.row.date_of_receiving.format('MM/DD/YYYY') : null)
                        },
                    },
                    {
                        title: 'Keys',
                        width: 110,
                        key: 'keys',
                        resizable: true,
                        ellipsis: true,
                        render: (h, params) => {
                            return h('span', params.row.key_humanize )
                        },
                    },
                    {
                        title: 'Title',
                        width: 100,
                        resizable: true,
                        ellipsis: true,
                        key: 'title',
                        render: (h, params) => {
                            return h('span', params.row.title ? params.row.title.name : null)
                        },
                    },
                    {
                        title: 'Year',
                        width: 60,
                        resizable: true,
                        ellipsis: true,
                        key: 'year',
                        render: (h, params) => {
                            return h('span', params.row.year && params.row.year.isValid() ? params.row.year.format('YYYY') : null)
                        },
                    },
                    {
                        title: 'Make',
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        key: 'make',
                    },
                    {
                        title: 'Model',
                        width: 120,
                        resizable: true,
                        ellipsis: true,
                        key: 'model',
                    },
                    {
                        title: 'VIN',
                        width: 230,
                        resizable: true,
                        ellipsis: true,
                        key: 'vin',
                    },
                    {
                        title: 'Color',
                        resizable: true,
                        ellipsis: true,
                        key: 'color',
                        render: (h, params) => {
                            return h('div', params.row && params.row.color ? params.row.color.name : '')
                        },
                    },
                    {
                        title: 'Actions',
                        width: 80,
                        key: 'actions',
                        fixed: 'right',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {type: 'default', size: 'small'},
                                    style: {marginRight: '5px'},
                                    on: {
                                        click: () => this.show(params.index)
                                    }
                                }, 'View'),
                            ])
                        }
                    }
                ],
                loading: true,
                data: [],
            }
        },

        mounted() {
            this.reload()
        },

        methods: {
            handleView(url) {
                this.imageUrl = url;
                this.visible = true
            },

            show(index) {
                this.$router.push({name: 'customersInventoriesView', params: {id: this.data[index].id}})
            },

            storage_name() {
                return 'customer_container_inventory'
            },

            card_info_collection() {
                this.cardInfoData = [];
                this.cardInfoData.push({title: 'Container #', value: this.form.number});
                this.cardInfoData.push({title: 'State', value: this.$options.filters.humanize(this.form.state)});
                this.cardInfoData.push({title: 'Size', value: this.form.size ? this.form.size + "'" : ""});
                this.cardInfoData.push({
                    title: 'Booking #',
                    value: this.form.booking && this.form.booking.number ? this.form.booking.number : ""
                });
                this.cardInfoData.push({
                    title: 'Shipping Line:',
                    value: this.form.booking && this.form.booking.shipping_line && this.form.booking.shipping_line.name ? this.form.booking.shipping_line.name : ""
                });
                this.cardInfoData.push({
                    title: 'Vessel name',
                    value: this.form.booking && this.form.booking.vessel_name
                });
                this.cardInfoData.push({
                    title: 'Departure date',
                    value: this.form.booking && this.form.booking.departure_date.isValid() ? this.form.booking.departure_date.format('MM/DD/YYYY') : null
                });
                this.cardInfoData.push({
                    title: 'Arrival date',
                    value: this.form.booking && this.form.booking.arrival_date.isValid() ? this.form.booking.arrival_date.format('MM/DD/YYYY') : null
                });
                this.cardInfoData.push({title: 'Seal', value: this.form.seal});
            },

            async reload() {
                this.loading = true;
                if (this.$route && this.$route.params && this.$route.params.id) {
                    const response = await new Container()
                        .with(['attachments', 'booking'])
                        .find(this.$route.params.id);
                    this.form = response || {};
                    await this.form.pictures().then(resp => this.images = resp || [] ).catch(console.log);
                    this.images = this.images.concat(this.form.images);

                    const vehicles = await new Vehicle()
                        .with([
                            'lien',
                            'color',
                            'title',
                            'carrier',
                            'company',
                            'vehicle_model.vehicle_make',
                            'vehicle_options.option',
                            'vehicle_conditions.condition',
                        ])
                        .where('in_container', this.$route.params.id)
                        .all();
                    this.data = vehicles.data || []
                }
                this.card_info_collection();
                this.loading = false
            }
        },

        watch: {
            '$route': 'reload',
        }
    }
</script>
