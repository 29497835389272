<template>
    <div>
        <Row :gutter="8">
            <Col :md="24">
                <div style="column-count: 3; column-gap: 0; line-height: 0;"
                     v-viewer="{title: false, rotatable: false, scalable: false}">
                    <div class="upload-preview-list"
                         v-for="(item, index) in form.images"
                         :key="index" style="width: 100%; height: auto;">
                        <img :src="item.url" style="width: 100%; height: 100%;">

                        <div class="upload-preview-list-cover">
                            <Icon type="ios-trash-outline"
                                  @click.native="handleRemove(item)"/>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>

        <Modal title="View Image"
               v-model="visible">
            <img :src="imageUrl"
                 v-if="visible"
                 style="width: 100%">
        </Modal>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        props: {
            form: {
                type: Object,
            }
        },

        data() {
            return {
                headers: {
                    authorization: localStorage.getItem('user-token'),
                },
                imageUrl: '',
                visible: false,
            }
        },

        methods: {
            handleView(url) {
                this.imageUrl = url;
                this.visible = true
            },

            handleBeforeUpload(file) {
                this.$emit('addFile', file);
                return false
            },


            async handleRemove(item) {
                await axios
                    .request({
                            url: this.form.resourceImages() + item.url.split('/').pop(),
                            method: 'delete',
                        }
                    )
                    .catch(console.warn);
                this.form.images = this.form.images.filter(temp => temp.url !== item.url)
            },

        },
    }
</script>
