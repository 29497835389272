<template>
    <Tabs :value="type"
          @on-click="onTabClick">
        <TabPane v-for="state in stateList"
                 :label="state.label"
                 :name="state.name"
                 :key="state.name">
            <div class="filter-fields">
                <Select :value="$route.query.branch || 'all'"
                        size="small"
                        style="width: 150px;"
                        @on-change="onChangeBranch">
                    <Option v-for="item in branches()"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <DatePicker :value="[$route.query.date_from || undefined, $route.query.date_till || undefined]"
                            :options="dateOptions"
                            format="MM/dd/yyyy"
                            type="daterange"
                            size="small"
                            placeholder="Select date"
                            style="width: 200px; font-size: 12px;"
                            @on-change="onChangeRange"
                            v-if="show_filter"/>
                <Select :value="$route.query.company || undefined "
                        placeholder="Customer"
                        size="small"
                        filterable
                        clearable
                        style="width: 150px;"
                        @on-change="onChangeCustomer">
                    <Option v-for="item in listCompanies"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <Select :value="$route.query.carrier || undefined "
                        placeholder="Carrier"
                        size="small"
                        filterable
                        clearable
                        style="width: 150px;"
                        @on-change="onChangeCarrier">
                    <Option v-for="item in listCarriers"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <Select :value="$route.query.inventory_type || undefined "
                        size="small"
                        placeholder="Inventory type"
                        filterable
                        clearable
                        style="width: 150px;"
                        @on-change="onChangeInventoryType">
                    <Option v-for="item in listInventoryTypes"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <Select :value="$route.query.title || undefined "
                        size="small"
                        placeholder="Title status"
                        filterable
                        clearable
                        style="width: 150px;"
                        @on-change="onChangeTitle">
                    <Option v-for="item in listTitles"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <Select :value="$route.query.lien || undefined "
                        size="small"
                        placeholder="Lien"
                        filterable
                        clearable
                        style="width: 150px;"
                        @on-change="onChangeLien">
                    <Option v-for="item in listLiens"
                            :value="item.id"
                            :key="item.id">
                        <template>{{item.name}}</template>
                    </Option>
                </Select>
                <Input size="small"
                       :value="$route.query.days_of_storage || undefined"
                       type="text"
                       v-if="type === 'in_yard'"
                       @on-change="onChangeDaysOfStorage"
                       placeholder="Days of storage"
                       style="width: 150px;"
                />
                <i-switch :value="$route.query.deleted || false"
                          @on-change="onChangeDeleted" v-if="$store.getters.isAdmin()">
                    <Icon type="md-checkmark" slot="open"></Icon>
                    <Icon type="md-close" slot="close"></Icon>
                </i-switch>
                <span v-if="$store.getters.isAdmin()">show removed</span>
                <div style="float: right">
                    <Downloads name="Export"
                               size="small"
                               v-if="$store.getters.hasAccess('inventory', 'export') && type === 'in_yard'"
                               :url="`exports/inventories?filter[date_of_receiving]=${range_to_string()}`"
                               :disabled="[$route.query.date_from || undefined, $route.query.date_till || undefined].filter(item => !!item).length === 0"/>
                </div>
            </div>
            <inventories :ref="state.name"
                         @on-action="onAction"
                         :type="type"
                         :range="[$route.query.date_from || undefined, $route.query.date_till || undefined]"
                         :page="parseInt($route.query.page) || undefined"
                         :sort="{key: $route.query.key || undefined, order: $route.query.order || undefined}"
                         :branch="$route.query.branch || undefined"
                         :days_of_storage="$route.query.days_of_storage || - 1"
                         :deleted="$route.query.deleted || false"
                         :company="$route.query.company || undefined"
                         :carrier="$route.query.carrier || undefined"
                         :inventory_type="$route.query.inventory_type || undefined"
                         :title="$route.query.title || undefined"
                         :lien="$route.query.lien || undefined"
                         :search="$route.query.search || undefined"/>
        </TabPane>

        <template slot="extra">
            <Button type="primary"
                    icon="md-add"
                    @click="create"
                    v-if="$store.getters.hasAccess('inventory', 'create')">
                <template>New vehicle</template>
            </Button>
            <Input :value="$route.query.search || undefined"
                   @on-change="onSearchChange"
                   type="text"
                   placeholder="Search ..."
                   style="width: 200px;"
                   search
            />
        </template>
        <ModifiedInventory ref="modalInventory"
                           @on-ok="reload"/>
    </Tabs>
</template>

<script>
    import Vue from 'vue'
    import Downloads from '../../../components/Downloads'
    import inventories from './_list'
    import ModifiedInventory from '../../../modules/app/modals/inventory/modified_modal'
    import {Company} from '../../../models/Company'
    import {Lien} from '../../../models/Lien'
    import {Title} from '../../../models/Title'
    import {Carrier} from '../../../models/Carrier'
    import {InventoryType} from "../../../models/InventoryType";

    export default {
        props: {
            type: {
                type: String,
                default: 'received',
            },
        },

        components: {
            inventories,
            Downloads,
            ModifiedInventory,
        },

        data() {
            return {
                listCompanies: [],
                listCarriers: [],
                listInventoryTypes: [],
                listLiens: [],
                listTitles: [],
                stateList: [
                    {label: 'Received', name: 'received'},
                    {label: 'In Yard', name: 'in_yard'},
                    {label: 'Shipped', name: 'shipped,immersed'},
                    {label: 'Released', name: 'released'},
                    {label: 'On the way', name: 'on_the_way'},
                ],
                dateOptions: {
                    shortcuts: [
                        {
                            text: 'This week',
                            value () {
                                const current = new Date();
                                const start = new Date(current.setDate(current.getDate() - current.getDay()));
                                const end = new Date(current.setDate(current.getDate() - current.getDay()+6));
                                return [start, end];
                            }
                        },
                        {
                            text: '1 week',
                            value () {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                return [start, end];
                            }
                        },
                        {
                            text: '1 month',
                            value () {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                return [start, end];
                            }
                        },
                        {
                            text: '3 months',
                            value () {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                return [start, end];
                            }
                        }
                    ]
                }
            }
        },

        mounted() {
            this.reload()
        },

        watch: {
            '$route': 'reload',
        },

        methods: {
            branches() {
                const items = [{id: 'all', name: 'All Branches'}];
                if (this.$store.getters.branches()) {
                    return [...items, ...this.$store.getters.branches().data]
                }
                return items
            },

            onAction(name, action, object) {
                if (['checked', 'cancel', 'received'].includes(action)) {
                    this.reload()
                }
            },

            reload() {
                new InventoryType().all().then(resp => this.listInventoryTypes = resp.data || []).catch(console.log);
                new Company().all().then(resp => this.listCompanies = resp.data || []).catch(console.log);
                new Title().all().then(resp => this.listTitles = resp.data || []).catch(console.log);
                new Lien().all().then(resp => this.listLiens = resp.data || []).catch(console.log);
                this.$nextTick(() => {
                    this.$refs[this.type][0].reload()
                })
            },

            onOk() {
                this.reload()
            },

            create() {
                if(this.type === 'on_the_way'){
                    this.$refs.modalInventory.open();
                }else{
                    this.$router.push({name: 'appInventoriesCreate'})
                }
            },

            createCR() {
                this.$router.push({name: 'appConditionReport'})
            },

            onTabClick(name) {
                this.$router.push({path: name, query: this.$route.query })
            },

            onChangeBranch(item) {
                this.changeUrl({branch: (item === 'all' ? undefined : item)})
            },

            onChangeCustomer(item) {
                this.changeUrl({company: item || undefined})
            },

            onChangeCarrier(item) {
                this.changeUrl({carrier: item || undefined})
            },

            onChangeInventoryType(item) {
                this.changeUrl({inventory_type: item || undefined})
            },
            onChangeTitle(item) {
                this.changeUrl({title: item || undefined})
            },
            onChangeLien(item) {
                this.changeUrl({lien: item || undefined})
            },

            onChangeRange(range) {
                const [date_from, date_till] = range;
                this.changeUrl({date_from: date_from || undefined, date_till: date_till || undefined});
            },

            range_to_string() {
                return Vue.options.filters.to_string([this.$route.query.date_from || undefined, this.$route.query.date_till || undefined])
            },

            onSearchChange(event) {
                this.changeUrl({search: event.currentTarget.value || undefined, page: undefined})
            },

            onChangeDaysOfStorage(event) {
                this.changeUrl({days_of_storage: parseInt(event.currentTarget.value) || undefined, page: undefined})
            },

            onChangeDeleted(value) {
                this.changeUrl({deleted: value || false, page: undefined})
            },

            changeUrl(object) {
                this.$router.push({name: this.$route.name, query: {...this.$route.query, ...object}});
            },
        },
        computed: {
            show_filter(){
                if( this.type !== 'on_the_way'){
                    return true
                }else{
                    return false
                }
            }
        }
    }
</script>

<style lang="scss">
    .filter-fields {
        margin-bottom: 10px;
        margin-top: -20px;
    }

</style>
